import './Score.scss'

import React from 'react'
import cx from 'classnames'

import SpotGoodSmall from '../images/spotGoodSmall.png'
import SpotGoodBig from '../images/spotGoodBig.png'
import SpotNormalSmall from '../images/spotNormalSmall.png'
import SpotNormalBig from '../images/spotNormalBig.png'
import SpotBadSmall from '../images/spotBadSmall.png'
import SpotBadBig from '../images/spotBadBig.png'

const EVALUATION = {
  GOOD: '좋음',
  NORMAL: '보통',
  BAD: '나쁨',
}
interface Props {
  className?: string
  evaluation?: 'GOOD' | 'NORMAL' | 'BAD'
  spotSize?: 'BIG' | 'SMALL'
  data?: number[]
}

const Score = ({ className, evaluation = 'GOOD', spotSize = 'SMALL', data = [0, 0] }: Props) => {
  return (
    <div className={cx('Score', className)}>
      {/* FIXEME: html2canvas svg 렌더링버그 */}
      {/* <Icon
        className={cx('Score__spot', {
          'Score__spot--big': spotSize === 'BIG',
          'Score__spot--small': spotSize === 'SMALL',
          'Score__spot--good': evaluation === 'GOOD',
          'Score__spot--normal': evaluation === 'NORMAL',
          'Score__spot--bad': evaluation === 'BAD',
        })}
        name={spotSize === 'BIG' ? 'JointSpotBig' : 'JointSpotSmall'}
      /> */}
      {evaluation === 'GOOD' && spotSize === 'SMALL' && (
        <img alt="" className={cx('Score__spot', 'Score__spot--small')} src={SpotGoodSmall} />
      )}
      {evaluation === 'GOOD' && spotSize === 'BIG' && (
        <img alt="" className={cx('Score__spot', 'Score__spot--big')} src={SpotGoodBig} />
      )}
      {evaluation === 'NORMAL' && spotSize === 'SMALL' && (
        <img alt="" className={cx('Score__spot', 'Score__spot--small')} src={SpotNormalSmall} />
      )}
      {evaluation === 'NORMAL' && spotSize === 'BIG' && (
        <img alt="" className={cx('Score__spot', 'Score__spot--big')} src={SpotNormalBig} />
      )}
      {evaluation === 'BAD' && spotSize === 'SMALL' && (
        <img alt="" className={cx('Score__spot', 'Score__spot--small')} src={SpotBadSmall} />
      )}
      {evaluation === 'BAD' && spotSize === 'BIG' && (
        <img alt="" className={cx('Score__spot', 'Score__spot--big')} src={SpotBadBig} />
      )}
      <div
        className={cx('Score__color', {
          'Score__color--good': evaluation === 'GOOD',
          'Score__color--normal': evaluation === 'NORMAL',
          'Score__color--bad': evaluation === 'BAD',
        })}
      >
        {EVALUATION[evaluation]}
      </div>
      <div className="Score__score">
        <div>{data[0]}</div>
        <div>{data[1]}</div>
      </div>
    </div>
  )
}

export default Score
