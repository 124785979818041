import * as React from 'react'

function FunBoldCheck(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 16 16" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        d="M6.895 9.457L4.762 6.949a1 1 0 00-1.524 1.297l2.896 3.402a.999.999 0 001.523 0l5.105-6a1 1 0 10-1.524-1.296L6.895 9.457z"
      />
    </svg>
  )
}

export default FunBoldCheck
