import './SearchInput.scss'

import React, { useRef, ChangeEvent } from 'react'
import cx from 'classnames'
import { isEmpty } from 'lodash'

import Icon from './Icon'
import Loading from './Loading'
import Button from './Button'
import Input from './Input'

interface Props {
  className?: string
  label: string
  value: string
  placeholder?: string
  error?: boolean
  disabled?: boolean
  autoComplete?: 'on' | 'off'
  isLoading?: boolean
  onChange: (value: string) => void
  onBlur?: () => void
  onSearch?: () => void
  onDelete?: () => void
}

export default function SearchInput({
  className,
  label,
  value,
  placeholder,
  error,
  disabled,
  autoComplete,
  isLoading,
  onChange,
  onBlur,
  onSearch,
  onDelete,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null)

  const clearInput = () => {
    onChange('')
    onDelete?.()
    inputRef.current?.focus()
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }

  const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch?.()
    }
  }

  return (
    <Input
      className={cx('SearchInput', className)}
      ref={inputRef}
      label={label}
      role="searchbox"
      placeholder={placeholder}
      type="text"
      value={value}
      autoComplete={autoComplete}
      error={error}
      disabled={disabled}
      leftEl={isLoading ? <Loading isVisible /> : <Icon name="Search" />}
      rightEl={
        !isEmpty(value) && (
          <Button theme="iconOnly" title="Clear" size="small" onClick={clearInput}>
            <Icon name="XCircle" />
          </Button>
        )
      }
      onChange={handleChange}
      onBlur={onBlur}
      onKeyPress={e => handleSearch(e)}
      hideLabel
    />
  )
}
