import * as React from 'react'

function SvgArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg className="Icon" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="15" cy="14.9993" rx="15" ry="14.9995" fill="white"/>
    <g clipPath="url(#clip0)">
    <path d="M19.1424 6.96094H10.8567C10.2264 6.96094 9.71387 7.47344 9.71387 8.10379V21.8181C9.71387 22.4484 10.2264 22.9609 10.8567 22.9609H19.1424C19.7728 22.9609 20.2853 22.4484 20.2853 21.8181V8.10379C20.2853 7.47344 19.7728 6.96094 19.1424 6.96094ZM18.9996 21.6752H10.9996V8.24665H18.9996V21.6752ZM14.2853 19.8538C14.2853 20.0432 14.3606 20.2249 14.4945 20.3589C14.6285 20.4928 14.8101 20.5681 14.9996 20.5681C15.189 20.5681 15.3707 20.4928 15.5047 20.3589C15.6386 20.2249 15.7139 20.0432 15.7139 19.8538C15.7139 19.6644 15.6386 19.4827 15.5047 19.3487C15.3707 19.2148 15.189 19.1395 14.9996 19.1395C14.8101 19.1395 14.6285 19.2148 14.4945 19.3487C14.3606 19.4827 14.2853 19.6644 14.2853 19.8538Z" fill="#03ACCE"/>
    </g>
    <defs>
    <clipPath id="clip0">
    <rect width="16" height="16" fill="white" transform="translate(7 7)"/>
    </clipPath>
    </defs>
    </svg>
  )
}

export default SvgArrow
