import './RecentSearchWords.scss'

import React from 'react'
import { isEmpty } from 'lodash'

import Icon from './Icon'
import Button from './Button'

interface Props {
  className?: string
  searchWords: string[] | null
  onRemove: (word: string) => void
  onSearch: (word: string) => void
}

const RecentSearchWords = ({ searchWords, onRemove, onSearch }: Props) => {
  return isEmpty(searchWords) ? (
    <div className="RecentSearchWords--empty">최근 검색어가 없습니다.</div>
  ) : (
    <ul className="RecentSearchWords">
      {searchWords?.map(word => (
        <li className="RecentSearchWords__word" key={word} >
          <button className="RecentSearchWords__search" onClick={() => onSearch(word)}>
            {word}
          </button>
          <Button theme="iconOnly" size="small" onClick={() => onRemove(word)}>
            <Icon name="Close" />
          </Button>
        </li>
      ))}
    </ul>
  )
}

export default RecentSearchWords
