import React from 'react'
import cx from 'classnames'
import {Line, Bar } from 'react-chartjs-2'
import { graphColor } from '../constants/color'
import {cutDecimal} from '../utils/misc'
import './GroupBarGraph.scss'

type Data = {
  label: string
  data: number[]
}

interface Props {
  className?: string
  labels: string[]
  averageAgeData: number[]
 // data: Data[]
}

const barGraphOption = {
  type: 'bar',
  backgroundColor: 'rgba(135,113,177,0.6)',
  borderWidth: 0,
  maxBarThickness: 120,
}
const PURPLE = '#562C8D'
const GroupBarGraph = ({ className, labels, averageAgeData }: Props) => {
  // const barData = data.map(({ label, data }) => {
  //   return { ...barGraphOption, label, data }
  // })
  let sample  = averageAgeData.map(age => 100 - Number(cutDecimal(age, 1)))
  const graphData = {
    labels,
    datasets: [
      {
        type: 'line',
        label: 'Health age',
        lineTension: 0,
        borderWidth: 7,
        fill: false,
        borderColor: PURPLE,
        backgroundColor: 'rgba(0,0,0,0.2)',
        pointBorderColor: PURPLE,
        pointBackgroundColor: 'white',
        pointRadius: 5,
        pointBorderWidth: 2,
        data: sample,
      },
    //  ...barData,
    ],
  }

  return (
    <div className={cx('GroupBarGraph', className)}>
      <Bar
        data={graphData}
        options={{
          plugins: {
            datalabels: {
              display: context => context?.dataset?.type === 'line',
              formatter:  value => `${cutDecimal(100 - Number(value), 1)}세`,
              color: graphColor.BLACK,
              align: 'end',
              offset: 5,
              font: {
                weight: 'bold',
                size: 35,
                family: 'Noto Sans KR',
              },
            },
          },
          layout: { padding: { top: 50 } },
          aspectRatio: 0,
          maintainAspectRatio: true,
          animation: { duration: 0 },
          title: {
            display: false,
            text: 'Health Development',
          },
          legend: { display: false },
          scales: {
            xAxes: [
              {
                gridLines: { display: false },
                ticks: {
                  fontStyle: 'bold',
                  fontSize: 27,
                  fontColor: '#6D6D6D',
                  fontFamily: 'Noto Sans KR',
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "나이 ( 세 )",      
                  fontStyle: 'bold',
                  fontSize: 24,
                  fontColor: '#6D6D6D',
                  fontFamily: 'Noto Sans KR',   
                },
                type: 'linear',
                position: 'left',
                ticks: {
                  fontStyle: 'bold',
                  fontSize: 27,
                  fontColor: '#6D6D6D',
                  fontFamily: 'Noto Sans KR',
                  beginAtZero: false,
                  stepSize: 5,
                  suggestedMin: 15,
                   suggestedMax: 100, // flexible하게도 가능함
                callback: value => Math.abs(Number(value) - 100),
                },
                gridLines: {
                  lineWidth: 2,
                  color: '#C4C4C4',
                },
              },
            ],
          },
        }}
        width={100}
        height={26}
      />
    </div>
  )
}

export default GroupBarGraph
