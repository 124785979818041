import './EndDiseasePredictionPage.scss'

import React from 'react'
import useRouter from '../hooks/useRouter'

import BasicLayout from '../templates/BasicLayout'
import EndDiseasePrediction from '../components/EndDiseasePrediction'

export default function DiseasePredictionPage() {
  const { match: { params: { id: reportId } } } = useRouter<{ id: string }>()

  return (
    <BasicLayout className="DiseasePredictionPage" isHeader={false}>
      <EndDiseasePrediction reportId={reportId} />
    </BasicLayout >
  )
}
