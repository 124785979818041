import './SolutionPage.scss'

import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { chunk, flatten, isEmpty, isNil, isString, take } from 'lodash'

import useRouter from '../hooks/useRouter'
import { requestApi } from '../utils/api'
import { formatDate, getAge, getDuration, getSchedule } from '../utils/misc'
import { getLabel, CATEGORY_LIST, LEVEL_LIST, PERSONAL_SOLUTION_SEND_HOUR_LIST, PERSONAL_SOLUTION_SEND_MINUTE_LIST } from '../constants/label'

import BasicLayout from '../templates/BasicLayout'
import Loading from '../components/Loading'
import UserInfoTag from '../components/UserInfoTag'
import ExpectAge from './ExpectAge'
import Button from '../components/Button'
import LinkButton from '../components/LinkButton'
import Icon from '../components/Icon'
import CropImg from '../components/CropImg'
import Modal from '../components/Modal'
import naverLink from '../images/naver_link.png'
import type { PersonalSolution, Video, Center } from '../types/model'

const Tip = ({ message }: { message: string }) =>
  isEmpty(message) ? null : (
    <div className="Tip">
      <div>
        <strong>Tip</strong>
        {message}
      </div>
    </div>
  )

const isFreeUser = (membershipType: string) => !membershipType || membershipType === 'FREE'

export default function SolutionPage() {
  const {
    match: {
      params: { id: solutionId, progress },
    },
    history,
  } = useRouter<{ id: string; progress: string }>()
  const [solutionData, setSolutionData] = useState<PersonalSolution | string | null>(null)
  const [centerData, setCenterData] = useState<Center[] | null>(null)
  const [schedule, setSchedule] = useState<string[]>([])
  const [scheduledExercise, setScheduledExercise] = useState<Video[][] | null>(null)

  useEffect(() => {
    if (!isEmpty(solutionId)) {
      requestApi({ url: `/solutions/${solutionId}` }).then(res => {
        if (res?.status === 200) {
          setSolutionData(res?.data)
        } else {
          setSolutionData('퍼스널솔루션을 조회하는 데 문제가 발생했습니다. url을 확인 해주세요.')
        }
      })
      requestApi({ url: '/centers' }).then(res => {
        if (res?.status === 200) {
          setCenterData(res?.data.data)
        }
      })
    } else {
      setSolutionData('퍼스널솔루션을 조회하는 데 문제가 발생했습니다. url을 확인 해주세요.')
    }
  }, [solutionId])

  useEffect(() => {
    if (!isNil(solutionData) && !isString(solutionData)) {
      const {
        user: { personalSolutionSendDays, membershipType },
        solution: { startDate, videos },
      } = solutionData
      const week = isFreeUser(membershipType) ? 2 : 4
      const count = personalSolutionSendDays.length
      const total = week * count
      setSchedule(getSchedule(startDate, total, personalSolutionSendDays))
      setScheduledExercise(chunk(take(videos, week * count), count))
    }
  }, [solutionData])

  // 1. Solution request
  if (isNil(solutionData)) {
    return (
      <BasicLayout className="SolutionPage" isHeader={false}>
        <h1>인택트 퍼스널 솔루션</h1>
        <Loading />
      </BasicLayout>
    )
  }

  // 2. Request Error
  if (isString(solutionData)) {
    return (
      <BasicLayout className="SolutionPage" isHeader={false}>
        <h1>인택트 퍼스널 솔루션</h1>
        {solutionData}
      </BasicLayout>
    )
  }

  const realProgress = solutionData.solution.progress
  const linkProgress = Number(progress) || 0

  // 3. Future progress access restrict
  if (linkProgress > realProgress) {
    const moveToProgress = () => history.push(`/solutions/${solutionId}/${realProgress}`)
    return (
      <Modal
        className="AlertModal"
        content={{
          title: '현재 진도가 아닙니다.',
          description: '오늘의 퍼스널솔루션 페이지로 이동합니다.',
        }}
        onConfirm={moveToProgress}
        onClose={moveToProgress}
        isVisible
      />
    )
  }

  // 4. Personal Solution
  let {
    solution: { videos, startDate, endDate, stamps, lifeExpectancy, created, reportId },
    user,
  } = solutionData
  const { name, birthday, personalSolutionSendDays, personalSolutionSendHour,personalSolutionSendMinute, membershipType } = user
  const { title, thumbnail, category, duration, level, tip, url } = videos[linkProgress]

  const solutionWeekCount = scheduledExercise?.length
  const solutionTotalCount = flatten(scheduledExercise).length
  const solutionStamp = take(stamps, solutionTotalCount)

  const startExercise = () => {
    if (window.confirm('운동을 시작하시겠습니까? \n시작 시 운동 영상으로 이동합니다.')) {
      window.open(`https://www.youtube.com/watch?v=${url}`, '_blank')
      requestApi({
        method: 'put',
        url: `/solutions/${solutionId}/stamps?index=${linkProgress}`,
        isAuthenticated: true,
      }).then(res => {
        if (res?.status !== 200) {
          window.alert('운동을 시작하는데 문제가 발생했습니다. 다시 시도해주세요.')
        }
      })
    }
  }

// const throwPlace:function(e, center) {
//    return console.log('눌러짐',center);
    
//   }


  return (
    <BasicLayout className="SolutionPage" isHeader={false}>
      <h1>인택트 퍼스널 솔루션</h1>
      <section className="section1">
        <Button
          theme="iconOnly"
          onClick={() => {
            startExercise()
          }}
        >
          <Icon name="Play" />
          <img alt={title} src={thumbnail} />
        </Button>
        <h2>{`${linkProgress + 1}. ${title}`}</h2>
        <strong>{`${schedule[linkProgress] || ''} ${name} 회원님 운동`}</strong>
        <p>
          {!isEmpty(category) && `${category.map(c => getLabel(CATEGORY_LIST, c)).join(', ')} 개선에 도움 / `}
          {`${getDuration(duration)} 소요 / `}
          {`난이도: ${getLabel(LEVEL_LIST, level)}`}
        </p>
        <Tip message={tip} />
      </section>
      <section className="Stamp">
        <h2>{solutionWeekCount ? `${solutionWeekCount}주` : ''} 솔루션을 꾸준히 수행하고 위험 질환을 관리하세요.</h2>
        {isEmpty(solutionStamp) ? (
          <p>
            회원님의 퍼스널 솔루션 발송요일이 지정되지 않아있습니다. 회원정보 수정 페이지에서 발송요일을 지정해주세요.
          </p>
        ) : (
          <>
            <strong>{`${formatDate(startDate, 'YY-MM-DD')} ~ ${formatDate(endDate, 'YY-MM-DD')}`}</strong>
            <div className="stamps">
              {solutionStamp.map((s, idx) => {
                const isFuture = idx >= realProgress
                return (
                  <LinkButton
                    key={`stamp-${idx}`}
                    className={cx('stamp', {
                      current: !isFuture && idx === linkProgress,
                      future: isFuture,
                      done: s,
                    })}
                    theme="iconOnly"
                    to={`/solutions/${solutionId}/${idx}`}
                    disabled={isFuture}
                    newTab
                  >
                    {idx + 1}
                  </LinkButton>
                )
              })}
            </div>
            <p className="progress">{`${solutionStamp.length}개 운동 중 ${
              solutionStamp.filter(s => !!s).length
            }개 완료`}</p>
          </>
        )}
      </section>
      <ExpectAge
        name={name}
        reportId={reportId}
        age={getAge(birthday)}
        lifeExpectancy={lifeExpectancy}
        created={created}
      />
      <UserInfoTag userInfo={user} />
      {scheduledExercise?.map((weekEx, week) => {
        return (
          <section key={`weekEx-${week}`} className="Exercises">
            {isFreeUser(membershipType) && <div className="badge">체험판</div>}
            <h2>{week + 1}주차 운동</h2>
            {weekEx.map((dayEx, day) => {
              const { title, duration, level, thumbnail } = dayEx
              const weeklyExerciseCount = personalSolutionSendDays.length
              const currentProgress = day + week * weeklyExerciseCount
              const isDone = currentProgress < realProgress
              const exerciseInfo = `${getDuration(duration)} 소요, 난이도: ${getLabel(LEVEL_LIST, level)}`
              const openingDate = `${schedule[currentProgress]} ${getLabel(
                PERSONAL_SOLUTION_SEND_HOUR_LIST,
                personalSolutionSendHour
              )} ${getLabel(PERSONAL_SOLUTION_SEND_MINUTE_LIST,
                personalSolutionSendMinute)} 전송 예정`
              return (
                <LinkButton
                  key={`${title}-${day}`}
                  className="Exercise"
                  theme="iconOnly"
                  to={`/solutions/${solutionId}/${currentProgress}`}
                  disabled={!isDone}
                  newTab
                >
                  <div className="info">
                    <strong>{title}</strong>
                    <p>{isDone ? exerciseInfo : openingDate}</p>
                  </div>
                  <CropImg alt={title} src={thumbnail} />
                </LinkButton>
              )
            })}
          </section>
        )
      })}
      {isFreeUser(membershipType) &&  (
        <>
          <section className="IntactFunc">
            <p>머스트무브짐에서 나에게 필요한 올바른 운동을 꾸준히 지속하세요.</p>
            <div className="detail">
              <Icon name="CircleReport" />
              인택트를 통한 심층적인 건강 리포트 산출
            </div>
            <div className="detail">
              <Icon name="Circle2" />
              건강 나이 기반 오프라인 집중 트레이닝
            </div>
            <div className="detail">
              <Icon name="Circle3" />
              질환 예측 기반 온라인 홈트레이닝 제공
            </div>
          </section>
          <section className="Contact">
            <h2>Contact</h2>
            <ul>
              {centerData?.map(center => (
                <li key={center._id}>
                  <div className="throwPlace" onClick={(e)=>{
                    console.log('눌림',center)
                    window.open(center.url);
                  }} >
                   <h3><img style={{width:13}} src={naverLink} alt="naver link"/> {center.centerName}</h3>
                    <p>{center.pointName}</p>
                  </div>
                  <LinkButton to={`tel:${center.contact}`} theme="iconOnly" external>
                    <Icon name="Phone" />
                  </LinkButton>
                </li>
              ))}
            </ul>
          </section>
        </>
      )}
    </BasicLayout>
  )
}
