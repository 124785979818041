import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'
import { ScoreStaticItem } from '../types/model'
import { findIndex, includes } from 'lodash'
import { DATE_LIST } from '../constants/label'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(duration)

export const today = dayjs().tz('Asia/Seoul')

export const getAge = (date: string) => {
  return today.year() - dayjs(date).tz('Asia/Seoul').year() + 1
}

export const getDateDiff = (date: string, word: string = '측정') => {
  dayjs.updateLocale('en', {
    relativeTime: {
      past: '%s 전',
      s: '방금',
      m: '1분',
      mm: '%d분',
      h: '1시간',
      hh: '%d시간',
      d: '하루',
      dd: '%d일',
      M: '한달',
      MM: '%d달',
      y: '1년',
      yy: '%d년',
    },
  })

  return `${dayjs(date).fromNow()} ${word}`
}

export const getMembershipEndDateDiff = (date: string) => {
  const endDate = today.diff(date, 'day')
  return endDate > 0 ? '만료 회원' : `갱신일: ${Math.abs(endDate)}일 남음`
}

export const formatDate = (date: string, format: string = 'YYYY-MM-DD') => {
  return dayjs(date).tz('Asia/Seoul').format(format)
}

export const formatDateKR = (date: string | dayjs.Dayjs = today) =>
  dayjs(date).tz('Asia/Seoul').format(`YYYY년 MM월 DD일`)

export const getGender = (gender: string) => (gender === 'male' ? '남' : '여')

export const cutDecimal = (num: string | number, digit: number = 0) => {
  return Number.isInteger(Number(num)) ? num : Number(num).toFixed(digit)
}

export const getDate = (data: ScoreStaticItem[]) => data.map(a => formatDate(a.date, 'M/D'))
export const getScoreDate = (data: any[]) => data.map(a => formatDate(a.created, 'M/D'))

export const getScore = (data: ScoreStaticItem[]) => data.map(a => a.score)
export const getCardioScore = (data: any[]) => data.map(a => a.cardio.score)
export const getWalkingScore = (data: any[]) => data.map(a => a.walking.score)
export const getJointScore = (data: any[]) => data.map(a => a.joint.score)
export const getStrengthScore = (data: any[]) => data.map(a => a.strength.score)

export const getEvaluatedAge = (data: ScoreStaticItem[]) => data.map(a => a.evaluatedAge)
export const getEvaluateCommonAge = (data: any[]) => data.map(a => a.common.evaluatedAge)
export const getEvaluateCardiodAge = (data: any[]) => data.map(a => a.cardio.evaluatedAge)
export const getEvaluateWalkingdAge = (data: any[]) => data.map(a => a.walking.evaluatedAge)
export const getEvaluateJointdAge = (data: any[]) => data.map(a => a.joint.evaluatedAge)
export const getEvaluateStrengthdAge = (data: any[]) => data.map(a => a.strength.evaluatedAge)

export const formatPSDate = (date: dayjs.Dayjs) =>
  dayjs(date).tz('Asia/Seoul').format('YYYY-MM-DD (dd').replace('요', ')')

export const getDuration = (d: number) => {
  // FIX: duration.format() 메서드 못찾는 원인 파악 필요. 지금은 하드코딩
  const ms = d * 1000
  const hour = dayjs.duration(ms).hours() ? `${dayjs.duration(ms).hours()}:` : ''
  const minute =
    `${dayjs.duration(ms).minutes()}`.length < 2
      ? `0${dayjs.duration(ms).minutes()}:`
      : `${dayjs.duration(ms).minutes()}:`
  const second =
    `${dayjs.duration(ms).seconds()}`.length < 2
      ? `0${dayjs.duration(ms).seconds()-1}`
      : `${dayjs.duration(ms).seconds()-1}`
  return `${hour}${minute}${second}`
}

export const getSchedule = (startDate: string, total: number, days: string[]) => {
  let day = dayjs(startDate)
  const sendDays = days.map(day => findIndex(DATE_LIST, d => d.value === day))
  const schedule = []
  while (schedule.length < total) {
    if (includes(sendDays, day.day())) {
      schedule.push(formatPSDate(day))
    }
    const nextDay = dayjs(day).add(1, 'day')
    day = nextDay
  }
  return schedule
}

export const isBefore = (standardDate: string, comparedDate: string) => dayjs(comparedDate).isBefore(standardDate)
