import './PercentageBar.scss'

import React from 'react'
import cx from 'classnames'
import { isEmpty } from 'lodash'
import { cutDecimal } from '../utils/misc'

interface Props {
  className?: string
  point: number | React.ReactNode
  cuttingPoint?: number
  score: number
  cuttingScore?: number
  maxScore?: number
  labels?: (string | number)[]
  cuttingLabel?: number
}

const PercentageBar = ({
  className,
  point,
  cuttingPoint = 0,
  score,
  cuttingScore = 0,
  maxScore = 100,
  labels,
  cuttingLabel = 0,
}: Props) => {
  const width = Number(score) === 0 ? 0 : `${(Number(score) / Number(maxScore)) * 100}%`

  return (
    <div className={cx('PercentageBar', className)}>
      <div className="PercentageBar__point">{cutDecimal(Number(point), cuttingPoint)}</div>
      <div className="PercentageBar__bar">
        <div className="PercentageBar__graph" style={{ width }}>
          <div className="PercentageBar__score">{cutDecimal(Number(score), cuttingScore)}점</div>
        </div>
        <div className="PercentageBar__stripe" />
        <div className="PercentageBar__stripe" />
        <div className="PercentageBar__stripe" />
        <div className="PercentageBar__stripe" />
        <div className="PercentageBar__stripe" />
        <div className="PercentageBar__stripe" />
        <div className="PercentageBar__stripe" />
        <div className="PercentageBar__stripe" />
        <div className="PercentageBar__stripe" />
        <div className="PercentageBar__stripe" />
        <div className="PercentageBar__stripe" />
        <div className="PercentageBar__stripe" />
        <div className="PercentageBar__stripe" />
        <div className="PercentageBar__stripe" />
        <div className="PercentageBar__stripe" />
        <div className="PercentageBar__stripe" />
        <div className="PercentageBar__stripe" />
        <div className="PercentageBar__stripe" />
        <div className="PercentageBar__stripe" />
      </div>
      {!isEmpty(labels) && (
        <div className="PercentageBar__labels">
          {labels?.map((label, idx) => (
            <p className="PercentageBar__label" key={`${label}-${idx}`}>
              {cutDecimal(label, cuttingLabel)}
            </p>
          ))}
        </div>
      )}
    </div>
  )
}

export default PercentageBar
