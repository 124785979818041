import axios from 'axios'
import { API_END_POINT } from '../constants/api'

export const instance = axios.create({ baseURL: API_END_POINT })

export interface RequestApiParams {
  url: string
  header?: { [key: string]: any }
  body?: { [key: string]: any }
  method?: 'get' | 'post' | 'patch' | 'delete' | 'put'
}

type RequestParams = RequestApiParams & { isAuthenticated?: boolean }

export const requestApi = async ({
  method = 'get',
  url,
  header,
  body,
  isAuthenticated = false,
}: RequestParams): Promise<any> => {
  const token = localStorage.getItem('token')

  const params: { [key: string]: any } = {
    method,
    url,
    headers: isAuthenticated ? { ...header, authorization: token } : header,
    data: body,
  }
  try {
    const res = await instance.request(params)
    return res
  } catch (error) {
    return error.response
  }
}
