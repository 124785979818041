import * as React from 'react'

function SvgJointSpotBig(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 50 50" width="50" height="50" fill="currentColor" {...props}>
      <path
        className="jointSpotBig_svg__st0"
        d="M25 39.7c-8.2 0-14.8-6.6-14.8-14.8S16.8 10.1 25 10.1s14.8 6.6 14.8 14.8S33.2 39.7 25 39.7zm0-25.4c-5.8 0-10.6 4.7-10.6 10.6S19.2 35.4 25 35.4s10.6-4.7 10.6-10.6S30.8 14.3 25 14.3z"
      />
      <path
        className="jointSpotBig_svg__st0"
        d="M25 46.9C12.8 46.9 2.9 37 2.9 24.8 2.9 12.6 12.8 2.7 25 2.7s22.1 9.9 22.1 22.1c0 12.2-9.9 22.1-22.1 22.1zm0-41c-10.4 0-18.9 8.5-18.9 18.9 0 10.4 8.5 18.9 18.9 18.9s18.9-8.5 18.9-18.9c0-10.4-8.5-18.9-18.9-18.9z"
      />
      <path
        className="jointSpotBig_svg__st0"
        d="M25 50C11.2 50 0 38.8 0 25S11.2 0 25 0s25 11.2 25 25-11.2 25-25 25zm0-48.9C11.8 1.1 1.1 11.8 1.1 25S11.8 48.9 25 48.9 48.9 38.2 48.9 25 38.2 1.1 25 1.1z"
      />
      <path
        className="jointSpotBig_svg__st0"
        d="M25 41.5c-9.1 0-16.6-7.4-16.6-16.6 0-9.1 7.4-16.6 16.6-16.6s16.6 7.4 16.6 16.6c0 9.2-7.5 16.6-16.6 16.6zm0-32.1c-8.6 0-15.5 7-15.5 15.5s7 15.5 15.5 15.5 15.5-7 15.5-15.5S33.6 9.4 25 9.4z"
      />
    </svg>
  )
}

export default SvgJointSpotBig
