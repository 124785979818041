import './MemberList.scss'

import React, { useEffect, useState } from 'react'
import { isNil, isEmpty } from 'lodash'
import { Link } from 'react-router-dom'

import { User } from '../types/model'
import { getAge, getDateDiff } from '../utils/misc'
import { requestApi } from '../utils/api'
import { Option } from './Dropdown'

import Icon from './Icon'
import Dropdown from './Dropdown'
import Loading from './Loading'
import Paginator from './Paginator'

interface Props {
  trainerList: string[]
}

export const MemberListBox = ({ userData }: { userData: User[] | null }) => {
  const isUser = !isNil(userData) && !isEmpty(userData)
  return (
    <ul className="MemberList__list">
      {isNil(userData) && <Loading className="MemberList__loading" />}
      {!isNil(userData) && isEmpty(userData) && '등록된 회원이 없습니다.'}
      {isUser &&
        userData?.map(
          ({
            _id: id,
            name,
            trainer,
            gender,
            birthday,
            latestReportDate,
            latestAttendanceDate,
            latestPersonalSolutionSentDate,
            memo,
          }) => {
            const attendanceDateDiff = latestAttendanceDate ? getDateDiff(latestAttendanceDate, '출석') : ''
            const reportDateDiff = latestReportDate ? getDateDiff(latestReportDate, '인택트') : ''
            const personalSolutionDateDiff = latestPersonalSolutionSentDate
              ? getDateDiff(latestPersonalSolutionSentDate, '솔루션')
              : ''
            return (
              <li className="MemberList__member" key={id}>
                <Link to={`/member/${id}`}>
                  <div>
                    <h3>
                      {name}
                      <span>
                        ({gender === 'female' ? '여' : '남'}, {`${getAge(birthday)}세`}
                        {trainer && `, ${trainer} 트레이너님`})
                      </span>
                    </h3>
                    <p>
                      {attendanceDateDiff}
                      {`${attendanceDateDiff && reportDateDiff && ' • '}${reportDateDiff}`}
                      {`${
                        (attendanceDateDiff || reportDateDiff) && personalSolutionDateDiff && ' • '
                      }${personalSolutionDateDiff}`}
                    </p>
                    {!isEmpty(memo) && <p className="MemberList__memberMemo">{memo}</p>}
                  </div>
                  <Icon name="Arrow" />
                </Link>
              </li>
            )
          }
        )}
    </ul>
  )
}

type UserData = {
  count: number
  data: User[]
}

export default function MemberList({ trainerList }: Props) {
  const DEFAULT_SORT = 'created'
  const DEFAULT_FILTER = '전체회원'

  const [sort, setSort] = useState(DEFAULT_SORT)
  const [filter, setFilter] = useState(DEFAULT_FILTER)
  const [userData, setUserData] = useState<UserData | null>(null)
  const [trainerOption, setTrainerOption] = useState<Option[]>([])
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    getUserData(currentPage)
  }, [sort, currentPage])

  useEffect(() => {
    setCurrentPage(1)
    getUserData(1)
  }, [filter])

  useEffect(() => {
    setTrainerOption(getTrainerOptions(trainerList))
  }, [trainerList])

  const getTrainerOptions = (trainerList: string[] | null) =>
    !isNil(trainerList)
      ? [{ label: DEFAULT_FILTER, value: DEFAULT_FILTER }].concat(
          trainerList.map(trainer => ({ label: `${trainer}님`, value: trainer }))
        )
      : []

  const getUserData = (page: number) => {
    try {
      requestApi({
        url: `/users?sortKey=${sort}&sortValue=${sort === 'name' ? 'asc' : 'desc'}&trainer=${
          filter === DEFAULT_FILTER ? '' : filter
        }&page=${page}`,
        isAuthenticated: true,
      }).then(res => {
        if (res?.status === 200) {
          setUserData(res?.data)
        }
      })
    } catch (e) {
      console.log('request error', e)
    }
  }

  return (
    <div className="MemberList">
      <header className="MemberList__header">
        <h2>{`총 ${userData?.count ?? 0}명 회원`}</h2>
        <div>
          <Dropdown
            className="MemberList__trainerFilter"
            label="트레이너 필터"
            hideLabel
            options={trainerOption}
            defaultOption={DEFAULT_FILTER}
            onSelect={val => {
              setCurrentPage(1)
              setFilter(val)
            }}
          />
          <Dropdown
            className="MemberList__sort"
            label="회원 정렬"
            hideLabel
            options={[
              { label: '등록일순', value: 'created' },
              { label: '가나다순', value: 'name' },
            ]}
            defaultOption={DEFAULT_SORT}
            onSelect={val => setSort(val)}
          />
        </div>
      </header>
      <MemberListBox userData={userData?.data ?? []} />
      <Paginator
        currentPage={currentPage - 1}
        pageCount={userData?.count ? Math.ceil(userData.count / 20) : 1}
        setCurrentPage={setCurrentPage}
      />
    </div>
  )
}
