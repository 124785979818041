export const graphColor = {
  DARK_PURPLE: '#562C8D',
  MEDIUM_PURPLE: '#7F62A8',
  LIGHT_PURPLE: '#D1CAE2',
  PURPLE: 'rgb(135,113,177)',
  BLACK: '#231916',
  LIGHT_GRAY: '#939393',
  DARK_GRAY: '#686968',
  GOOD: '#5B92CD',
  NORMAL: '#61BC8E',
  BAD: '#A23C24',
}
