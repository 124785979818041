import './Button.scss'

import React, { ReactNode } from 'react'
import classNames from 'classnames'
import Loading from './Loading'

export const iconSize = {
  large: 24,
  medium: 20,
  small: 16,
}

interface Props {
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
  children?: ReactNode

  className?: string
  theme?: 'primary' | 'secondary' | 'error' | 'aqua' | 'iconOnly' | 'ghostPurple' | 'ghostGray' | 'ghostBlack' | 'ghostRed'
  size?: 'small' | 'medium' | 'large' | 'small_medium'
  fullWidth?: boolean

  // iconType?: IconType
  title?: string

  isActive?: boolean
  isLoading?: boolean
  disabled?: boolean
  testId?: string
}

export default function Button({
  type = 'button',
  onClick,
  children,
  className,
  theme = 'primary',
  size = 'medium',
  fullWidth,
  // iconType,
  title,
  isActive,
  isLoading = false,
  disabled,
  testId,
}: Props) {
  return (
    <button
      type={type}
      onClick={onClick}
      className={classNames('Button', className, `Button--${size}`, `Button--${theme}`, {
        disabled,
        fullWidth,
        isLoading,
        isActive,
      })}
      disabled={disabled}
      data-testid={testId}
    >
      {theme === 'iconOnly' ? (
        children
      ) : (
        <>
          {isLoading && <Loading />}
          {!isLoading && (
            <span role="button" className="Button__text">
              {children || title}
            </span>
          )}
        </>
      )}
    </button>
  )
}
