import './EditMemberInfo.scss'

import React, { useState, useEffect, useRef } from 'react'
import { includes, isNil, pull } from 'lodash'
import { Controller, useForm, SubmitHandler } from 'react-hook-form/dist/index.ie11'

import { User } from '../types/model'
import useRouter from '../hooks/useRouter'
import { requestApi } from '../utils/api'
import { formatDate, today } from '../utils/misc'
import { DATE_LIST, PERSONAL_SOLUTION_SEND_HOUR_LIST, PLAN_LIST, PAIN_PART_LIST, LEVEL_LIST, PERSONAL_SOLUTION_SEND_MINUTE_LIST, PERSONAL_MAX_DURATION_MINUTE_LIST } from '../constants/label'

import Input from './Input'
import Radio from './Radio'
import Button from './Button'
import Alert from './Alert'
import InfoItem from './InfoItem'
import Selection from './Selection'
import Icon from './Icon'
import Checkbox, { CheckboxGroup } from './Checkbox'

interface Props {
  data?: User | null
  isEdit?: boolean
  trainerList?: string[] | null
}

type FormValues = {
  _id: string
  name: string
  contact: string
  job: string
  gender: string
  height: number | undefined
  birthday: string
  level?: string
  painPartList?: string
  personalSolutionSendDays?: string
  personalSolutionSendHour?: string
  personalSolutionSendMinute?: string
  personalMaxDurationMinute?: number
  membershipType?: string
  membershipStart?: string
  membershipEnd?: string
  trainer?: string
  memo?: string
}

const defaultValues: FormValues = {
  _id: '',
  name: '',
  contact: '',
  job: '',
  gender: 'male',
  height: undefined,
  birthday: '',
  level: '',
  painPartList: '',
  personalSolutionSendDays: '',
  personalSolutionSendHour: '',
  personalSolutionSendMinute: '',
  personalMaxDurationMinute: 8888888,
  membershipType: '',
  membershipStart: '',
  membershipEnd: '',
  trainer: '',
  memo: '',
}

const JOB_LIST = [
  '사업 및 관리직',
  '회사원',
  '전문직',
  '서비스직',
  '판매직',
  '농림어업직',
  '기능직',
  '기계조작 및 운전',
  '군인',
  '주부 및 학생',
]

export default function EditMemberInfo({ data, isEdit, trainerList }: Props) {
  const { history } = useRouter()
  const memberId = data?._id ?? null

  const [isLoading, setIsLoading] = useState(false)
  const [serverError, setServerError] = useState<string | null>(null)
  const [painPartList, setPainPartList] = useState<string[]>([])
  const [personalSolutionSendDays, setPersonalSolutionSendDays] = useState<string[]>([])
  const [isPSSendDaysError, setIsPSSendDaysError] = useState(false)
  const [isPSSendDaysDisabled, setIsPSSendDaysDisabled] = useState(false)

  const { register, handleSubmit, control, getValues, setValue, errors, formState } = useForm<FormValues>({
    mode: 'all',
    defaultValues,
    shouldFocusError: false,
  })

  useEffect(() => {
    if (!isNil(data) && !isNil(trainerList)) {
      const config = {
        shouldValidate: true,
        shouldDirty: true,
      }
      setValue('name', data.name, config)
      setValue('contact', data.contact, config)
      setValue('job', data.job, config)
      setValue('gender', data.gender, config)
      setValue('height', data.height, config)
      setValue('birthday', formatDate(data.birthday), config)
      setValue('level', data.level, config)
      setValue('personalSolutionSendHour', data.personalSolutionSendHour, config)
      setValue('personalSolutionSendMinute', data.personalSolutionSendMinute, config)
      setValue('personalMaxDurationMinute', data.personalMaxDurationMinute, config)
      setValue('membershipType', data.membershipType, config)
      setValue('trainer', data.trainer, config)
      setValue('memo', data.memo, config)
      data.membershipStart && setValue('membershipStart', formatDate(data.membershipStart), config)
      data.membershipEnd && setValue('membershipEnd', formatDate(data.membershipEnd), config)

      setPainPartList(data.painPartList || [])
      setPersonalSolutionSendDays(data.personalSolutionSendDays || [])
    }
  }, [data, trainerList, setValue])

  const formRef = useRef<HTMLFormElement>(null)
  const { touched, isValid } = formState

  const isErrorField = (key: keyof FormValues) => touched[key] && errors[key]

  const onSubmit: SubmitHandler<FormValues> = async data => {
    const token = localStorage.getItem('token')
    try {
      setIsLoading(true)
      const res = await requestApi({
        url: `/users${isEdit ? `/${memberId}` : ''}`,
        method: isEdit ? 'put' : 'post',
        header: { authorization: token },
        body: data,
      })

      if (res.status === 200) {
        // TODO: POST res의 user 키로 래핑되어있는거 제거 요청함
        // const createdMemberId = res.data?._id
        const createdMemberId = res.data?.user?._id || res.data?._id
        history.push(`/member/${createdMemberId}`)
        return
      } else {
        setServerError('회원 정보 저장을 실패했습니다. 다시 시도해주세요.')
      }
    } catch (e) {
      setServerError('회원 정보 저장을 실패했습니다. 다시 시도해주세요.')
      console.error('request error', e)
    } finally {
      setIsLoading(false)
    }
  }

  const deleteMember = () => {
    if (window.confirm('회원을 삭제하시겠습니까? \n삭제 시 회원 정보 및 건강 평가 데이터가 삭제됩니다.')) {
      requestApi({
        method: 'delete',
        url: `users/${memberId}`,
        isAuthenticated: true,
      }).then(res => {
        if (res?.status === 200) {
          history.push('/')
        } else {
          setServerError('회원을 삭제하는데 문제가 생겼습니다. 페이지를 새로고침 해주세요.')
        }
      })
    }
  }

  const handlePersonalSolutionSendDays = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target
    const newPersonalSolutionSendDays = checked ? [...personalSolutionSendDays, value] : pull(personalSolutionSendDays, e.target.value)
    setPersonalSolutionSendDays(newPersonalSolutionSendDays)
    setIsPSSendDaysError(newPersonalSolutionSendDays.length !== 0 && newPersonalSolutionSendDays.length < 2)
    setIsPSSendDaysDisabled(newPersonalSolutionSendDays.length >= 4)
  }


  return (
    <div className="EditMemberInfo">
      <form className="EditMemberInfo__form" onSubmit={handleSubmit(onSubmit)} ref={formRef} autoComplete="off">
        {serverError ? <Alert className="EditMemberInfo__alert" text={serverError} theme="error" /> : null}
        <fieldset className="EditMemberInfo__userInfo">
          <legend className="EditMemberInfo__title">고객 기본 정보 <span>*</span></legend>
          {isEdit && <InfoItem className="EditMemberInfo__userNumber" label="회원번호" value={data?.userNumber} />}
          <Input
            ref={register({ required: true })}
            name="name"
            label="이름"
            placeholder="이름을 입력하세요."
            error={!!isErrorField('name')}
          />
          <Input
            ref={register({ required: true })}
            name="contact"
            label="연락처"
            placeholder="연락처를 입력하세요."
            error={!!isErrorField('contact')}
          />
          <Selection
            ref={register({ required: true })}
            name="job"
            label="직업"
            options={JOB_LIST}
            placeholder="직업을 선택하세요."
          />
          <Controller
            control={control}
            name="gender"
            rules={{ required: true }}
            render={({ onChange, value }) => (
              <Radio
                className="EditMemberInfo__radio"
                name="gender"
                label="성별"
                selectedValue={value}
                options={[
                  { label: '남성', value: 'male' },
                  { label: '여성', value: 'female' },
                ]}
                error={!!isErrorField('gender')}
                onChange={value => {
                  touched.gender = true
                  onChange(value)
                }}
              />
            )}
          />
          <Input
            ref={register({ required: true })}
            type="number"
            name="height"
            label="신장(cm)"
            placeholder="신장을 입력하세요."
            error={!!isErrorField('height')}
          />
          <Input
            ref={register({ required: true })}
            type="date"
            name="birthday"
            label="생년월일"
            placeholder="생년월일 입력하세요."
            error={!!isErrorField('birthday')}
            max={`${today.year() - 7}-12-31`}
            min={`${today.year() - 99}-01-01`}
          />
        </fieldset>
        <fieldset className="EditMemberInfo__solution">
          <legend className="EditMemberInfo__title">퍼스널 솔루션 정보</legend>
          <Selection
            ref={register}
            name="level"
            label="운동 숙련도"
            options={LEVEL_LIST}
            placeholder="운동 숙련도를 선택하세요."
          />
          <CheckboxGroup title="통증부위" subTitle="* 없을 시 미선택">
            {PAIN_PART_LIST.map(({ label, value }) => (
              <Checkbox
                key={value}
                name="painPartList"
                label={label}
                value={value}
                ref={register}
                checked={includes(painPartList, value)}
              />
            )
            )}
          </CheckboxGroup>
          <CheckboxGroup title="요일 선택" subTitle="* 최소 2개, 최대 4개 선택하세요." error={isPSSendDaysError}>
            {DATE_LIST.map(({ label, value }) => (
              <Checkbox
                key={value}
                name="personalSolutionSendDays"
                label={label}
                value={value}
                onChange={handlePersonalSolutionSendDays}
                ref={register}
                checked={includes(personalSolutionSendDays, value)}
                disabled={isPSSendDaysDisabled}
              />
            )
            )}
          </CheckboxGroup>
          <Selection
            ref={register}
            name="personalSolutionSendHour"
            label="시간대 선택"
            options={PERSONAL_SOLUTION_SEND_HOUR_LIST}
            placeholder="시간대를 선택하세요."
          />
          <Selection
            ref={register}
            name="personalSolutionSendMinute"
            label="분 선택"
            options={PERSONAL_SOLUTION_SEND_MINUTE_LIST}
            placeholder="분을 선택하세요."
          />
           <Selection
            ref={register}
            name="personalMaxDurationMinute"
            label="영상 길이 선택"
            options={PERSONAL_MAX_DURATION_MINUTE_LIST}
            placeholder="영상 길이를 선택하세요."
          />
          <Selection
            ref={register}
            name="membershipType"
            label="플랜"
            options={PLAN_LIST}
            placeholder="플랜 종류를 선택하세요."
          />
        </fieldset>
        <fieldset>
          <legend className="EditMemberInfo__title">회원 등록 정보</legend>
          <Input
            ref={register}
            type="date"
            name="membershipStart"
            label="시작일"
            placeholder="회원 등록일을 입력하세요."
            error={!!isErrorField('membershipStart')}
          />
          <Input
            ref={register}
            type="date"
            name="membershipEnd"
            label="종료일"
            placeholder="회원 종료일을 입력하세요."
            error={!!isErrorField('membershipEnd')}
            min={getValues('membershipStart')}
          />
          <Selection
            ref={register}
            name="trainer"
            label="트레이너"
            placeholder="담당 트레이너를 선택해주세요."
            options={trainerList}
          />
          <textarea ref={register} name="memo" placeholder="메모" />
        </fieldset>
        <Button type="submit" title="저장하기" disabled={!isValid || isLoading || isPSSendDaysError} isLoading={isLoading} fullWidth />
      </form>
      {isEdit && (
        <Button className="EditMemberInfo__delete" type="button" theme="secondary" onClick={deleteMember} fullWidth>
          <Icon name="Delete" /> 회원 삭제하기
        </Button>
      )}
    </div>
  )
}
