import * as React from 'react'

function Download(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} width="36" height="36" viewBox="0 0 36 36" fill="#fff" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.7466 23.9793C17.7766 24.0177 17.815 24.0488 17.8589 24.0701C17.9028 24.0915 17.9509 24.1026 17.9997 24.1026C18.0485 24.1026 18.0966 24.0915 18.1405 24.0701C18.1843 24.0488 18.2227 24.0177 18.2528 23.9793L22.7528 18.286C22.9175 18.0771 22.7689 17.7677 22.4997 17.7677H19.5225V4.17125C19.5225 3.99447 19.3778 3.84982 19.201 3.84982H16.7903C16.6135 3.84982 16.4689 3.99447 16.4689 4.17125V17.7637H13.4997C13.2305 17.7637 13.0818 18.073 13.2466 18.282L17.7466 23.9793ZM32.705 22.573H30.2943C30.1175 22.573 29.9729 22.7177 29.9729 22.8945V29.082H6.02647V22.8945C6.02647 22.7177 5.88183 22.573 5.70504 22.573H3.29433C3.11754 22.573 2.9729 22.7177 2.9729 22.8945V30.8498C2.9729 31.561 3.54745 32.1355 4.25861 32.1355H31.7408C32.4519 32.1355 33.0265 31.561 33.0265 30.8498V22.8945C33.0265 22.7177 32.8818 22.573 32.705 22.573Z"
        fill="#fff"
      />
    </svg>
  )
}

export default Download
