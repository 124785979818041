import './AttendanceConfirmPage.scss'

import React, { useEffect, useState } from 'react'
import { isNil } from 'lodash'
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'
import { Attendance } from '../types/model'
import useRouter from '../hooks/useRouter'
import { requestApi } from '../utils/api'

import Button from '../components/Button'
import Icon from '../components/Icon'
import Loading from '../components/Loading'

import LogoLong from '../images/logo-long.png'
import LogoSmall from '../images/logo-small.png'

dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
  weekdays: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
})

const Done = () => (
  <>
    <Icon className="AttendanceConfirmPage__icon" name="VCircle" />
    <h2>출석 확인이 완료됐습니다.</h2>
    <p>회원님의 건강나이 개선을 약속드립니다.</p>
    <img className="AttendanceConfirmPage__logoSmall" src={LogoSmall} alt="Must move gym logo" />
  </>
)

const Delete = ({ date = '', pointName = '' }) => (
  <>
    <Icon className="AttendanceConfirmPage__icon" name="WarningCircle" />
    <h2>출석 확인 요청이 취소됐습니다.</h2>
    <p>
      {dayjs(date).tz('Asia/Seoul').format(`YYYY년 MM월 DD일 dddd h:mm A`)} 머스트무브 {pointName}에서 전송한 출석 확인
      요청이 취소됐습니다.
      <br />
      궁금하신 사항은 센터로 문의 부탁드립니다.
    </p>
    <img className="AttendanceConfirmPage__logoSmall" src={LogoSmall} alt="Must move gym logo" />
  </>
)

const Pending = ({ date = '', pointName = '', name = '', onClick = () => {}, isLoading = false }) => (
  <>
    <img className="AttendanceConfirmPage__logoLong" src={LogoLong} alt="Must move gym logo" />
    <h2 className="AttendanceConfirmPage__pointName">{pointName}</h2>
    <h3>안녕하세요, {name} 회원님.</h3>
    <p className="AttendanceConfirmPage__desc">
      아래 시간대에 트레이닝을 완료하셨다면
      <br />
      출석 확인 부탁드립니다.
    </p>
    <p className="AttendanceConfirmPage__time">{dayjs(date).tz('Asia/Seoul').format(`YYYY년 MM월 DD일 dddd h:mm A`)}</p>
    <Button title="출석 확인하기" onClick={onClick} isLoading={isLoading} fullWidth />
  </>
)

export default function AttendanceConfirmPage() {
  const {
    match: {
      params: { id: attendanceToken },
    },
  } = useRouter<{ id: string }>()
  const [attendance, setAttendance] = useState<Attendance | null>(null)
  const [attendanceError, setAttendanceError] = useState<string | null>(null)
  const [isProcessing, setIsProcessing] = useState(false)

  useEffect(() => {
    if (attendanceToken) {
      requestApi({ url: `/attendances/token/${attendanceToken}`, body: {} }).then(res => {
        if (res?.status === 200) {
          setAttendance(res?.data)
        } else {
          setAttendanceError('출석 정보를 조회하는 데 문제가 발생했습니다. 페이지를 새로고침 해주세요.')
        }
      })
    }
  }, [attendanceToken])

  const confirmAttendance = () => {
    setIsProcessing(true)
    requestApi({ method: 'post', url: `/attendances/confirm`, body: { token: attendanceToken } }).then(res => {
      if (res?.status === 200) {
        setAttendance(res?.data)
      } else {
        setAttendanceError('출석 정보를 조회하는 데 문제가 발생했습니다. 페이지를 새로고침 해주세요.')
      }
      setIsProcessing(false)
    })
  }

  if (attendanceError) {
    return <div className="AttendanceConfirmPage">{<p>{attendanceError}</p>}</div>
  }

  return (
    <div className="AttendanceConfirmPage">
      {isNil(attendance) && <Loading className="AttendanceConfirmPage__loading" isVisible />}
      {attendance?.status === 'DONE' && <Done />}
      {attendance?.status === 'CANCEL' && <Delete date={attendance?.created} pointName={attendance?.pointName} />}
      {attendance?.status === 'PENDING' && (
        <Pending
          date={attendance?.created}
          pointName={attendance?.pointName}
          name={attendance?.name}
          onClick={confirmAttendance}
          isLoading={isProcessing}
        />
      )}
    </div>
  )
}
