import * as React from 'react'

function Link(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="27" height="26" viewBox="0 0 27 26" fill="none" {...props}>
      <path d="M15.0484 17.4492C15.0048 17.406 14.9458 17.3818 14.8844 17.3818C14.823 17.3818 14.7641 17.406 14.7205 17.4492L11.3486 20.8211C9.78746 22.3823 7.15264 22.5477 5.42898 20.8211C3.70241 19.0945 3.86782 16.4626 5.42898 14.9015L8.80085 11.5296C8.89081 11.4396 8.89081 11.2916 8.80085 11.2017L7.64594 10.0468C7.60231 10.0036 7.54339 9.97933 7.48199 9.97933C7.42059 9.97933 7.36167 10.0036 7.31804 10.0468L3.94616 13.4186C1.49125 15.8736 1.49125 19.8461 3.94616 22.2981C6.40107 24.7501 10.3736 24.753 12.8256 22.2981L16.1975 18.9262C16.2875 18.8363 16.2875 18.6883 16.1975 18.5983L15.0484 17.4492ZM22.5524 3.69476C20.0975 1.23985 16.125 1.23985 13.6729 3.69476L10.2982 7.06664C10.255 7.11027 10.2307 7.16919 10.2307 7.23059C10.2307 7.29199 10.255 7.35091 10.2982 7.39454L11.4502 8.54655C11.5401 8.6365 11.6881 8.6365 11.7781 8.54655L15.15 5.17467C16.7111 3.61351 19.3459 3.44811 21.0696 5.17467C22.7962 6.90123 22.6308 9.53315 21.0696 11.0943L17.6977 14.4662C17.6545 14.5098 17.6303 14.5687 17.6303 14.6301C17.6303 14.6915 17.6545 14.7505 17.6977 14.7941L18.8526 15.949C18.9426 16.039 19.0906 16.039 19.1805 15.949L22.5524 12.5771C25.0044 10.1222 25.0044 6.14967 22.5524 3.69476V3.69476ZM16.0959 8.94409C16.0523 8.90089 15.9934 8.87665 15.932 8.87665C15.8706 8.87665 15.8117 8.90089 15.768 8.94409L9.19549 15.5137C9.15229 15.5574 9.12806 15.6163 9.12806 15.6777C9.12806 15.7391 9.15229 15.798 9.19549 15.8416L10.3446 16.9907C10.4346 17.0807 10.5825 17.0807 10.6725 16.9907L17.2421 10.4211C17.3321 10.3311 17.3321 10.1832 17.2421 10.0932L16.0959 8.94409Z" fill="black" fillOpacity="0.65" />
    </svg>

  )
}

export default Link
