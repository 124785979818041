import React from 'react'
import cx from 'classnames'
import { isNil } from 'lodash'
import InfoItem from './InfoItem'
import './Info.scss'

type Data = {
  label: string
  value?: string | null
}

interface Props {
  className?: string
  title: string
  data: Data[] | null | undefined
  error?: string | null
}

const Info = ({ className, title, data, error }: Props) => (
  <section className={cx('Info', className)}>
    <h2 className="Info__title">{title}</h2>
    {error && <p className="Info__error">{error}</p>}
    {!error && !isNil(data) && data?.map(({ label, value }, idx) => <InfoItem key={idx} label={label} value={value} />)}
  </section>
)

export default Info
