import { useEffect } from 'react'

interface EventParam {
  eventName: string
  callback: (e: any) => void
}

export default function useEventListener(events: EventParam[], eventTarget: 'window' | 'document' = 'document') {
  useEffect(() => {
    const eventObject = eventTarget === 'document' ? document : window

    events.forEach(({ eventName, callback }) => {
      eventObject.addEventListener(eventName, callback)
    })

    return () => {
      events.forEach(({ eventName, callback }) => {
        eventObject.removeEventListener(eventName, callback)
      })
    }
    // eslint-disable-next-line
  }, [])
}
