import * as React from 'react'

function Play(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="57" height="56" viewBox="0 0 57 56" fill="currentColor" {...props}>
      <path d="M39.4558 26.989L18.9779 9.02967C18.2142 8.36249 17.0957 8.96405 17.0957 10.0414V45.9601C17.0957 47.0375 18.2142 47.639 18.9779 46.9719L39.4558 29.0125C40.0419 28.4984 40.0419 27.5031 39.4558 26.989Z" fill="#fff" />
    </svg>
  )
}

export default Play
