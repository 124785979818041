import './Alert.scss'

import React, { ReactNode } from 'react'
import classNames from 'classnames'
import Loading from './Loading'

interface Props {
  className?: string
  theme?: 'default' | 'success' | 'error'
  text?: string
  children?: ReactNode
  isLoading?: boolean
}

export default function Alert({ className, theme = 'default', text, children, isLoading }: Props) {
  return (
    <div className={classNames('Alert', className, theme)}>
      {isLoading && <Loading />}
      {text || children}
    </div>
  )
}
