import * as React from 'react'

function Close(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" {...props}>
      <path d="M8.92473 7.99921L13.6122 2.41171C13.6908 2.31885 13.6247 2.17778 13.5033 2.17778H12.0783C11.9944 2.17778 11.914 2.21528 11.8587 2.27957L7.99258 6.88849L4.12651 2.27957C4.07294 2.21528 3.99258 2.17778 3.90687 2.17778H2.48187C2.36044 2.17778 2.29437 2.31885 2.37294 2.41171L7.06044 7.99921L2.37294 13.5867C2.35534 13.6074 2.34405 13.6327 2.3404 13.6596C2.33676 13.6866 2.34092 13.714 2.35239 13.7386C2.36386 13.7632 2.38216 13.7841 2.40511 13.7986C2.42806 13.8131 2.4547 13.8208 2.48187 13.8206H3.90687C3.9908 13.8206 4.07115 13.7831 4.12651 13.7189L7.99258 9.10992L11.8587 13.7189C11.9122 13.7831 11.9926 13.8206 12.0783 13.8206H13.5033C13.6247 13.8206 13.6908 13.6796 13.6122 13.5867L8.92473 7.99921Z" />
    </svg>
  )
}

export default Close
