import './DiseasePrediction.scss'

import React, { useEffect, useState } from 'react'
import { filter, find, isEmpty, isNil, isString } from 'lodash'

import { requestApi } from '../utils/api'

import Icon from '../components/Icon'
import Loading from '../components/Loading'
import Button from '../components/Button'
import CircleGraph from '../components/CircleGraph'
import Modal, { ModalContent } from '../components/Modal'

import type { LifeExpectancy, LifeExpectancyDetail, DiseaseInfo, DiseaseRaking, InfoImage, UserInfo } from '../types/model'
import UserInfoTag from './UserInfoTag'
import ExpectAge from '../pages/ExpectAge'

const EMOJI = {
  VERY_GOOD: '💪 😃',
  GOOD: '👏 🙂',
  NORMAL: '❗ 🤔',
  BAD: '️️‼️ ️😨',
  VERY_BAD: '❌ 😱',
}

const RISK_LABEL = {
  SAFE: "안전",
  NORMAL: "보통",
  CAUTION: "주의",
  DANGER: "다소 위험",
  VERY_DANGER: "매우 위험"
}

const DEFAULT_SHOW_COUNT = 3

export default function DiseasePrediction({ reportId }: { reportId: string }) {
  const [prediction, setPrediction] = useState<any>(null)
  const [modalContent, setModalContent] = useState<ModalContent | null>(null)
  const [showMore, setShowMore] = useState(false)

  useEffect(() => {
    if (!isEmpty(reportId)) {
      requestApi({ url: `/reports/${reportId}/disease-prediction` }).then(res => {
        if (res?.status === 200) {
          setPrediction(res?.data)
        } else {
          setPrediction('질환 예측 정보를 조회하는 데 문제가 발생했습니다. url을 확인 해주세요.')
        }
      })
    } else {
      setPrediction('질환 예측 정보를 조회하는 데 문제가 발생했습니다. url을 확인 해주세요.')
    }
  }, [reportId])

  if (isNil(prediction)) {
    return (
      <div className="DiseasePrediction">
        <Loading />
      </div>
    )
  }

  if (isString(prediction)) {
    return (
      <div className="DiseasePrediction">
        {prediction}
      </div>
    )
  }

  const LIFE_EXPECTANCY: LifeExpectancy = find(prediction[0], info => info.type === 'LIFE_EXPECTANCY') || {}
  const LIFE_EXPECTANCY_DETAIL: LifeExpectancyDetail = find(prediction[0], info => info.type === 'LIFE_EXPECTANCY_DETAIL')
  const DISEASE_RANKING: DiseaseRaking = find(prediction[0], info => info.type === 'DISEASE_RANKING')
  const DISEASE_RANKING2: DiseaseRaking = find(prediction[1], info => info.type === 'DISEASE_RANKING')
  const DISEASE_INFO: DiseaseInfo[] = filter(prediction[0], info => info.type === 'DISEASE_INFO')
  const INFO_IMAGE: InfoImage = find(prediction[0], info => info.type === 'INFO_IMAGE')
  const USER_INFO: UserInfo = find(prediction[0], info => info.type === 'USER_INFO')

  const expectAge = `${LIFE_EXPECTANCY_DETAIL?.lifeExpectancy?.min}~${LIFE_EXPECTANCY_DETAIL?.lifeExpectancy?.max}세`
  let pre_probability=[0,0,0] ;
  // 직전 인택트가 없는 경우 빈칸으로 처리 
  if(prediction[1]){
      const DISEASE_RANKING3 = [ DISEASE_RANKING.diseases[0].title, DISEASE_RANKING.diseases[1].title, DISEASE_RANKING.diseases[2].title ]
      DISEASE_RANKING2.diseases.map(({title, probability})=>{
          if(DISEASE_RANKING3[0]===title){
            pre_probability[0]=probability
          }else if(DISEASE_RANKING3[1]===title){
            pre_probability[1]=probability
          }else if(DISEASE_RANKING3[2]===title){
            pre_probability[2]=probability
          }
      })
  }

  //console.log('DISEASE_RANKING',DISEASE_RANKING)
  return (
    <div className="DiseasePrediction">
      <Modal content={modalContent} isVisible={!isNil(modalContent)} onClose={() => setModalContent(null)} />
      <section className={`section1 ${LIFE_EXPECTANCY.evaluated}`}>
        인택트 측정결과 {LIFE_EXPECTANCY.name}님의 기대 수명은 {expectAge}로 예측됩니다.
        <br />
        <div>{EMOJI[LIFE_EXPECTANCY.evaluated]}</div>
      </section>
      <ExpectAge name={LIFE_EXPECTANCY_DETAIL.name} age={LIFE_EXPECTANCY_DETAIL.age} lifeExpectancy={LIFE_EXPECTANCY_DETAIL.lifeExpectancy} created={LIFE_EXPECTANCY_DETAIL.created} />
      <section className="DiseaseRanking">
        <h2>위험 질환 순위</h2>
        {DISEASE_RANKING.diseases.slice(0, DEFAULT_SHOW_COUNT).map(({ title, description, probability, risk }, idx) => (
          <div key={title} className="disease">
            <div className="percent">
              <CircleGraph percent={probability} />
            </div>
            <div className="info">
              <h3 style={{fontSize:21}}>{`${idx + 1}. ${title}`}</h3>
              <p>발병 확률 <b>{probability}%</b>로 <b>{RISK_LABEL[risk]}</b></p>
              {
                pre_probability[0] ?
                (<p className="preP" style={{fontSize:13, marginBottom: 8,marginTop:0}}>( 직전 인택트 발병 확률 : <b>{pre_probability[idx]}%</b>)</p>)
                :
                ''
              }             
              <Button title="질환 설명 자세히 보기" size="small_medium" onClick={() => setModalContent({ title, description })} />
            </div>
          </div>
        ))}
        {/* {!showMore && <Button theme="ghostPurple" onClick={() => setShowMore(true)}>더보기</Button>}
        {showMore && DISEASE_RANKING.diseases.slice(DEFAULT_SHOW_COUNT).map(({ title, description, probability, risk }, idx) => (
          <div key={title} className="disease">
            <div className="percent">
              <CircleGraph percent={probability} />
            </div>
            <div className="info">
              <h3>{`${idx + 4}. ${title}`}</h3>
              <p>발병 확률 {probability}%로 {RISK_LABEL[risk]}</p>
              <Button title="질환 설명 자세히 보기" size="small" onClick={() => setModalContent({ title, description })} />
            </div>
          </div>
        ))} */}
      </section>
      {DISEASE_INFO.slice(0, DEFAULT_SHOW_COUNT).map(({ rank, title, detail }) => {
        return (
          <section key={title} className="DiseaseInfo">
            <h2>{rank}위 위험 질환: {title}</h2>
            <p>회원님의 위험 질환 {rank}위 {title}의 상세 측정 결과 중 관리가 필요한 항목들 입니다.</p>
            {detail.filter(a => a.bad).map(({ name, score }) => (
              <div key={`${name}-${score}`} className="detail">
                <Icon name="RedWarningCircle" />
                {name} 측정 결과 {score}점이며, 평균보다 나쁨
              </div>
            ))}
          </section>
        )
      })}
      <section className="InfoImage">
        <img alt="인택트 퍼스널 솔루션" src={INFO_IMAGE.image} />
        <h2>{INFO_IMAGE.title}</h2>
        <p>{INFO_IMAGE.description}</p>
      </section>
      <UserInfoTag userInfo={USER_INFO} />
    </div>
  )
}
