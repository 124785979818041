import './EditCenter.scss'

import React, { ReactText, useEffect, useState } from 'react'
import { isEmpty, isNil } from 'lodash'
import { useForm } from 'react-hook-form/dist/index.ie11'

import { Center } from '../types/model'
import { requestApi } from '../utils/api'
import { KAKAO_CHANEL } from '../constants/url'
import useRouter from '../hooks/useRouter'

import BasicLayout from '../templates/BasicLayout'
import Info from '../components/Info'
import LinkButton from '../components/LinkButton'
import Button from '../components/Button'
import Input from '../components/Input'
import Alert from '../components/Alert'
import Icon from '../components/Icon'

type AlertMessage = {
  isError: boolean
  message: string
}

export default function EditCenterPage() {
  const { history } = useRouter()
  const [centerInfo, setCenterInfo] = useState<Center | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>(null)

  const centerId = localStorage.getItem('centerId')
  const { register, handleSubmit } = useForm({
    mode: 'all',
    shouldFocusError: false,
  })

  useEffect(() => {
    try {
      requestApi({ url: `/centers/${centerId}`, isAuthenticated: true }).then(res => {
        if (res?.status === 200) {
          setCenterInfo(res?.data)
        } else {
          setError('센터 정보를 불러오지 못했습니다. 페이지를 새로고침 해주세요.')
        }
      })
    } catch (e) {
      setError('센터 정보를 불러오지 못했습니다. 페이지를 새로고침 해주세요.')
      console.error('request error', e)
    }
  }, [centerId])

  const goBack = () => history.goBack()

  const getCenterData = () => {
    return isNil(centerInfo)
      ? null
      : [
          { label: 'ID', value: localStorage.getItem('loginId') ?? '' },
          { label: '센터명', value: centerInfo?.centerName ?? '' },
          { label: '지점명', value: centerInfo?.pointName ?? '' },
          { label: '연락처', value: centerInfo?.contact ?? '' },
        ]
  }

  const addNewInput = () => {
    const newTrainerList = centerInfo?.trainerList.concat('') || ['']
    const newCenterIngo = centerInfo ? { ...centerInfo, trainerList: newTrainerList } : centerInfo
    setCenterInfo(newCenterIngo)
  }

  const deleteTrainer = async (trainer: ReactText) => {
    if (
      window.confirm(
        `${trainer} 트레이너를 삭제하시겠습니까? 트레이너 삭제 시 회원 정보에는 트레이너 정보가 남아있습니다.`
      )
    ) {
      const newTrainerList = centerInfo?.trainerList.filter(a => a !== trainer)
      onSubmit(newTrainerList)
    }
  }

  const onSubmit = async (data: any) => {
    const trainerList = Object.values(data).filter(a => !isEmpty(a))
    const updatedData = { ...centerInfo, trainerList }
    try {
      requestApi({ method: 'put', url: `/centers/${centerId}`, body: updatedData, isAuthenticated: true }).then(res => {
        if (res?.status === 200) {
          setCenterInfo(res?.data)
          setAlertMessage({ isError: false, message: '트레이너 정보를 성공적으로 저장했습니다.' })
        } else {
          setAlertMessage({
            isError: true,
            message: '트레이너 정보를 저장하는데 문제가 생겼습니다. 다시 시도해주세요.',
          })
        }
      })
    } catch (e) {
      setAlertMessage({ isError: true, message: '트레이너 정보를 저장하는데 문제가 생겼습니다. 다시 시도해주세요.' })
    }
  }

  return (
    <BasicLayout
      className="EditCenter"
      headerTitle="센터 정보"
      headerColor="black"
      headerLeftEl={<button onClick={goBack}>이전</button>}
    >
      <Info title="센터 정보" data={getCenterData()} error={error} />
      <LinkButton
        className="EditCenter__centerEditLink"
        to={KAKAO_CHANEL}
        theme="secondary"
        title="센터 정보 수정 문의"
        external
        fullWidth
      />
      <section className="EditCenter__trainerSection">
        <h2 className="EditCenter__title">트레이너 등록</h2>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          {!isNil(alertMessage) && (
            <Alert className="EditCenter__alert" text={alertMessage.message} theme={alertMessage.isError ? 'error' : 'success'} />
          )}
          {!isEmpty(centerInfo?.trainerList) && (
            <ul className="EditCenter__trainerList">
              {centerInfo?.trainerList.map((trainer, idx) => (
                <li className="EditCenter__trainer" key={`${trainer}-${idx}`}>
                  <Input
                    ref={register}
                    name={`${trainer}-${idx}`}
                    label={`${idx}`}
                    defaultValue={trainer}
                    placeholder="트레이너명을 입력하세요."
                    rightEl={
                      <button
                        className="EditCenter__deleteTrainer"
                        type="button"
                        onClick={() => deleteTrainer(trainer)}
                      >
                        삭제
                      </button>
                    }
                  />
                </li>
              ))}
            </ul>
          )}
          <Button className="EditCenter__addTrainer" theme="secondary" onClick={addNewInput} fullWidth>
            <Icon name="AddMember" />
            트레이너 입력란 추가
          </Button>
          <Button className="EditCenter__save" type="submit" title="저장하기" fullWidth />
        </form>
      </section>
    </BasicLayout>
  )
}
