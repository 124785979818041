import * as React from 'react'

function RedWarningCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
      <ellipse cx="15" cy="14.9992" rx="15" ry="14.9995" fill="white" />
      <path d="M13.8564 20.214C13.8564 20.5171 13.9769 20.8078 14.1912 21.0221C14.4055 21.2365 14.6962 21.3569 14.9993 21.3569C15.3024 21.3569 15.5931 21.2365 15.8074 21.0221C16.0218 20.8078 16.1422 20.5171 16.1422 20.214C16.1422 19.9109 16.0218 19.6202 15.8074 19.4059C15.5931 19.1916 15.3024 19.0711 14.9993 19.0711C14.6962 19.0711 14.4055 19.1916 14.1912 19.4059C13.9769 19.6202 13.8564 19.9109 13.8564 20.214ZM14.4279 17.214H15.5707C15.6493 17.214 15.7136 17.1497 15.7136 17.0711V8.78544C15.7136 8.70686 15.6493 8.64258 15.5707 8.64258H14.4279C14.3493 8.64258 14.285 8.70686 14.285 8.78544V17.0711C14.285 17.1497 14.3493 17.214 14.4279 17.214Z" fill="#F13642" stroke="#F13642" />
    </svg>

  )
}

export default RedWarningCircle
