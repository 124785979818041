import * as React from 'react'

function SvgAttendance(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 36 36" fill="currentColor" {...props}>
      <path d="M25.072 9.957V8.028a.322.322 0 00-.321-.321H9.322a.322.322 0 00-.321.321v1.929c0 .177.145.321.321.321h15.429a.322.322 0 00.321-.321zm-15.75 3.536a.322.322 0 00-.321.321v1.929c0 .176.145.321.321.321h7.393a.322.322 0 00.322-.321v-1.929a.322.322 0 00-.322-.321H9.322zm15.108 4.66a8.678 8.678 0 00-8.68 8.679 8.678 8.678 0 008.68 8.678 8.678 8.678 0 008.678-8.678 8.678 8.678 0 00-8.678-8.679zm4.319 12.998a6.07 6.07 0 01-4.32 1.788 6.07 6.07 0 01-4.319-1.788 6.07 6.07 0 01-1.788-4.319 6.07 6.07 0 011.788-4.32 6.069 6.069 0 014.32-1.787c1.63 0 3.166.635 4.319 1.788a6.07 6.07 0 011.788 4.319 6.07 6.07 0 01-1.788 4.32zm-.744-7.373h-1.78c-.104 0-.2.049-.26.133l-2.552 3.528-.928-1.282a.32.32 0 00-.261-.133h-1.772a.323.323 0 00-.261.51l2.965 4.103a.32.32 0 00.518 0l4.589-6.348a.32.32 0 00-.258-.51zm-12.897 7.875H5.787V3.368h22.5v13.821c0 .177.144.322.321.322h2.25a.322.322 0 00.322-.322V1.761c0-.712-.575-1.286-1.286-1.286H4.18c-.712 0-1.286.574-1.286 1.286v31.5c0 .71.574 1.285 1.286 1.285h10.928a.322.322 0 00.322-.321v-2.25a.322.322 0 00-.322-.322z" />
    </svg>
  )
}

export default SvgAttendance
