import './ReportGenerate.scss'

import React, { useEffect, useState } from 'react'
import { isNil } from 'lodash'
import cx from 'classnames'
import 'chartjs-plugin-datalabels'

import { Report } from '../types/model'
import { requestApi } from '../utils/api'
import { getGender, cutDecimal, formatDate, getDate, getScore, getEvaluatedAge, 
  getScoreDate, getCardioScore, getWalkingScore, getJointScore, getStrengthScore, 
  getEvaluateCommonAge, getEvaluateCardiodAge, getEvaluateWalkingdAge, 
  getEvaluateJointdAge, getEvaluateStrengthdAge    } from '../utils/misc'

import useRouter from '../hooks/useRouter'

import BasicLayout from '../templates/BasicLayout'
import PercentageBar from '../components/PercentageBar'
import LineGraph from '../components/LineGraph'
import RadarGraph from '../components/RadarGraph'
import GroupBarGraph from '../components/GroupBarGraph'
import StackedBarGraph from '../components/StackedBarGraph'
import TotalScore from '../components/TotalScore'
import Score from '../components/Score'
import DoughnutGraph from './DoughnutGraph'
import querystring from 'querystring'

export default function ReportGenerate() {
  const {
    location: { search },
    match: {
      params: { id: reportId },
    },
  } = useRouter<{ id: string }>()
  const [reportData, setReportData] = useState<Report | null>(null)
  const [requestError, setRequestError] = useState<string | null>(null)

  const [recentReport, setRecentReport] = useState<any[]>([])
  const [recentReportError, setRecentReportError] = useState<boolean | null>(null)

  const token = querystring.parse(search, '?').authorization

  useEffect(() => {
    requestApi({ url: `/reports/${reportId}`, header: { authorization: token } }).then(res => {
      console.log('data graph2333333',res?.data);
      console.log('statu33333',res?.status)
      if (res?.status === 200) {
        setReportData(res?.data)
      } else {
        setRequestError('리포트를 정보를 찾을 수 없습니다. url의 리포트 id가 올바른지 확인해주세요.')
      }
    })

  }, [reportId])

  useEffect(() => {
    requestApi({ url: `/reports/user/${reportId}/graphcapture`, header: { authorization: token } }).then(res => {
      console.log('data graph22222222',res.data);
      console.log('status2222222',res.status)
      if (res.status === 200) {
        setRecentReport(res?.data)
      } else {
        setRecentReportError(true)
      }
     
    })
  }, [reportId])

  if (!isNil(requestError) || isNil(reportData) || !isNil(recentReportError) || isNil(recentReport)) {
    return (
      <BasicLayout className="ReportGenerate" headerColor="white" headerTitle="리포트 생성">
        <div className="ReportGenerate">
          {requestError ?? '리포트를 정보를 찾을 수 없습니다. url의 리포트 id가 올바른지 확인해주세요.'}
        </div>
      </BasicLayout>
    )
  }

  return (
    <div className="ReportGenerate">
      <div id="report1">
        <div className="Common">
          <span>{reportData.common.userNumber}</span>
          <span>{reportData.common.name}</span>
          <span>{reportData.common.height}cm</span>
          <span>{Number(reportData.cardio.bmi.weight).toFixed(1)}kg</span>
          <span>{reportData.common.age}세</span>
          <span>{getGender(reportData.common.gender)}</span>
          <span>{formatDate(reportData.created, `YYYY/M/D h:mm A`)}</span>
        </div>
        <div className="Cardio">
          <DoughnutGraph
            className="Cardio__age"
            kind="cardio"
            data={{
              evaluation: reportData.cardio.evaluation,
              evaluatedAge: reportData.cardio.evaluatedAge,
              score: reportData.cardio.score,
              maxScore: reportData.cardio.maxScore,
            }}
          />
          <PercentageBar
            className="Cardio__heartRate"
            point={reportData.cardio.heartRate.stable}
            score={reportData.cardio.heartRate.score}
            maxScore={reportData.cardio.heartRate.maxScore}
            labels={[reportData.cardio.heartRate.stable, reportData.cardio.heartRate.max]}
          />
          <PercentageBar
            className="Cardio__bloodPressure"
            point={reportData.cardio.bloodPressure.min + reportData.cardio.bloodPressure.max}
            score={reportData.cardio.bloodPressure.score}
            cuttingScore={1}
            maxScore={reportData.cardio.bloodPressure.maxScore}
            labels={[reportData.cardio.bloodPressure.max, reportData.cardio.bloodPressure.min]}
          />
          <PercentageBar
            className="Cardio__bmi"
            point={reportData.cardio.bmi.point}
            cuttingPoint={1}
            score={reportData.cardio.bmi.score}
            cuttingScore={2}
            maxScore={reportData.cardio.bmi.maxScore}
          />
          <PercentageBar
            className="Cardio__endurance"
            point={reportData.cardio.endurance.point}
            score={reportData.cardio.endurance.score}
            cuttingScore={1}
            maxScore={reportData.cardio.endurance.maxScore}
          />
          <LineGraph
            className="Cardio__scoreStatics"
            labels={getScoreDate(recentReport)}
            data={getEvaluateCardiodAge(recentReport)}
            min={100}
            max={20}
            stepSize={5}
          />
        </div>
        <div className="Walk">
          <PercentageBar
            className="Walk__velocity"
            point={reportData.walking.velocity.point}
            cuttingPoint={2}
            score={reportData.walking.velocity.score}
            cuttingScore={2}
            maxScore={reportData.walking.velocity.maxScore}
          />
          <PercentageBar
            className="Walk__balance"
            point={reportData.walking.balance.point}
            cuttingPoint={2}
            score={reportData.walking.balance.score}
            cuttingScore={2}
            maxScore={reportData.walking.balance.maxScore}
            labels={[reportData.walking.balance.left, reportData.walking.balance.right]}
          />
          <PercentageBar
            className="Walk__shock"
            point={reportData.walking.shock.point}
            cuttingPoint={1}
            score={reportData.walking.shock.score}
            maxScore={reportData.walking.shock.maxScore}
            labels={[reportData.walking.shock.left, reportData.walking.shock.right]}
            cuttingLabel={1}
          />
          <PercentageBar
            className="Walk__ankleStability"
            point={reportData.walking.ankleStability.point}
            score={reportData.walking.ankleStability.score}
            cuttingScore={1}
            maxScore={reportData.walking.ankleStability.maxScore}
            labels={[reportData.walking.ankleStability.left, reportData.walking.ankleStability.right]}
          />
          <PercentageBar
            className="Walk__typeLeft"
            point={reportData.walking.typeLeft.point}
            cuttingPoint={1}
            score={reportData.walking.typeLeft.score}
            cuttingScore={2}
            maxScore={reportData.walking.typeLeft.maxScore}
          />
          <PercentageBar
            className="Walk__typeRight"
            point={reportData.walking.typeRight.point}
            cuttingPoint={1}
            score={reportData.walking.typeRight.score}
            cuttingScore={2}
            maxScore={reportData.walking.typeRight.maxScore}
          />
          <PercentageBar
            className="Walk__imbalance"
            point={reportData.walking.imbalance.point}
            cuttingPoint={1}
            score={reportData.walking.imbalance.score}
            cuttingScore={2}
            maxScore={reportData.walking.imbalance.maxScore}
          />
          <DoughnutGraph
            className="Walk__age"
            kind="walk"
            data={{
              evaluation: reportData.walking.evaluation,
              evaluatedAge: reportData.walking.evaluatedAge,
              score: reportData.walking.score,
              maxScore: reportData.walking.maxScore,
            }}
          />
          <LineGraph
            className="Walk__scoreStatics"
            labels={getScoreDate(recentReport)}
            data={getEvaluateWalkingdAge(recentReport)}
            min={100}
            max={20}
          />
        </div>
      </div>
      <div id="report2">
        <div className="Joint">
          <DoughnutGraph
            className="Joint__age"
            kind="joint"
            data={{
              evaluation: reportData.joint.evaluation,
              evaluatedAge: reportData.joint.evaluatedAge,
              score: reportData.joint.score,
              maxScore: reportData.joint.maxScore,
            }}
          />
          <div className="Joint__score">
            <p className="leftLabel">좌</p>
            <p className="rightLabel">우</p>
            <Score
              className="Joint__scoreNeck"
              evaluation={reportData.joint.neckSideFlexion.evaluation}
              spotSize="SMALL"
              data={[reportData.joint.neckSideFlexion.left, reportData.joint.neckSideFlexion.right]}
            />
            <Score
              className="Joint__scoreShoulder"
              evaluation={reportData.joint.shoulderInternalRotation.evaluation}
              spotSize="SMALL"
              data={[reportData.joint.shoulderInternalRotation.left, reportData.joint.shoulderInternalRotation.right]}
            />
            <Score
              className="Joint__scoreSpine"
              evaluation={reportData.joint.spineRotation.evaluation}
              spotSize="BIG"
              data={[reportData.joint.spineRotation.left, reportData.joint.spineRotation.right]}
            />
            <Score
              className="Joint__scoreHip"
              evaluation={reportData.joint.hipInternalRotation.evaluation}
              spotSize="SMALL"
              data={[reportData.joint.hipInternalRotation.left, reportData.joint.hipInternalRotation.right]}
            />
            <Score
              className="Joint__scoreKnee"
              evaluation={reportData.joint.kneeExtension.evaluation}
              spotSize="SMALL"
              data={[reportData.joint.kneeExtension.left, reportData.joint.kneeExtension.right]}
            />
            <Score
              className="Joint__scoreAnkle"
              evaluation={reportData.joint.ankleDorsiflexion.evaluation}
              spotSize="BIG"
              data={[reportData.joint.ankleDorsiflexion.left, reportData.joint.ankleDorsiflexion.right]}
            />
          </div>
          <RadarGraph
            className="Joint__radarGraph"
            labels={[
              'shoulderInternalRotation',
              'spineRotation',
              'hipInternalRotation',
              'kneeExtension',
              'ankleDorsiflexion',
              'neckSideFlexion',
            ]}
            data={[
              reportData.joint.shoulderInternalRotation.score,
              reportData.joint.spineRotation.score,
              reportData.joint.hipInternalRotation.score,
              reportData.joint.kneeExtension.score,
              reportData.joint.ankleDorsiflexion.score,
              reportData.joint.neckSideFlexion.score,
            ]}
          />
          <StackedBarGraph
            className="Joint__spine"
            labels={['좌', '우']}
            data={[reportData.joint.spineRotation.leftScore, reportData.joint.spineRotation.rightScore]}
          />
          <StackedBarGraph
            className="Joint__shoulder"
            labels={['좌', '우']}
            data={[
              reportData.joint.shoulderInternalRotation.leftScore,
              reportData.joint.shoulderInternalRotation.rightScore,
            ]}
          />
          <StackedBarGraph
            className="Joint__neck"
            labels={['좌', '우']}
            data={[reportData.joint.neckSideFlexion.leftScore, reportData.joint.neckSideFlexion.rightScore]}
          />
          <StackedBarGraph
            className="Joint__ankle"
            labels={['좌', '우']}
            data={[reportData.joint.ankleDorsiflexion.leftScore, reportData.joint.ankleDorsiflexion.rightScore]}
          />
          <StackedBarGraph
            className="Joint__knee"
            labels={['좌', '우']}
            data={[reportData.joint.kneeExtension.leftScore, reportData.joint.kneeExtension.rightScore]}
          />
          <StackedBarGraph
            className="Joint__hip"
            labels={['좌', '우']}
            data={[reportData.joint.hipInternalRotation.leftScore, reportData.joint.hipInternalRotation.rightScore]}
          />
          <LineGraph
            className="Joint__scoreStatics"
            labels={getScoreDate(recentReport)}
            data={getEvaluateJointdAge(recentReport)}
            min={100}
            max={20}
          />
        </div>
        <div className="Strength">
          <DoughnutGraph
            className="Strength__age"
            kind="strength"
            data={{
              evaluation: reportData.strength.evaluation,
              evaluatedAge: reportData.strength.evaluatedAge,
              score: reportData.strength.score,
              maxScore: reportData.strength.maxScore,
            }}
          />
          <GroupBarGraph
            className="Strength__healthDevelopment"
            labels={getScoreDate(recentReport)}
            averageAgeData={getEvaluateCommonAge(recentReport)}
            // data={[
            //   { label: 'cardio', data: getEvaluateCardiodAge(recentReport) },
            //   { label: 'walk', data: getEvaluateWalkingdAge(recentReport) },
            //   { label: 'joint', data: getEvaluateJointdAge(recentReport) },
            //   { label: 'strength', data: getEvaluateStrengthdAge(recentReport) },
            // ]}
          />
          <TotalScore
            className="Strength__totalScore"
            labels={['심폐', '보행', '관절', '근력']}
            data={[
              reportData.cardio.evaluatedAge,
              reportData.walking.evaluatedAge,
              reportData.joint.evaluatedAge,
              reportData.strength.evaluatedAge,
            ]}
          />
          <LineGraph
            className="Strength__scoreStatics"
            labels={getScoreDate(recentReport)}
            data={getEvaluateStrengthdAge(recentReport)}
            min={100}
            max={20}
          />
          <PercentageBar
            className="Strength__grip"
            point={reportData.strength?.grip?.point ?? 0}
            cuttingPoint={1}
            score={reportData.strength?.grip?.score ?? 0}
            cuttingScore={1}
            maxScore={reportData.strength?.grip?.maxScore ?? 0}
          />
          <PercentageBar
            className="Strength__squatJump"
            point={reportData.strength.squatJump.point}
            cuttingPoint={1}
            score={reportData.strength.squatJump.score}
            cuttingScore={1}
            maxScore={reportData.strength.squatJump.maxScore}
          />
          <div className="Strength__commonTotalScore">
            <div className="score">
              <p>건강점수 총합</p>
              <p>
                {`${cutDecimal(reportData.common.score)}점`}
                <span>{` / ${reportData.common.maxScore}점`}</span>
              </p>
            </div>
            <div
              className={cx('evaluation', {
                'evaluation--small': reportData.common.evaluation === 'GOOD' || reportData.common.evaluation === 'BAD',
              })}
            >
              {reportData.common.evaluation === 'GOOD' && '평균\n이상'}
              {reportData.common.evaluation === 'NORMAL' && '평균'}
              {reportData.common.evaluation === 'BAD' && '평균\n이하'}
            </div>
          </div>
          <div className="Strength__evaluatedAge">{`${cutDecimal(reportData.common.evaluatedAge, 1)}세`}</div>
        </div>
      </div>
    </div>
  )
}
