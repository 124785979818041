import * as React from 'react'

function SvgXCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="currentColor">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8538 14.4483L20.1668 18.3901L19.058 19.5555L14.7716 15.638C13.3481 16.9091 11.4929 17.612 9.56935 17.6092C5.29745 17.6092 1.8335 14.2145 1.8335 10.0266C1.8335 5.83908 5.29745 2.44443 9.56935 2.44443C13.8412 2.44443 17.3052 5.83908 17.3052 10.0266C17.3052 11.6768 16.7672 13.204 15.8538 14.4483ZM15.878 10.0708C15.878 13.4564 13.1204 16.2108 9.64675 16.2108C6.17505 16.2108 3.35019 13.4564 3.35019 10.0708C3.35019 6.68656 6.17554 3.93119 9.64772 3.93119C13.1204 3.93119 15.879 6.68656 15.879 10.0708H15.878Z"
      />
    </svg>
  )
}

export default SvgXCircle
