import './CircleGraph.scss'
import React from 'react'
import cx from 'classnames'

interface Props {
  className?: string
  percent: number
}

const CircleGraph = ({ className, percent }: Props) => {
  return (
    <div className="CircleGraph">
      <svg viewBox="0 0 36 36">
        <path className="background"
          strokeDasharray="100, 100"
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path className="line"
          strokeDasharray={`${percent} 100`}
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
      </svg>
      <p className="text">{percent}<span>%</span></p>
    </div>
  )
}

export default CircleGraph
