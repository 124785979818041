import * as React from 'react'

function SvgJointSpotSmall(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" width="100%" height="100%" fill="currentColor" {...props}>
      <path
        className="jointSpotSmall_svg__st0"
        d="M16 26c-5.6 0-10.1-4.5-10.1-10.1S10.4 5.8 16 5.8s10.1 4.5 10.1 10.1S21.6 26 16 26zm0-16c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6z"
      />
      <path
        className="jointSpotSmall_svg__st0"
        d="M16 30.4c-8 0-14.5-6.5-14.5-14.5S8 1.3 16 1.3s14.5 6.5 14.5 14.5c0 8.1-6.5 14.6-14.5 14.6zm0-25.9C9.7 4.5 4.6 9.6 4.6 15.9S9.7 27.3 16 27.3s11.4-5.1 11.4-11.4S22.3 4.5 16 4.5z"
      />
      <path
        className="jointSpotSmall_svg__st0"
        d="M16 32C7.2 32 0 24.8 0 16S7.2 0 16 0s16 7.2 16 16-7.2 16-16 16zm0-31C7.7 1 1 7.7 1 16s6.7 15 15 15 15-6.7 15-15S24.3 1 16 1z"
      />
      <path
        className="jointSpotSmall_svg__st0"
        d="M16 26.6c-5.9 0-10.7-4.8-10.7-10.7S10.1 5.3 16 5.3 26.7 10.1 26.7 16 21.9 26.6 16 26.6zm0-20.3c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.6 9.6 9.6 9.6-4.3 9.6-9.6-4.3-9.6-9.6-9.6z"
      />
    </svg>
  )
}

export default SvgJointSpotSmall
