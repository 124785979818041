import * as React from 'react'

function SvgSnsInstagram(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="currentColor" viewBox="0 0 22 22" {...props}>
      <path d="M11 5.96a5.029 5.029 0 00-5.037 5.036A5.029 5.029 0 0011 16.032a5.029 5.029 0 005.036-5.036A5.029 5.029 0 0011 5.96zm0 8.31a3.28 3.28 0 01-3.274-3.274A3.28 3.28 0 0111 7.723a3.28 3.28 0 013.273 3.273A3.28 3.28 0 0111 14.27zm5.242-9.69a1.176 1.176 0 100 2.353 1.173 1.173 0 001.087-1.627 1.175 1.175 0 00-1.087-.725zm4.574 6.416c0-1.355.012-2.698-.064-4.05-.076-1.572-.434-2.967-1.584-4.116-1.151-1.152-2.543-1.508-4.115-1.584-1.355-.076-2.698-.064-4.051-.064-1.356 0-2.699-.012-4.052.064-1.57.076-2.966.435-4.115 1.584-1.151 1.15-1.507 2.544-1.583 4.115-.077 1.355-.064 2.699-.064 4.051 0 1.353-.012 2.699.064 4.052.076 1.571.434 2.966 1.583 4.115 1.152 1.151 2.544 1.508 4.116 1.584 1.355.076 2.698.064 4.05.064 1.356 0 2.7.012 4.052-.064 1.572-.076 2.966-.435 4.115-1.584 1.152-1.152 1.508-2.544 1.584-4.115.079-1.353.064-2.696.064-4.052zm-2.16 5.79a3.12 3.12 0 01-.742 1.125 3.14 3.14 0 01-1.125.741c-1.291.513-4.358.398-5.79.398-1.431 0-4.5.115-5.792-.395a3.12 3.12 0 01-1.124-.742 3.141 3.141 0 01-.742-1.124c-.51-1.294-.395-4.361-.395-5.793 0-1.431-.116-4.5.395-5.792.18-.447.395-.78.742-1.124a3.174 3.174 0 011.124-.742c1.292-.51 4.36-.395 5.792-.395 1.432 0 4.501-.116 5.793.395.446.18.78.395 1.124.742.346.346.562.677.742 1.124.51 1.292.395 4.361.395 5.792 0 1.432.115 4.499-.398 5.79z" />
    </svg>
  )
}

export default SvgSnsInstagram
