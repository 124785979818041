import { find } from "lodash"

export const  getLabel = (list: { label: string, value: string }[], value: string) => find(list, a => a.value === value)?.label

export const LEVEL_LIST = [
  { label: '비기너', value: "BEGINNER" },
  { label: '숙련자', value: "EXPERT" },
  { label: '프로', value: "MASTER" },
]

export const PERSONAL_SOLUTION_SEND_HOUR_LIST = [
  { label: "정오", value: "12" },
  { label: "오후 1시", value: "13" },
  { label: "오후 2시", value: "14" },
  { label: "오후 3시", value: "15" },
  { label: "오후 4시", value: "16" },
  { label: "오후 5시", value: "17" },
  { label: "오후 6시", value: "18" },
  { label: "오후 7시", value: "19" },
  { label: "오후 8시", value: "20" },
  { label: "오후 9시", value: "21" },
  { label: "오후 10시", value: "22" },
  { label: "오후 11시", value: "23" },
  { label: "자정", value: "00" },
  { label: "오전 1시", value: "01" },
  { label: "오전 2시", value: "02" },
  { label: "오전 3시", value: "03" },
  { label: "오전 4시", value: "04" },
  { label: "오전 5시", value: "05" },
  { label: "오전 6시", value: "06" },
  { label: "오전 7시", value: "07" },
  { label: "오전 8시", value: "08" },
  { label: "오전 9시", value: "09" },
  { label: "오전 10시", value: "10" },
  { label: "오전 11시", value: "11" },
]


export const PERSONAL_SOLUTION_SEND_MINUTE_LIST = [
  { label: "00분", value: "00" },
  { label: "10분", value: "10" },
  { label: "20분", value: "20" },
  { label: "30분", value: "30" },
  { label: "40분", value: "40" },
  { label: "50분", value: "50" },
  
]

export const PERSONAL_MAX_DURATION_MINUTE_LIST = [
  { label: "15분 미만", value: '900' },
  { label: "30분 미만", value: '1800' },
  { label: "60분 미만", value: '3600' },
  { label: "모든 영상", value: '9999997' },
 
]

export const PLAN_LIST = [
  { label: 'Move 10', value: "MOVE10" },
  { label: 'Move 20', value: "MOVE20" },
  { label: 'Move 30', value: "MOVE30" },
  { label: '체험', value: "FREE" },
]

export const PAIN_PART_LIST = [
  { label: '목', value: 'NECK' },
  { label: '어깨', value: 'SHOULDER' },
  { label: '손목', value: 'WRIST' },
  { label: '척추', value: 'SPINE' },
  { label: '골반', value: 'PELVIS' },
  { label: '무릎', value: 'KNEE' },
  { label: '발목', value: 'ANKLE' },
]

export const DATE_LIST = [
  { label: '일', value: 'SUN' },
  { label: '월', value: 'MON' },
  { label: '화', value: 'TUE' },
  { label: '수', value: 'WED' },
  { label: '목', value: 'THU' },
  { label: '금', value: 'FRI' },
  { label: '토', value: 'SAT' },
]

export const CATEGORY_LIST = [
  { value: 'CARDIO', label: '심폐운동' },
  { value: 'STRENGTH_TOP', label: '상체근력' },
  { value: 'STRINGTH_BOTTOM', label: '하체근력' },
  { value: 'FLEX_TOP', label: '상지유연성' },
  { value: 'FLEX_BOTTOM', label: '하지유연성' },
]
