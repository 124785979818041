import './ReportPage.scss'
import './MemberHome.scss'

import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { isNil, isEmpty, assign } from 'lodash'
import querystring from 'querystring'
import CopyToClipboard from 'react-copy-to-clipboard'

import { requestApi } from '../utils/api'
import { isBefore } from '../utils/misc'
import useRouter from '../hooks/useRouter'
import { Report } from '../types/model'

import BasicLayout from '../templates/BasicLayout'
import Loading from '../components/Loading'
import MenuButton from '../components/MenuButton'
import LinkButton from '../components/LinkButton'
import Button from '../components/Button'
import Icon from '../components/Icon'
import Tab from '../components/Tab'
import DiseasePrediction from '../components/DiseasePrediction'
import Alert from '../components/Alert'

import KakaoLink from '../images/kakao-link.png'
import PdfLink from '../images/pdf-link.png'
import ImgLink from '../images/image-link.png'

type SoutionState = 'fail' | 'success' | 'process' | 'past'

const SOLUTION_SERVICE_START_DATE = '2021-08-24T10:45:00'

export default function ReportPage() {
  const {
    history,
    location: { search },
    match: {
      params: { id: reportId },
    },
  } = useRouter<{ id: string }>()
  const [reportData, setReportData] = useState<Report | null>(null)
  const [requestError, setRequestError] = useState<string | null>(null)
  const [selectedTab, setSelectedTab] = useState('intact')
  const [isSolution, setIsSolution] = useState<SoutionState | null>(null)

  const goMemberHome = () => history.push(reportData?.common?.userId ? `/member/${reportData?.common?.userId}` : '/')

  const requestReport = (retry: boolean = false) => {
    requestApi({
      method: retry ? 'post' : 'get',
      url: `/reports/${reportId}${retry ? `/screenshot?retry=1` : ''}`,
      isAuthenticated: true,
    }).then(res => {
      if (res?.status === 200) {
        if (isEmpty(res?.data.reportImages?.pdf) || isEmpty(res?.data.reportImages?.images)) {
          // NOTE: report에 pdf, image가 생성되어있지 않을경우 retry(POST) 요청한다.
          requestReport(true)
        } else {
          setReportData(res?.data)
        }
      } else {
        setRequestError('리포트를 정보를 찾을 수 없습니다. url의 리포트 id가 올바른지 확인해주세요.')
      }
    })
  }

  const requestSolution = () => {
    setIsSolution('process')
    requestApi({
      method: 'post',
      url: `/reports/${reportId}/solution`,
      isAuthenticated: true,
    }).then(res => {
      if (res?.status === 200 && !res?.data.error) {
        const newReportData = assign(reportData, { solutionId: res.data._id })
        setReportData(newReportData)
        setIsSolution('success')
      } else {
        setIsSolution('fail')
      }
    })
  }

  useEffect(() => {
    const isNew = querystring.parse(search, '?').isNew
    if (isNew === '1') {
      requestReport(true)
    } else {
      requestReport()
    }
  }, [reportId])

  useEffect(() => {
    if (!isNil(reportData)) {
      const isSolutionService = !isBefore(SOLUTION_SERVICE_START_DATE, reportData.created)
      console.log('isSolutionService', isSolutionService)
      if (!isSolutionService) {
        return setIsSolution('past')
      }

      if (reportData?.solutionId) {
        setIsSolution('success')
      } else {
        setIsSolution('process')
        requestSolution()
      }
    }
  }, [reportData])

  const deleteReport = () => {
    if (window.confirm('평가를 삭제하시겠습니까? \n삭제 시 평가표 데이터 및 공유 URL 링크가 삭제됩니다.')) {
      requestApi({
        method: 'delete',
        url: `/reports/${reportId}`,
        isAuthenticated: true,
      }).then(res => {
        if (res?.status === 200) {
          history.push(`/member/${reportData?.common?.userId}`)
        } else {
          setRequestError('리포트를 정보를 찾을 수 없습니다. url의 리포트 id가 올바른지 확인해주세요.')
        }
      })
    }
  }

  const sendKakao = () => {
    if (window.confirm('회원님에게 인택트 결과 메시지를 전송하시겠습니까? \n카카오톡 또는 문자로 전송됩니다.')) {
      requestApi({
        method: 'post',
        url: `/reports/${reportId}/alimtalk`,
        isAuthenticated: true,
      }).then(res => {
        if (res?.status === 200) {
          window.alert('회원님의 카카오톡으로 PDF URL 주소가 전송되었습니다.')
        } else {
          window.alert('전송을 실패하였습니다. 다시 시도해주세요.')
        }
      })
    }
  }

  const sendKakaoExampleSolutionPage = () => {
    if (window.confirm('회원님에게 인택트 펄스널 솔루션 메시지를 전송하시겠습니까? \n카카오톡 또는 문자로 전송됩니다.')) {
      requestApi({
        method: 'post',
        url: `/solutions/${reportData?.common.userId}/alimtalk`,
        isAuthenticated: true,
      }).then(res => {
          if (res?.status === 200) {
          // window.alert('회원님의 카카오톡으로 펄스널 솔루션 URL 주소가 전송되었습니다.')
        } else {
          window.alert('전송을 실패하였습니다. 다시 시도해주세요.')
        }
      })
    }
  }

  const sendDiseasePrediction = () => {
    if (window.confirm('회원님에게 질환예측 결과 메시지를 전송하시겠습니까? \n카카오톡 또는 문자로 전송됩니다.')) {
      requestApi({
        method: 'post',
        url: `/reports/${reportId}/disease-prediction/alimtalk`,
        isAuthenticated: true,
      }).then(res => {
        if (res?.status === 200) {
          window.alert('회원님의 카카오톡으로 질환예측 결과가 전송되었습니다.')
        } else {
          window.alert('전송을 실패하였습니다. 다시 시도해주세요.')
        }
      })
    }
  }

  if (!isNil(requestError)) {
    return (
      <BasicLayout
        className="ReportPage"
        headerColor="white"
        headerTitle="회원님 건강평가표"
        headerLeftEl={<button onClick={goMemberHome}>닫기</button>}
      >
        <div className="ReportPage">{requestError}</div>
      </BasicLayout>
    )
  }

  return (
    <BasicLayout
      className="ReportPage"
      headerColor="white"
      headerTitle={`${reportData?.common?.name ?? '회원'}님 건강 평가표`}
      headerLeftEl={<button onClick={goMemberHome}>닫기</button>}
      headerRightEl={
        <MenuButton
          disabled={isNil(reportData)}
          list={[
            <Button
              title="데이터 수정"
              onClick={() => history.push(`/health-check/${reportData?.common?.userId}/${reportData?._id}`)}
              theme="ghostBlack"
            />,
            <Button title="삭제하기" theme="ghostRed" onClick={deleteReport} />,
          ]}
        />
      }
    >
      <Tab
        item={[
          { label: '인택트 결과지', value: 'intact' },
          { label: '질환예측', value: 'disease-prediction', disabled: isNil(isSolution) || isSolution === 'past' },
        ]}
        selectedTab={selectedTab}
        onSelect={tab => setSelectedTab(tab)}
      >
        {selectedTab === 'disease-prediction' && (
          <>
            <header>
              <h2>인택트 질환 예측 공유</h2>
              <div className="ReportPage__linkCopyBox ReportPage__linkCopyBox--disease">
                <button className="ReportPage__linkCopyBtn" onClick={sendDiseasePrediction}>
                  <img src={KakaoLink} />
                  카카오톡
                </button>
                <CopyToClipboard
                  text={`${window.location.origin}/disease-prediction/${reportId}`}
                  onCopy={() => window.alert('URL 주소를 복사했습니다.')}
                >
                  <button className="ReportPage__linkCopyBtn">
                    <Icon name="Link" />
                    URL 복사
                  </button>
                </CopyToClipboard>
              </div>
            </header>
            <DiseasePrediction reportId={reportId} />
          </>
        )}
        {selectedTab === 'intact' && (
          <>
            <header>
              <h2>인택트 결과 공유</h2>
              <div className="ReportPage__linkCopyBox">
                <button
                  className="ReportPage__linkCopyBtn"
                  disabled={!reportData?.reportImages?.pdf}
                  onClick={sendKakao}
                >
                  <img src={KakaoLink} />
                  카카오톡
                </button>
                <LinkButton
                  className="ReportPage__linkCopyBtn"
                  theme="iconOnly"
                  to={reportData?.reportImages?.pdf ?? ''}
                  newTab
                  external
                  disabled={!reportData?.reportImages?.pdf}
                >
                  <img src={PdfLink} />
                  전체 결과지 PDF
                </LinkButton>
                <LinkButton
                  className="ReportPage__linkCopyBtn"
                  theme="iconOnly"
                  to={reportData?.reportImages?.images[0] ?? ''}
                  disabled={!reportData?.reportImages?.images[0]}
                  newTab
                  external
                >
                  <img src={ImgLink} />
                  결과지 1p
                </LinkButton>
                <LinkButton
                  className="ReportPage__linkCopyBtn"
                  theme="iconOnly"
                  to={reportData?.reportImages?.images[1]! ?? ''}
                  disabled={!reportData?.reportImages?.images[1]}
                  newTab
                  external
                >
                  <img src={ImgLink} />
                  결과지 2p
                </LinkButton>
              </div>
              <div className="ReportPage__retry">
                {isNil(reportData?.strength?.grip) ? (
                  <Button disabled fullWidth>
                    평가표 개선으로 데이터 수정이 필요합니다.
                  </Button>
                ) : (
                  <Button
                    theme="secondary"
                    onClick={() => {
                      setReportData(null)
                      requestReport(true)
                    }}
                    fullWidth
                  >
                    <Icon name="Report" />
                    리포트 다시 생성 하기
                  </Button>
                )}
              </div>
              {isNil(isSolution) && (
                <Alert theme="default" isLoading>
                  리포트 생성후 퍼스널 솔루션을 생성합니다.
                </Alert>
              )}
              {isSolution === 'process' && (
                <Alert theme="default" isLoading>
                  퍼스널 솔루션을 생성중입니다.
                </Alert>
              )}
              {isSolution === 'success' && reportData?.solutionId && (
                <Alert theme="success">
                  퍼스널 솔루션이 생성되었습니다.&nbsp;&nbsp;&nbsp;
                  <div
                  onClick={ sendKakaoExampleSolutionPage }>
                  <LinkButton
                    to={`/solutions/${reportData.solutionId}`}
                    title="Open"
                    size="small"
                    theme="aqua"
                    newTab
                  />
                  </div>
                </Alert>
              )}
              {isSolution === 'fail' && (
                <Alert theme="error">
                  퍼스널 솔루션 생성을 실패하였습니다.&nbsp;&nbsp;&nbsp;
                  <Button title="Retry" size="small" theme="error" onClick={requestSolution} />
                </Alert>
              )}
            </header>
            <div className="ReportPage__inner">
              <div className={cx('LoadingScreen', { 'LoadingScreen--finish': !isNil(reportData) })}>
                <div className="LoadingScreen__content">
                  <Loading />
                  <p>리포트를 생성중입니다.</p>
                </div>
              </div>
              {reportData?.reportImages?.images[0] && (
                <img
                  className="ReportPage__reportImg"
                  src={reportData?.reportImages?.images[0]}
                  alt={`${reportData?.common?.name}-report01`}
                />
              )}
              {reportData?.reportImages?.images[1] && (
                <img
                  className="ReportPage__reportImg"
                  src={reportData?.reportImages?.images[1]}
                  alt={`${reportData?.common?.name}-report01`}
                />
              )}
            </div>
          </>
        )}
      </Tab>
    </BasicLayout>
  )
}
