import './DoughnutGraph.scss'
import React from 'react'
import cx from 'classnames'
import { Doughnut } from 'react-chartjs-2'

import { graphColor } from '../constants/color'
import { cutDecimal } from '../utils/misc'

import DoughnutCardio from '../images/doughnutCardio.png'
import DoughnutWalk from '../images/doughnutWalk.png'
import DoughnutJoint from '../images/doughnutJoint.png'
import DoughnutStrength from '../images/doughnutStrength.png'

export const EVALUATION = {
  GOOD: '좋음',
  NORMAL: '보통',
  BAD: '나쁨',
}

interface Props {
  className?: string
  kind?: 'cardio' | 'walk' | 'joint' | 'strength'
  data: {
    evaluatedAge: number
    score: number
    maxScore: number
    evaluation: 'GOOD' | 'NORMAL' | 'BAD'
  }
}

const DoughnutGraph = ({ className, kind = 'cardio', data }: Props) => {
  const { evaluatedAge = 0, score = 0, maxScore = 0, evaluation = 'GOOD' } = data
  return (
    <div className={cx('DoughnutGraph__wrapper', className)}>
      <div className={cx('DoughnutGraph')}>
        <div
          className={cx('DoughnutGraph__age', {
            'DoughnutGraph__age--good': evaluation === 'GOOD',
            'DoughnutGraph__age--normal': evaluation === 'NORMAL',
            'DoughnutGraph__age--bad': evaluation === 'BAD',
          })}
        >{`${cutDecimal(evaluatedAge, 1)}세`}</div>
        {kind === 'cardio' && <img alt="" className="DoughnutGraph__img" src={DoughnutCardio} />}
        {kind === 'walk' && <img alt="" className="DoughnutGraph__img" src={DoughnutWalk} />}
        {kind === 'joint' && <img alt="" className="DoughnutGraph__img" src={DoughnutJoint} />}
        {kind === 'strength' && <img alt="" className="DoughnutGraph__img" src={DoughnutStrength} />}
        <Doughnut
          key={kind}
          data={{
            datasets: [
              {
                data: [score, maxScore - score],
                backgroundColor: [graphColor[evaluation], 'white'],
                borderColor: 'transparent',
                datalabels: { display: false },
              },
            ],
          }}
          width={100}
          height={100}
          options={{ maintainAspectRatio: true, animation: { duration: 0 } }}
        />
      </div>
      <div
        className={cx('DoughnutGraph__evaluation', {
          'DoughnutGraph__evaluation--good': evaluation === 'GOOD',
          'DoughnutGraph__evaluation--normal': evaluation === 'NORMAL',
          'DoughnutGraph__evaluation--bad': evaluation === 'BAD',
        })}
      >
        {cutDecimal(score)}
      </div>
    </div>
  )
}

export default DoughnutGraph
