import './ExpectAge.scss'

import React, { ReactText } from 'react'
import { formatDate } from '../utils/misc'
import LinkButton from '../components/LinkButton'

interface Props {
  name: string
  age: ReactText
  created: string
  lifeExpectancy: {
    min: number
    max: number
  }
  reportId?: string
} 

export default function ExpectAge({ name, age, created, lifeExpectancy, reportId }: Props) {
  const expectAge = `${lifeExpectancy.min}~${lifeExpectancy?.max}세`

  return (
    <section className="ExpectAge">
      <div className="detail">
        <div>현재나이<br /><span>{age}세</span></div>
        <div>기대수명<br /><span>{expectAge}</span></div>
      </div>
      <div className="about">
        <strong>About</strong>
        {formatDate(created)} 인택트 측정으로 산출된 질환 노출 가능성을 약 500만명의 건강 데이터를 통해 알고리즘화한 결과, {name}님의 기대 수명은 {expectAge}로 예측됐습니다.
      </div>
      {reportId && <LinkButton to={`/disease-prediction/${reportId}`} title="인택트 질환 예측 상세보기" newTab />}
    </section>
  )
}
