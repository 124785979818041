import React from 'react'
import { Switch, Route } from 'react-router-dom'

import AuthPage from './pages/AuthPage'
import ReportPage from './pages/ReportPage'
import HealthCheckPage from './pages/HealthCheckPage'
import AttendancePage from './pages/AttendancePage'
import Login from './pages/Login'
import Home from './pages/Home'
import EditCenter from './pages/EditCenter'
import AddMember from './pages/AddMember'
import MemberHome from './pages/MemberHome'
import Error from './pages/Error'
import ReportGenerate from './pages/ReportGenerate'
import SearchPage from './pages/SearchPage'
import AttendanceConfirmPage from './pages/AttendanceConfirmPage'
import DiseasePredictionPage from './pages/DiseasePredictionPage'
import EndDiseasePredictionPage from './pages/EndDiseasePredictionPage'
import SolutionPage from './pages/SolutionPage'
import AllAttendancePage from './pages/AllAttendancePage'

import ScrollToTop from './components/ScrollToTop'

import './App.scss'
import ModifySolutionTime from './pages/ModifySolutionTime'

export default function App() {
  return (
    <div className="App">
      <ScrollToTop>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/report-generate/:id" component={ReportGenerate} />
          <Route path="/attendance/token/:id" component={AttendanceConfirmPage} />
          <Route path="/disease-prediction/:id?" component={DiseasePredictionPage} />
          <Route path="/end-disease-prediction/:id?" component={EndDiseasePredictionPage} />
          <Route path="/solutions/:id?/:progress?" component={SolutionPage} />
          <Route path="/user/:id/modifytime" component={ModifySolutionTime} />

          <AuthPage>
            <Switch>
              <Route path="/search" component={SearchPage} />
              <Route path="/attendance/:id" component={AttendancePage} />
              <Route path="/member/:id" component={MemberHome} />
              <Route path="/edit-member/:id" component={AddMember} />
              <Route path="/add-member" component={AddMember} />
              <Route path="/edit-center" component={EditCenter} />
              <Route path="/health-check/:userId/:reportId?" component={HealthCheckPage} />
              <Route path="/reports/:id" component={ReportPage} />
              <Route path="/" component={Home} exact />
              <Route path="/all-attendances" component={AllAttendancePage} />
              <Route component={Error} />
            </Switch>
          </AuthPage>
        </Switch>
      </ScrollToTop>
    </div>
  )
}
