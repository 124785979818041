import React from 'react'
import classNames from 'classnames'

import * as Icons from './icons'

export type IconType = keyof typeof Icons
interface Props {
  name: IconType
  size?: number
  className?: string
  title?: string
}

// Temporary type: cra의  SVGProps 타입이 업데이트 되면 삭제해도 됩니다.
type ODCSvg = React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title: string }>

const DEFAULT_SIZE = 24

export default function Icon({ name, className, size = DEFAULT_SIZE, title }: Props) {
  const SVG: ODCSvg = Icons[name]
  return (
    <SVG
      className={classNames('Icon', className)}
      width={size}
      height={size}
      aria-hidden={title ? undefined : true}
      title={title ?? name}
      role="img"
      data-testid="svg"
    />
  )
}
