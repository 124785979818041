import * as React from 'react'

function Info(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <g clipPath="url(#clip0)">
        <path d="M12 -0.00146484C6.20156 -0.00146484 1.5 4.7001 1.5 10.4985C1.5 16.297 6.20156 20.9985 12 20.9985C17.7984 20.9985 22.5 16.297 22.5 10.4985C22.5 4.7001 17.7984 -0.00146484 12 -0.00146484ZM12.75 15.561C12.75 15.6642 12.6656 15.7485 12.5625 15.7485H11.4375C11.3344 15.7485 11.25 15.6642 11.25 15.561V9.18604C11.25 9.08291 11.3344 8.99854 11.4375 8.99854H12.5625C12.6656 8.99854 12.75 9.08291 12.75 9.18604V15.561ZM12 7.49854C11.7056 7.49253 11.4253 7.37136 11.2192 7.16103C11.0132 6.95071 10.8977 6.66799 10.8977 6.37354C10.8977 6.07908 11.0132 5.79636 11.2192 5.58604C11.4253 5.37571 11.7056 5.25454 12 5.24854C12.2944 5.25454 12.5747 5.37571 12.7808 5.58604C12.9868 5.79636 13.1023 6.07908 13.1023 6.37354C13.1023 6.66799 12.9868 6.95071 12.7808 7.16103C12.5747 7.37136 12.2944 7.49253 12 7.49854Z" fill="#03ACCE" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Info
