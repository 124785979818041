import './SummaryGraph.scss'

import React from 'react'
import cx from 'classnames'
import { isEmpty, sum , floor} from 'lodash'
import { Line , Bar} from 'react-chartjs-2'
import 'chartjs-plugin-annotation'

import { cutDecimal } from '../utils/misc'
import { normalScore } from '../constants/score'

interface Props {
  className?: string
  label: 'cardio' | 'joint' | 'walking' | 'strength'
  labels: string[]
  data: number[]
  min?: number
  max?: number
  stepSize?: number
}

const AQUA = '#03ACCE'

const SummaryGraph = ({ className, label, labels, data, min, max, stepSize = 1 }: Props) => {
  let sample  = data.map(age => 100 - Number(cutDecimal(age, 1)))
  var lineData = {
    labels,
    datasets: [
      {
        type: 'line',
        label,
        data: sample,
        
        lineTension: 0,
        fill: true,
        borderColor: AQUA,
        backgroundColor: 'transparent',
        pointBorderColor: 'white',
        pointBackgroundColor: AQUA,
        pointRadius: 6,
        pointBorderWidth: 2,
        datalabels: { display: true },
        margin:{
          bottom:1
        }
      },
    ],
  }

  const title = {
    cardio: '심폐능력',
    joint: '관절 움직임',
    strength: '근력',
    walking: '보행',
  }

  const averageScore = cutDecimal(sum(data) / data?.length, 1)

  return (
    <div className={cx('SummaryGraph', className)}>
      <p className="SummaryGraph__averageScore">
        평균 {title[label]} 평가:
        {isEmpty(data) ? (
          ' 데이터 없음'
        ) : (
          <>
            <span style={{fontWeight:'bold'}}> {averageScore}</span>세
          </>
        )}
      </p>
      {/* <p className="SummaryGraph__normalScore">
        정상구간: {normalScoreMin} ~ {normalScoreMax}점
      </p> */}
      <Bar
        data={lineData}
        options={{
          plugins: {
            datalabels: {
              formatter: value => `${cutDecimal(100 - Number(value), 1)}세`,
              anchor: 'end',
              align: 'end',
              font: {
                weight: 'bold',
                size: 10,
              },
            },
          },
             // Core options
    aspectRatio: 5 / 3,
    layout: {
      padding: {
        top: 32,
        right: 16,
        bottom: 16,
        // left: 8
      }
    },
          legend: { display: false,
            labels: {
              boxWidth: 50,
              // boxHeight:50,
              fontColor: 'black',
              padding:10
            } },
          maintainAspectRatio: true,
          animation: { duration: 0 },
          
          // annotation: {
          //   annotations: [
          //     {
          //       drawTime: 'beforeDatasetsDraw',
          //       id: 'boxG',
          //       type: 'box',
          //       yScaleID: 'y-axis-0',
          //       xMin: 0,
          //       xMax: 0,
          //       yMin: normalScoreMin,
          //       yMax: normalScoreMax,
          //       backgroundColor: 'rgba(152, 70, 150, 0.05)',
          //       borderWidth: 0,
          //       borderColor: 'transparent',
          //     },
          //   ],
          // },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontSize: 12,
                  fontColor: '#999',
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                stacked: false,
                // scaleLabel: {
                //   display: true,
                //  // labelString: "나이 ( 세 )",         
                // },
                ticks: {
                  display:true,
                  fontSize: 12,
                  suggestedMin: min,
                  suggestedMax: max,
              
                  fontColor: '#999',
                  stepSize,
                  backdropColor: 'red',
                  callback: value => Math.abs(Number(value) - 100),
                },
              },
            ],
          },
          tooltips: {
            enabled: false
          },
          hover: {
            animationDuration: 0
          },
        }}
      />
    </div>
  )
}

export default SummaryGraph
