import './SearchPage.scss'

import React, { useEffect, useState } from 'react'
import { isEmpty, isNil, uniq, without } from 'lodash'

import { requestApi } from '../utils/api'
import useRouter from '../hooks/useRouter'

import Button from '../components/Button'
import SearchInput from '../components/SearchInput'
import { MemberListBox } from '../components/MemberList'
import RecentSearchWords from '../components/RecentSearchWords'

import { User } from '../types/model'

export default function SearchPage() {
  const { history } = useRouter()
  const [isLoading, setIsLoading] = useState(false)
  const [searchWord, setSearchWord] = useState('')
  const [userList, setUserList] = useState<User[] | null>(null)
  const [recentSearchWords, setRecentSearchWords] = useState<string[] | null>(null)

  useEffect(() => {
    getRecentSearchWords()
  }, [])

  const getRecentSearchWords = () => {
    const recentSearchWords = !isNil(localStorage.getItem('recentSearchWords'))
      ? JSON.parse(localStorage.getItem('recentSearchWords') ?? '')
      : []
    setRecentSearchWords(recentSearchWords)
  }

  const deleteRecentSearchWord = (word: string) => {
    const newRecentSearchWords: string[] = isNil(recentSearchWords) ? [] : without(recentSearchWords, word)
    localStorage.setItem('recentSearchWords', JSON.stringify(newRecentSearchWords))
    setRecentSearchWords(newRecentSearchWords)
  }

  const deleteAllRecentSearchWords = () => {
    const newRecentSearchWords: string[] = []
    localStorage.setItem('recentSearchWords', JSON.stringify(newRecentSearchWords))
    setRecentSearchWords(newRecentSearchWords)
  }

  const searchUser = (word: string) => {
    if (!isEmpty(word)) {
      const newRecentSearchWords: string[] = isNil(recentSearchWords) ? [word] : uniq([word, ...recentSearchWords])
      localStorage.setItem('recentSearchWords', JSON.stringify(newRecentSearchWords))
    }

    setIsLoading(true)

    try {
      requestApi({
        url: `/users?name=${word ?? ''}`,
        isAuthenticated: true,
      }).then(res => {
        if (res?.status === 200) {
          setUserList(res?.data?.data || [])
        }
        setIsLoading(false)
      })
    } catch (e) {
      console.log('request error', e)
      setIsLoading(false)
    }
  }

  return (
    <div className="SearchPage">
      <header className="SearchPage__header">
        <SearchInput
          className="SearchPage__input"
          label="회원 검색"
          value={searchWord}
          placeholder="회원명을 입력하세요."
          onChange={val => setSearchWord(val)}
          onSearch={() => searchUser(searchWord)}
          onDelete={() => {
            setUserList(null)
            getRecentSearchWords()
          }}
          isLoading={isLoading}
        />
        <Button
          className="SearchPage__searchButton"
          theme="ghostPurple"
          title="cancel"
          onClick={() => history.goBack()}
        />
      </header>
      <div className="SearchPage__main">
        <div className="SearchPage__title">
          <span>최근 검색어</span>
          <Button theme="ghostGray" title="전체 삭제" onClick={deleteAllRecentSearchWords} />
        </div>
        {isNil(userList) ? (
          <RecentSearchWords
            searchWords={recentSearchWords}
            onRemove={deleteRecentSearchWord}
            onSearch={val => {
              setSearchWord(val)
              searchUser(val)
            }}
          />
        ) : (
          <MemberListBox userData={userList} />
        )}
      </div>
    </div>
  )
}
