import * as React from 'react'

function SvgArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg className="Icon" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="15" cy="14.9993" rx="15" ry="14.9995" fill="white"/>
    <g clipPath="url(#clip0)">
    <path d="M21.1174 11.0107L17.2746 7.16786C17.1674 7.06071 17.0228 7 16.871 7H9.2853C8.96922 7 8.71387 7.25536 8.71387 7.57143V22.4286C8.71387 22.7446 8.96922 23 9.2853 23H20.7139C21.0299 23 21.2853 22.7446 21.2853 22.4286V11.4161C21.2853 11.2643 21.2246 11.1179 21.1174 11.0107ZM19.9674 11.6786H16.6067V8.31786L19.9674 11.6786ZM19.9996 21.7143H9.99958V8.28571H15.3924V12.1429C15.3924 12.3418 15.4715 12.5325 15.6121 12.6732C15.7528 12.8138 15.9435 12.8929 16.1424 12.8929H19.9996V21.7143ZM14.8567 16.8929H11.571C11.4924 16.8929 11.4282 16.9571 11.4282 17.0357V17.8929C11.4282 17.9714 11.4924 18.0357 11.571 18.0357H14.8567C14.9353 18.0357 14.9996 17.9714 14.9996 17.8929V17.0357C14.9996 16.9571 14.9353 16.8929 14.8567 16.8929ZM11.4282 14.6071V15.4643C11.4282 15.5429 11.4924 15.6071 11.571 15.6071H18.4282C18.5067 15.6071 18.571 15.5429 18.571 15.4643V14.6071C18.571 14.5286 18.5067 14.4643 18.4282 14.4643H11.571C11.4924 14.4643 11.4282 14.5286 11.4282 14.6071Z" fill="#03ACCE"/>
    </g>
    <defs>
    <clipPath id="clip0">
    <rect width="16" height="16" fill="white" transform="translate(7 7)"/>
    </clipPath>
    </defs>
    </svg>
  )
}

export default SvgArrow
