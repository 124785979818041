import './MenuButton.scss'

import React, { ReactNode, useRef, useState } from 'react'
import cx from 'classnames'
import useEventListener from '../hooks/useEventListener'

import Icon from './Icon'

const MenuButton = ({ list, disabled }: { list?: ReactNode[]; disabled?: boolean }) => {
  const [showMenu, setShowMenu] = useState(false)

  const $buttonRef = useRef<HTMLButtonElement>(null)
  const $listboxRef = useRef<HTMLUListElement>(null)

  useEventListener([
    {
      eventName: 'click',
      callback: e => {
        const $listboxEl = $listboxRef.current
        const $buttonEl = $buttonRef.current
        if (e.target !== $buttonEl && e.target !== $listboxEl) {
          setShowMenu(false)
        }
      },
    },
  ])

  return (
    <div className="MenuButton">
      <button className="MenuButton__menu" ref={$buttonRef} onClick={() => setShowMenu(!showMenu)} disabled={disabled}>
        <Icon name="Menu" title="메뉴" />
      </button>
      <ul className={cx('MenuButton__menuList', { 'MenuButton__menuList--hide': !showMenu })} ref={$listboxRef}>
        {list?.map((item, idx) => (
          <li className="MenuButton__menuItem" key={`menuItem${idx}`}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default MenuButton
