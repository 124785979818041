import * as React from 'react'

function Delete(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="currentColor" width="18" height="18" viewBox="0 0 22 22" {...props}>
      <path d="M7.2675 2.94425H7.07108C7.17911 2.94425 7.2675 2.85586 7.2675 2.74782V2.94425H14.7318V2.74782C14.7318 2.85586 14.8202 2.94425 14.9282 2.94425H14.7318V4.71211H16.4996V2.74782C16.4996 1.88108 15.795 1.17639 14.9282 1.17639H7.07108C6.20434 1.17639 5.49965 1.88108 5.49965 2.74782V4.71211H7.2675V2.94425ZM19.6425 4.71211H2.35679C1.92219 4.71211 1.57108 5.06322 1.57108 5.49782V6.28354C1.57108 6.39157 1.65947 6.47996 1.7675 6.47996H3.25054L3.85701 19.3215C3.8963 20.1588 4.58871 20.8193 5.42599 20.8193H16.5733C17.413 20.8193 18.103 20.1612 18.1423 19.3215L18.7488 6.47996H20.2318C20.3398 6.47996 20.4282 6.39157 20.4282 6.28354V5.49782C20.4282 5.06322 20.0771 4.71211 19.6425 4.71211ZM16.3842 19.0514H5.61505L5.02085 6.47996H16.9784L16.3842 19.0514Z" />
    </svg>
  )
}

export default Delete
