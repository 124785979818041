import * as React from 'react'

function SvgLoader(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        d="M15.76.601A2 2 0 0114.507 4.4c-.8-.264-1.643-.4-2.507-.4a8 8 0 108 8 2 2 0 114 0c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0c1.29 0 2.556.204 3.76.601z"
      />
    </svg>
  )
}

export default SvgLoader
