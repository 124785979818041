import React, { ReactNode, ChangeEvent, ReactText, forwardRef } from 'react'
import classNames from 'classnames'

import './Input.scss'

interface Props {
  className?: string
  label: string
  name?: string
  role?: string
  placeholder?: string
  type?: 'text' | 'email' | 'password' | 'number' | 'hidden' | 'date'
  value?: ReactText
  autoComplete?: string
  disabled?: boolean
  error?: boolean
  required?: boolean
  hideLabel?: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur?: () => void
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  min?: string | number
  max?: string | number
  stepSize?: number
  leftEl?: ReactNode
  rightEl?: ReactNode
  defaultValue?: ReactText
}

const Input = forwardRef(
  (
    {
      className,
      label,
      name,
      role,
      placeholder,
      type = 'text',
      value,
      autoComplete = 'on',
      disabled = false,
      error = false,
      required,
      hideLabel,
      onChange,
      onBlur,
      onKeyPress,
      min,
      max,
      stepSize = 1,
      leftEl,
      rightEl,
      defaultValue,
    }: Props,
    ref: React.Ref<HTMLInputElement>
  ) => {
    if (type === 'hidden') {
      return <input ref={ref} id={name} name={name ?? label} type={type} value={value} />
    }

    return (
      <div className={classNames('Input', className, { 'Input--error': error })}>
        <label
          className={classNames('Input__label', {
            'Input__label--hide': hideLabel,
          })}
          htmlFor={name}
        >
          {label}
        </label>
        {leftEl && <div className="Input__leftEl">{leftEl}</div>}
        <input
          defaultValue={defaultValue}
          className="Input__input"
          ref={ref}
          id={name ?? label}
          name={name ?? label}
          type={type}
          role={role}
          placeholder={placeholder}
          value={value}
          autoComplete={autoComplete}
          aria-invalid={error}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          required={required}
          min={min}
          max={max}
          step={stepSize}
        />
        {rightEl && <div className="Input__rightEl">{rightEl}</div>}
      </div>
    )
  }
)

export default Input
