import './Loading.scss'

import React from 'react'
import cx from 'classnames'
import Icon from './Icon'

interface Props {
  className?: string
  isVisible?: boolean
}

export default function Loading({ className, isVisible = true }: Props) {
  if (!isVisible) {
    return null
  }

  return (
    <div className={cx('Loading', className)}>
      <Icon className="Loading__indicator" name="Loader" />
    </div>
  )
}
