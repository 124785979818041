import './AttendancesListBox.scss'
import React, { useEffect, useState } from 'react'
import { isNil, isEmpty } from 'lodash'
import { Link } from 'react-router-dom'
import { Attendance } from '../types/model'
import { requestApi } from '../utils/api'
import { Option } from './Dropdown'

import Icon from './Icon'
import Dropdown from './Dropdown'
import Loading from './Loading'
import { formatDate } from '../utils/misc'
interface Props {
  trainerList: string[]
}

export const AttendanceListBox = ({ attendanceData }: { attendanceData: Attendance[] | null }) => {
  const isUser = !isNil(attendanceData) && !isEmpty(attendanceData)
  return (
    <ul className="MemberList__list">
      {isNil(attendanceData) && <Loading className="MemberList__loading" />}
      {!isNil(attendanceData) && isEmpty(attendanceData) && '등록된 회원이 없습니다.'}
      {isUser &&
        attendanceData?.map(
          ({
            _id: id,
            userId,
            name,
            created,
            status,
            centerName,
            pointName
          }) => {
            // const attendanceDateDiff = latestAttendanceDate ? getDateDiff(latestAttendanceDate, '출석') : ''
            // const reportDateDiff = latestReportDate ? getDateDiff(latestReportDate, '인택트') : ''
            // const personalSolutionDateDiff = latestPersonalSolutionSentDate
            //   ? getDateDiff(latestPersonalSolutionSentDate, '솔루션')
            //   : ''
            return (
              <li className="MemberList__member" key={id}>
                <Link to={`/attendance/${userId}`}>
                  <div>
                    <h3>
                      {name}  
                      <span style={{margin:10}}>
                           상태 :{status}
                      </span>
              
                      <span>
                        일시 :    {formatDate(created, `YYYY년 M월 D일 h:mm A`)}
                  
                      
                        {/* ({gender === 'female' ? '여' : '남'}, {`${getAge(birthday)}세`}
                        {trainer && `, ${trainer} 트레이너님`}) */}
                      </span>
                    </h3>
                    <p>
                      {/* {attendanceDateDiff}
                      {`${attendanceDateDiff && reportDateDiff && ' • '}${reportDateDiff}`}
                      {`${
                        (attendanceDateDiff || reportDateDiff) && personalSolutionDateDiff && ' • '
                      }${personalSolutionDateDiff}`} */}
                    </p>
                    {/* {!isEmpty(memo) && <p className="MemberList__memberMemo">{memo}</p>} */}
                  </div>
                  <Icon name="Arrow" />
                </Link>
              </li>
            )
          }
        )}
    </ul>
  )
}

type attendanceData = {
  count: number
  data: Attendance[]
}

export default function AttendanceList({ trainerList }: Props) {
  const DEFAULT_SORT = 'created'
  const DEFAULT_FILTER = '전체수업'

  const [sort, setSort] = useState(DEFAULT_SORT)
  const [filter, setFilter] = useState(DEFAULT_FILTER)
  const [attendanceData, setAttendanceData] = useState<attendanceData | null>(null)
  const [trainerOption, setTrainerOption] = useState<Option[]>([])
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    getAttendanceData(currentPage)
  }, [sort, currentPage])

  useEffect(() => {
    setCurrentPage(1)
    getAttendanceData(1)
  }, [filter])

  useEffect(() => {
    setTrainerOption(getTrainerOptions(trainerList))
  }, [trainerList])

  const getTrainerOptions = (trainerList: string[] | null) =>
    !isNil(trainerList)
      ? [{ label: DEFAULT_FILTER, value: DEFAULT_FILTER }].concat(
          trainerList.map(trainer => ({ label: `${trainer}님`, value: trainer }))
        )
      : []

  const getAttendanceData = (page: number) => {
    try {
      requestApi({
        url: `/attendances/monthcount?trainer=${
          filter === DEFAULT_FILTER ? '' : filter
        }`,
        isAuthenticated: true,
      }).then(res => {
        console.log("res",res)
        if (res?.status === 200) {
          setAttendanceData(res?.data)
        }
      })
    } catch (e) {
      console.log('request error', e)
    }
  }

  return (
    <div className="MemberList">
      <header className="MemberList__header">
        <h2>{`총 ${attendanceData?.count ?? 0}회 수업`}</h2>
        <div>
          <Dropdown
            className="MemberList__trainerFilter"
            label="트레이너 필터"
            hideLabel
            options={trainerOption}
            defaultOption={DEFAULT_FILTER}
            onSelect={val => {
              setCurrentPage(1)
              setFilter(val)
            }}
          />
        </div>
      </header>
      <AttendanceListBox attendanceData={attendanceData?.data ?? []} />

    </div>
  )
}
