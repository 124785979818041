import './MemberHome.scss'

import React, { useEffect, useState } from 'react'
import cx from 'classnames'

import { isNil, isEmpty } from 'lodash'
import useRouter from '../hooks/useRouter'
import { requestApi } from '../utils/api'
import { Report, User } from '../types/model'

import BasicLayout from '../templates/BasicLayout'
import LinkButton from '../components/LinkButton'
import UserInfo from '../components/UserInfo'
import ReportList from '../components/ReportList'
import ReportSummary from '../components/ReportSummary'
import Tab from '../components/Tab'

const MemberHome = () => {
  const {
    history,
    match: {
      params: { id: userId },
    },
  } = useRouter<{ id: string }>()
  const [memberInfo, setMemberInfo] = useState<User | null>(null)
  const [memberInfoError, setMemberInfoError] = useState<string | null>(null)
  const [attendance, setAttendance] = useState<number>(0)
  const [attendanceError, setAttendanceError] = useState<string | null>(null)
  const [reportData, setReportData] = useState<Report[] | null>(null)
  const [reportDataError, setReportDataError] = useState(false)
  const [recentReport, setRecentReport] = useState<Report[] | null>(null)
  const [recentReportError, setRecentReportError] = useState(false)
  const [selectedTab, setSelectedTab] = useState('summary')

  useEffect(() => {
    if (userId) {
      requestApi({ url: `/users/${userId}`, isAuthenticated: true }).then(res => {
        if (res?.status === 200) {
          setMemberInfo(res?.data)
        } else {
          setMemberInfoError('회원 정보를 조회하는 데 문제가 발생했습니다. 페이지를 새로고침 해주세요.')
        }
      })

      requestApi({ url: `attendances/user/${userId}`, isAuthenticated: true }).then(res => {
        if (res?.status === 200) {
          setAttendance(res?.data?.count)
        } else {
          setAttendanceError('출석 정보를 조회하는 데 문제가 발생했습니다. 페이지를 새로고침 해주세요.')
        }
      })

      requestApi({ url: `/reports/user/${userId}`, isAuthenticated: true }).then(res => {
        if (res.status === 200) {
          setReportData(res?.data)
        } else {
          setReportDataError(true)
        }
      })

      requestApi({ url: `/reports/user/${userId}/graph`, isAuthenticated: true }).then(res => {
        if (res.status === 200) {
          setRecentReport(res?.data)
        } else {
          setRecentReportError(true)
        }
      })
    }
  }, [userId])

  return (
    <BasicLayout
      className="MemberHome"
      headerTitle={`${memberInfo?.name ?? '회원'}님 건강 평가`}
      headerColor="black"
      headerLeftEl={<button onClick={() => history.push('/')}>이전</button>}
    >
      <UserInfo data={memberInfo} errorMessage={memberInfoError} />
      <ul className="MemberHome__loggedInfo">
        <li>
          <b className={cx({ empty: isNil(reportData) })}>
            {isNil(reportData) || isEmpty(reportData) ? '-' : `${reportData[0]?.common?.evaluatedAge}세`}
          </b>
          <span className={cx({ empty: isNil(reportData) })}>최근 건강 나이</span>
          {!isNil(reportData) && <LinkButton to={`/health-check/${userId}`} title="새로 평가하기" size="small" />}
        </li>
        <li>
          <b className={cx({ empty: isNil(attendance) })}>{attendance ?? '-'}</b>
          <span className={cx({ empty: isNil(attendance) })}>총 출석 수</span>
          {!isNil(reportData) && (
            <LinkButton to={`/attendance/${userId}`} title="출석 관리" size="small" theme="secondary" />
          )}
        </li>
      </ul>
      <div className="MemberHome__main">
        <Tab
          item={[
            { label: '요약', value: 'summary' },
            { label: '전체 건강 평가', value: 'list' },
          ]}
          selectedTab={selectedTab}
          onSelect={tab => setSelectedTab(tab)}
        >
          {selectedTab === 'summary' && <ReportSummary data={recentReport} error={recentReportError} />}
          {selectedTab === 'list' && <ReportList data={reportData} userId={userId} error={reportDataError} />}
        </Tab>
      </div>
    </BasicLayout>
  )
}

export default MemberHome
