import './HealthCheckPage.scss'

import React, { useEffect, useState, ReactText } from 'react'
import { useForm } from 'react-hook-form/dist/index.ie11'
import { isNil, set, has, omit, includes, isEmpty } from 'lodash'

import useRouter from '../hooks/useRouter'
import { EvaluatedHealthData, Report, User } from '../types/model'
import { requestApi } from '../utils/api'
import { formatDateKR, getAge, today } from '../utils/misc'
import { post_data } from '../utils/post_data'

import BasicLayout from '../templates/BasicLayout'
import Button from '../components/Button'
import Input from '../components/Input'
import UserInfo from '../components/UserInfo'
import InputGroup from '../components/InputGroup'
import Icon from '../components/Icon'
import Alert from '../components/Alert'

const HealthCheckPage = () => {
  const {
    history,
    match: {
      params: { userId, reportId },
    },
  } = useRouter<{ userId: string; reportId: string }>()
  const [reportData, setReportData] = useState<Report | null>(null)
  const [memberInfo, setMemberInfo] = useState<User | null>(null)
  const [memberInfoError, setMemberInfoError] = useState<string | null>(null)
  const [fieldError, setFieldError] = useState<{ field: string; message: string } | null>(null)
  const [saveError, setSaveError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const isEditPage = !isEmpty(reportId)

  const {
    register,
    handleSubmit,
    errors,
    setError,
    getValues,
    formState: { isValid, touched },
    clearErrors,
    setValue,
  } = useForm({
    mode: 'all',
    shouldFocusError: false,
    // defaultValues: post_data,
  })

  const isErrorField = (key: string, shouldTouched: boolean = true) => {
    if (!shouldTouched) {
      return has(errors, key)
    }
    return has(errors, key) && has(touched, key)
  }

  useEffect(() => {
    const url = isEditPage ? `/reports/${reportId}` : `/reports/user/${userId}/recent-analysis`

    requestApi({ url, isAuthenticated: true }).then(res => {
      if (res?.status === 200) {
        setReportData(res?.data)
      } else {
        setReportData(null)
      }
    })

    requestApi({ url: `/users/${userId}`, isAuthenticated: true }).then(res => {
      if (res?.status === 200) {
        setMemberInfo(res?.data)
        if (!!res?.data.error) {
          setMemberInfoError('회원 정보를 조회하는 데 문제가 발생했습니다. 페이지를 새로고침 해주세요.')
        }
      } else {
        setMemberInfoError('회원 정보를 조회하는 데 문제가 발생했습니다. 페이지를 새로고침 해주세요.')
      }
    })
  }, [userId, reportId])

  useEffect(() => {
    if (isEditPage && !isNil(reportData)) {
      setCardioData()
      setWalkingData()
      setJointData()
      setStrengthData()
    }
  }, [reportData])

  const isNumber = (val: ReactText) => !isNil(val) && !isNaN(Number(val))

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNumber(e.target.value)) {
      clearErrors()
    }
  }

  const onSubmit = async (data: EvaluatedHealthData) => {
    const walkingVelocityM: number = getValues('walking.velocity.minute')
    const walkingVelocityS: number = getValues('walking.velocity.second')
    const velocity = walkingVelocityM * 60 + walkingVelocityS

    set(data, 'cardio.bmi.height', memberInfo?.height)
    set(data, 'walking.velocity.point', velocity)

    const finalData = omit(data, ['walking.velocity.minute', 'walking.velocity.second'])

    const common = {
      userId: memberInfo?._id,
      userNumber: memberInfo?.userNumber,
      name: memberInfo?.name,
      height: memberInfo?.height,
      weight: memberInfo?.weight,
      age: getAge(memberInfo?.birthday ?? `${today.year()}`),
      gender: memberInfo?.gender,
    }

    const body = { common, ...finalData }

    try {
      setIsLoading(true)
      requestApi({
        method: isEditPage ? 'put' : 'post',
        url: isEditPage ? `/reports/scores/${reportId}` : '/reports',
        isAuthenticated: true,
        body,
      }).then(res => {
        setIsLoading(false)
        if (res?.status === 200) {
          history.push(`/reports/${res.data._id ?? reportId}?isNew=1`)
        } else {
          setFieldError({
            field: res?.data?.message?.field,
            message: '필드에 입력한 수치를 확인해주세요.',
          })
          setError(res?.data?.message?.field, { message: '필드에 입력한 수치를 확인해주세요.' })
          setSaveError('데이터 저장에 실패했습니다. 필드에 입력한 수치를 확인해주세요.')
        }
      })
    } catch (e) {
      setIsLoading(false)
      setSaveError('데이터 저장에 실패했습니다. 필드에 입력한 수치를 확인해주세요.')
      console.error('request error', e)
    }
  }

  const formConfig = {
    shouldValidate: true,
    shouldDirty: true,
  }

  const setCardioData = () => {
    setValue('cardio.bmi.weight', reportData?.cardio.bmi.weight, formConfig)
    setValue('cardio.heartRate.stable', reportData?.cardio.heartRate.stable, formConfig)
    setValue('cardio.bloodPressure.max', reportData?.cardio.bloodPressure.max, formConfig)
    setValue('cardio.bloodPressure.min', reportData?.cardio.bloodPressure.min, formConfig)
    setValue('cardio.endurance.point', reportData?.cardio.endurance.point, formConfig)
  }

  const setWalkingData = () => {
    const walkingVelocityM: number = Math.floor((reportData?.walking.velocity.time ?? 0) / 60)
    const walkingVelocityS: number = (reportData?.walking.velocity.time ?? 0) - walkingVelocityM * 60
    setValue('walking.velocity.minute', walkingVelocityM, formConfig)
    setValue('walking.velocity.second', walkingVelocityS, formConfig)

    setValue('walking.balance.left', reportData?.walking.balance.left, formConfig)
    setValue('walking.balance.right', reportData?.walking.balance.right, formConfig)
    setValue('walking.shock.left', reportData?.walking.shock.left, formConfig)
    setValue('walking.shock.right', reportData?.walking.shock.right, formConfig)
    setValue('walking.ankleStability.left', reportData?.walking.ankleStability.left, formConfig)
    setValue('walking.ankleStability.right', reportData?.walking.ankleStability.right, formConfig)
    setValue('walking.typeLeft.point', reportData?.walking.typeLeft.point, formConfig)
    setValue('walking.typeRight.point', reportData?.walking.typeRight.point, formConfig)
  }

  const setJointData = () => {
    setValue('joint.neckSideFlexion.left', reportData?.joint.neckSideFlexion.left, formConfig)
    setValue('joint.neckSideFlexion.right', reportData?.joint.neckSideFlexion.right, formConfig)
    setValue('joint.spineRotation.left', reportData?.joint.spineRotation.left, formConfig)
    setValue('joint.spineRotation.right', reportData?.joint.spineRotation.right, formConfig)
    setValue('joint.shoulderInternalRotation.left', reportData?.joint.shoulderInternalRotation.left, formConfig)
    setValue('joint.shoulderInternalRotation.right', reportData?.joint.shoulderInternalRotation.right, formConfig)
    setValue('joint.hipInternalRotation.left', reportData?.joint.hipInternalRotation.left, formConfig)
    setValue('joint.hipInternalRotation.right', reportData?.joint.hipInternalRotation.right, formConfig)
    setValue('joint.kneeExtension.left', reportData?.joint.kneeExtension.left, formConfig)
    setValue('joint.kneeExtension.right', reportData?.joint.kneeExtension.right, formConfig)
    setValue('joint.ankleDorsiflexion.left', reportData?.joint.ankleDorsiflexion.left, formConfig)
    setValue('joint.ankleDorsiflexion.right', reportData?.joint.ankleDorsiflexion.right, formConfig)
  }

  const setStrengthData = () => {
    setValue('strength.squatJump.point', reportData?.strength.squatJump.point, formConfig)
    setValue('strength.grip.point', reportData?.strength?.grip?.point, formConfig)
  }

  return (
    <BasicLayout
      className="HealthCheckPage"
      headerTitle={`${memberInfo?.name ?? '회원'}님 새로 평가하기`}
      headerLeftEl={
        <button
          onClick={() => {
            if (window.confirm(`${memberInfo?.name ?? '회원'}님 평가를 취소하시겠습니까?`)) {
              history.goBack()
            }
          }}
        >
          취소
        </button>
      }
    >
      <UserInfo data={memberInfo} errorMessage={memberInfoError} />
      <section>
        <p className="HealthCheckPage__date">{formatDateKR()}</p>
        <h2 className="HealthCheckPage__title">측정 데이터 입력</h2>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <fieldset className="HealthCheck">
            <header className="HealthCheck__header">
              <div className="HealthCheck__fieldInfo">
                <Icon name="MeasureHeart" />
                <div>
                  <legend className="HealthCheck__title">심폐 능력 평가</legend>
                  <p className="HealthCheck__desc">심폐 능력 측정 데이터를 입력하세요.</p>
                </div>
              </div>
              {includes(fieldError?.field, 'cardio') && (
                <p className="HealthCheck__errorMessage">
                  <Icon name="XCircle" />
                  {fieldError?.message}
                </p>
              )}
              {!isEditPage && !isNil(reportData) && !isEmpty(reportData) && (
                <Button theme="secondary" size="small" title="최근 평가 자동입력" onClick={setCardioData} />
              )}
            </header>
            <Input
              ref={register({ required: true, valueAsNumber: true, validate: isNumber })}
              label="체중"
              name="cardio.bmi.weight"
              placeholder="데이터 입력"
              error={!!isErrorField('cardio.bmi')}
              onChange={handleChange}
              stepSize={0.1}
            />
            <Input
              ref={register({ required: true, valueAsNumber: true, validate: isNumber })}
              label="안정시 심박수"
              name="cardio.heartRate.stable"
              placeholder="데이터 입력"
              error={!!isErrorField('cardio.heartRate')}
              onChange={handleChange}
            />
            <Input
              ref={register({ required: true, valueAsNumber: true, validate: isNumber })}
              label="혈압-수축기"
              name="cardio.bloodPressure.max"
              placeholder="데이터 입력"
              error={!!isErrorField('cardio.bloodPressure')}
              onChange={handleChange}
            />
            <Input
              ref={register({ required: true, valueAsNumber: true, validate: isNumber })}
              label="혈압-이완기"
              name="cardio.bloodPressure.min"
              placeholder="데이터 입력"
              error={!!isErrorField('cardio.bloodPressure')}
              onChange={handleChange}
            />
            <Input
              ref={register({ required: true, valueAsNumber: true, validate: isNumber })}
              label="심폐지구력"
              name="cardio.endurance.point"
              placeholder="데이터 입력"
              error={!!isErrorField('cardio.endurance')}
              onChange={handleChange}
            />
          </fieldset>
          <fieldset className="HealthCheck">
            <header className="HealthCheck__header">
              <div className="HealthCheck__fieldInfo">
                <Icon name="MeasureWalking" />
                <div>
                  <legend className="HealthCheck__title">보행 평가</legend>
                  <p className="HealthCheck__desc">보행 평가 측정 데이터를 입력하세요.</p>
                </div>
              </div>
              {includes(fieldError?.field, 'walking') && (
                <p className="HealthCheck__errorMessage">
                  <Icon name="XCircle" />
                  {fieldError?.message}
                </p>
              )}
              {!isEditPage && !isNil(reportData) && !isEmpty(reportData) && (
                <Button theme="secondary" size="small" title="최근 평가 자동입력" onClick={setWalkingData} />
              )}
            </header>
            <InputGroup label="보행 속도" error={!!isErrorField('walking.velocity')}>
              <Input
                ref={register({
                  required: true,
                  valueAsNumber: true,
                  validate: isNumber,
                })}
                label="분"
                name="walking.velocity.minute"
                placeholder="분"
                hideLabel
                error={!!isErrorField('walking.velocity')}
                onChange={handleChange}
              />
              /
              <Input
                ref={register({
                  required: true,
                  valueAsNumber: true,
                  validate: isNumber,
                })}
                label="초"
                name="walking.velocity.second"
                placeholder="초"
                hideLabel
                error={!!isErrorField('walking.velocity')}
                onChange={handleChange}
              />
            </InputGroup>
            <InputGroup label="보행 균형" error={!!isErrorField('walking.balance')}>
              <Input
                ref={register({ required: true, valueAsNumber: true, validate: isNumber })}
                label="좌"
                hideLabel
                name="walking.balance.left"
                placeholder="좌"
                error={!!isErrorField('walking.balance.left')}
                onChange={handleChange}
              />
              /
              <Input
                ref={register({ required: true, valueAsNumber: true, validate: isNumber })}
                label="우"
                hideLabel
                name="walking.balance.right"
                placeholder="우"
                error={!!isErrorField('walking.balance.right')}
                onChange={handleChange}
              />
            </InputGroup>
            <InputGroup label="보행 충격량" error={!!isErrorField('walking.shock')}>
              <Input
                ref={register({ required: true, valueAsNumber: true, validate: isNumber })}
                label="좌"
                hideLabel
                name="walking.shock.left"
                placeholder="좌"
                error={!!isErrorField('walking.shock.left')}
                onChange={handleChange}
                stepSize={0.1}
              />
              /
              <Input
                ref={register({ required: true, valueAsNumber: true, validate: isNumber })}
                label="우"
                hideLabel
                name="walking.shock.right"
                placeholder="우"
                error={!!isErrorField('walking.shock.right')}
                onChange={handleChange}
                stepSize={0.1}
              />
            </InputGroup>
            <InputGroup
              label="걸음걸이"
              error={
                !!isErrorField('walking.type', false) ||
                !!isErrorField('walking.imbalance', false) ||
                !!isErrorField('walking.typeLeft.point') ||
                !!isErrorField('walking.imbalance', false) ||
                !!isErrorField('walking.typeRight.point')
              }
            >
              <Input
                ref={register({ required: true, valueAsNumber: true, validate: isNumber })}
                label="좌"
                hideLabel
                name="walking.typeLeft.point"
                placeholder="좌"
                error={!!isErrorField('walking.typeLeft.point')}
                onChange={handleChange}
                stepSize={0.1}
              />
              /
              <Input
                ref={register({ required: true, valueAsNumber: true, validate: isNumber })}
                label="우"
                hideLabel
                name="walking.typeRight.point"
                placeholder="우"
                error={!!isErrorField('walking.typeRight.point')}
                onChange={handleChange}
                stepSize={0.1}
              />
            </InputGroup>
            <InputGroup label="발목 안정성" error={!!isErrorField('walking.ankleStability')}>
              <Input
                ref={register({ required: true, valueAsNumber: true, validate: isNumber })}
                label="좌"
                hideLabel
                name="walking.ankleStability.left"
                placeholder="좌"
                error={!!isErrorField('walking.ankleStability.left')}
                onChange={handleChange}
              />
              /
              <Input
                ref={register({ required: true, valueAsNumber: true, validate: isNumber })}
                label="우"
                hideLabel
                name="walking.ankleStability.right"
                placeholder="우"
                error={!!isErrorField('walking.ankleStability.right')}
                onChange={handleChange}
              />
            </InputGroup>
          </fieldset>
          <fieldset className="HealthCheck">
            <header className="HealthCheck__header">
              <div className="HealthCheck__fieldInfo">
                <Icon name="MeasureFlexibility" />
                <div>
                  <legend className="HealthCheck__title">움직임 평가</legend>
                  <p className="HealthCheck__desc">움직임 평가 측정 데이터를 입력하세요.</p>
                </div>
              </div>
              {includes(fieldError?.field, 'joint') && (
                <p className="HealthCheck__errorMessage">
                  <Icon name="XCircle" />
                  {fieldError?.message}
                </p>
              )}
              {!isEditPage && !isNil(reportData) && !isEmpty(reportData) && (
                <Button theme="secondary" size="small" title="최근 평가 자동입력" onClick={setJointData} />
              )}
            </header>
            <InputGroup label="목 가측굴곡" error={!!isErrorField('joint.neckSideFlexion')}>
              <Input
                ref={register({ required: true, valueAsNumber: true, validate: isNumber })}
                label="좌"
                hideLabel
                name="joint.neckSideFlexion.left"
                placeholder="좌"
                error={!!isErrorField('joint.neckSideFlexion.left')}
                onChange={handleChange}
              />
              /
              <Input
                ref={register({ required: true, valueAsNumber: true, validate: isNumber })}
                label="우"
                hideLabel
                name="joint.neckSideFlexion.right"
                placeholder="우"
                error={!!isErrorField('joint.neckSideFlexion.right')}
                onChange={handleChange}
              />
            </InputGroup>
            <InputGroup label="척추 회전" error={!!isErrorField('joint.spineRotation')}>
              <Input
                ref={register({ required: true, valueAsNumber: true, validate: isNumber })}
                label="좌"
                hideLabel
                name="joint.spineRotation.left"
                placeholder="좌"
                error={!!isErrorField('joint.spineRotation.left')}
                onChange={handleChange}
              />
              /
              <Input
                ref={register({ required: true, valueAsNumber: true, validate: isNumber })}
                label="우"
                hideLabel
                name="joint.spineRotation.right"
                placeholder="우"
                error={!!isErrorField('joint.spineRotation.right')}
                onChange={handleChange}
              />
            </InputGroup>
            <InputGroup label="골반 내회전" error={!!isErrorField('joint.hipInternalRotation')}>
              <Input
                ref={register({ required: true, valueAsNumber: true, validate: isNumber })}
                label="좌"
                hideLabel
                name="joint.hipInternalRotation.left"
                placeholder="좌"
                error={!!isErrorField('joint.hipInternalRotation.left')}
                onChange={handleChange}
              />
              /
              <Input
                ref={register({ required: true, valueAsNumber: true, validate: isNumber })}
                label="우"
                hideLabel
                name="joint.hipInternalRotation.right"
                placeholder="우"
                error={!!isErrorField('joint.hipInternalRotation.right')}
                onChange={handleChange}
              />
            </InputGroup>
            <InputGroup label="어깨 내회전" error={!!isErrorField('joint.shoulderInternalRotation')}>
              <Input
                ref={register({ required: true, valueAsNumber: true, validate: isNumber })}
                label="좌"
                hideLabel
                name="joint.shoulderInternalRotation.left"
                placeholder="좌"
                error={!!isErrorField('joint.shoulderInternalRotation.left')}
                onChange={handleChange}
              />
              /
              <Input
                ref={register({ required: true, valueAsNumber: true, validate: isNumber })}
                label="우"
                hideLabel
                name="joint.shoulderInternalRotation.right"
                placeholder="우"
                error={!!isErrorField('joint.shoulderInternalRotation.right')}
                onChange={handleChange}
              />
            </InputGroup>
            <InputGroup label="무릎 신전" error={!!isErrorField('joint.kneeExtension')}>
              <Input
                ref={register({ required: true, valueAsNumber: true, validate: isNumber })}
                label="좌"
                hideLabel
                name="joint.kneeExtension.left"
                placeholder="좌"
                error={!!isErrorField('joint.kneeExtension.left')}
                onChange={handleChange}
              />
              /
              <Input
                ref={register({ required: true, valueAsNumber: true, validate: isNumber })}
                label="우"
                hideLabel
                name="joint.kneeExtension.right"
                placeholder="우"
                error={!!isErrorField('joint.kneeExtension.right')}
                onChange={handleChange}
              />
            </InputGroup>
            <InputGroup label="발목 배측굴곡" error={!!isErrorField('joint.ankleDorsiflexion')}>
              <Input
                ref={register({ required: true, valueAsNumber: true, validate: isNumber })}
                label="좌"
                hideLabel
                name="joint.ankleDorsiflexion.left"
                placeholder="좌"
                error={!!isErrorField('joint.ankleDorsiflexion.left')}
                onChange={handleChange}
              />
              /
              <Input
                ref={register({ required: true, valueAsNumber: true, validate: isNumber })}
                label="우"
                hideLabel
                name="joint.ankleDorsiflexion.right"
                placeholder="우"
                error={!!isErrorField('joint.ankleDorsiflexion.right')}
                onChange={handleChange}
              />
            </InputGroup>
          </fieldset>
          <fieldset className="HealthCheck">
            <header className="HealthCheck__header">
              <div className="HealthCheck__fieldInfo">
                <Icon name="MeasureMuscle" />
                <div>
                  <legend className="HealthCheck__title">근력 평가</legend>
                  <p className="HealthCheck__desc">근력 평가 측정 데이터를 입력하세요.</p>
                </div>
              </div>
              {includes(fieldError?.field, 'strength') && (
                <p className="HealthCheck__errorMessage">
                  <Icon name="XCircle" />
                  {fieldError?.message}
                </p>
              )}
              {!isEditPage && !isNil(reportData) && !isEmpty(reportData) && (
                <Button theme="secondary" size="small" title="최근 평가 자동입력" onClick={setStrengthData} />
              )}
            </header>
            <Input
              ref={register({ required: true, valueAsNumber: true, validate: isNumber })}
              label="상지근력"
              name="strength.grip.point"
              placeholder="데이터 입력"
              error={!!isErrorField('strength.grip')}
              onChange={handleChange}
              stepSize={0.1}
            />
            <Input
              ref={register({ required: true, valueAsNumber: true, validate: isNumber })}
              label="하지근력"
              name="strength.squatJump.point"
              placeholder="데이터 입력"
              error={!!isErrorField('strength.squatJump')}
              onChange={handleChange}
              stepSize={0.1}
            />
          </fieldset>
          {saveError && <Alert text={saveError} theme="error" />}
          <Button type="submit" title="건강 평가 결과 보기" disabled={!isValid} fullWidth isLoading={isLoading} />
        </form>
      </section>
    </BasicLayout>
  )
}

export default HealthCheckPage
