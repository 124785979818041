import React from 'react'
import BasicLayout from '../templates/BasicLayout'
import LinkButton from '../components/LinkButton'

import './Error.scss'

export default function Error() {
  return (
    <BasicLayout className="Error" headerTitle="Error">
      <p className="Error__desc">페이지를 찾을 수 없습니다. url을 확인해주세요.</p>
      <LinkButton to="/" title="Go Home" />
    </BasicLayout>
  )
}
