import './AllAttendancePage.scss'
import React, { useEffect, useState } from 'react'
import { Attendance, Center, User } from '../types/model'
import useRouter from '../hooks/useRouter'
import { requestApi } from '../utils/api'
import BasicLayout from '../templates/BasicLayout'
import { logout } from '../utils/auth'
import AttendanceList, { AttendanceListBox } from '../components/AttendancesListBox'

export default function AllAttendancePage() {
const { history } = useRouter()
const [centerInfo, setCenterInfo] = useState<Center | any>()
const [error, setError] = useState<string | null>(null)
  useEffect(() => {
    
    const centerId = localStorage.getItem('centerId')
    try {
      requestApi({ url: `/centers/${centerId}`, isAuthenticated: true }).then(res => {
        res?.status === 200
          ? setCenterInfo(res?.data)
          : res?.status === 403
          ? logout(() => history.push('/login'))
          : setError('센터 정보를 불러오지 못했습니다. 다시 로그인해주세요.')
      })
    } catch (e) {
      setError('센터 정보를 불러오지 못했습니다. 다시 로그인해주세요.')
      console.log('request error', e)
    }
  }, [])

  const goBack = () => history.goBack()

  return (
    <div className="SearchPage">
     <BasicLayout
      className="AttendancePage"
      headerTitle={`이번달 수업 조회`}
      headerColor="black"
      headerLeftEl={<button onClick={goBack}>이전</button>}
    >  
    <div className="SearchPage__main">
        <AttendanceList trainerList={centerInfo?.trainerList} />
  </div>
  </BasicLayout>
  </div>
  )
}