import './Dropdown.scss'

import React, { useState, useRef, useEffect, memo } from 'react'
import classNames from 'classnames'
import { get, isNull, findIndex, isEmpty } from 'lodash'

import Icon, { IconType } from './Icon'
import useEventListener from '../hooks/useEventListener'
import useId from '../hooks/useId'
import { KeyCode } from '../constants/enums'

export const DISABLED_BUTTON_PLACEHOLDER = 'No option' // TODO: fallback placeholder 디자인 텍스트로 교체

const getOptionProperty = (options: Option[], idx: number, property: 'label' | 'value') =>
  get(options[idx], property, '')

export type Option = {
  label: string
  value: string | number
}

interface Props {
  label: string
  options: Option[]
  className?: string
  hideLabel?: boolean
  fullWidth?: boolean
  iconType?: IconType
  defaultOption?: number | string
  placeholder?: string
  error?: boolean
  errorMessage?: string
  testId?: string
  onSelect: (value: string) => void
  onClick?: () => void
  onBlur?: () => void
}

function Dropdown({
  label,
  options,
  className,
  hideLabel = false,
  fullWidth,
  iconType,
  defaultOption,
  placeholder,
  error,
  errorMessage,
  testId,
  onSelect,
  onClick,
  onBlur,
}: Props) {
  const dropdownId = useId('Dropdown')
  const defaultOptionIndex = findIndex(options, ['value', defaultOption])
  const initialOptionId = defaultOptionIndex >= 0 ? defaultOptionIndex : null
  const disabled = isNull(options) || isEmpty(options)

  const [isOpen, setIsOpen] = useState(false)
  const [selectedOptionId, setSelectedOptionId] = useState(initialOptionId)
  const [focusedOptionId, setFocusedOptionId] = useState(selectedOptionId || 0)

  const $buttonRef = useRef<HTMLButtonElement>(null)
  const $listboxRef = useRef<HTMLUListElement>(null)
  const $focusedListItemEl = $listboxRef.current?.children[focusedOptionId] || null

  // NOTE: When the dropdown opens, focus on the listbox
  useEffect(() => {
    if (isOpen) {
      $listboxRef.current?.focus()
    }
  }, [isOpen])

  // NOTE: Scroll to focused option
  useEffect(() => {
    $focusedListItemEl?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  }, [focusedOptionId])

  const firstOptionId = 0
  const lastOptionId = options.length - 1
  const toggle = (e: any) => {
    if (!!e.keyCode) {
      if (e.keyCode === KeyCode.ARROW_UP || e.keyCode === KeyCode.ARROW_DOWN || e.keyCode === KeyCode.ENTER) {
        e.preventDefault()
        setIsOpen(!isOpen)
        onClick?.()
      }
    } else {
      setIsOpen(!isOpen)
      onClick?.()
    }
  }
  const close = () => setIsOpen(false)
  const focusUp = () => {
    if (focusedOptionId !== firstOptionId) {
      setFocusedOptionId(focusedOptionId - 1)
    }
  }
  const focusDown = () => {
    if (focusedOptionId !== lastOptionId) {
      setFocusedOptionId(focusedOptionId + 1)
    }
  }

  const getButtonText = () => {
    if (disabled) {
      return DISABLED_BUTTON_PLACEHOLDER
    }

    if (isNull(selectedOptionId)) {
      return placeholder ?? getOptionProperty(options, firstOptionId, 'label')
    }

    return getOptionProperty(options, selectedOptionId, 'label')
  }

  const handleSelect = (id: number) => {
    $buttonRef.current?.focus()
    setSelectedOptionId(id)
    onSelect(`${getOptionProperty(options, id, 'value')}`)
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    switch (e.keyCode) {
      case KeyCode.ARROW_UP: {
        e.preventDefault()
        focusUp()
        break
      }
      case KeyCode.ARROW_DOWN: {
        e.preventDefault()
        focusDown()
        break
      }
      case KeyCode.ENTER:
      case KeyCode.SPACEBAR: {
        e.preventDefault()
        handleSelect(focusedOptionId)
        close()
        break
      }
      case KeyCode.TAB: {
        close()
        break
      }
      case KeyCode.ESC: {
        close()
        $buttonRef.current?.focus()
        break
      }
    }
  }

  // NOTE: Close the Dropdown when click outside
  useEventListener([
    {
      eventName: 'click',
      callback: e => {
        const $buttonEl = $buttonRef.current
        if (e.target !== $buttonEl) {
          close()
        }
      },
    },
  ])

  return (
    <div className={classNames('Dropdown', className, { fullWidth })} data-testid={testId}>
      <label
        className={classNames('Dropdown__label', {
          'screen-reader-text': hideLabel,
        })}
        htmlFor={`${dropdownId}__button`}
        id={`${dropdownId}__label`}
      >
        {label}
      </label>
      <button
        className={classNames('Dropdown__button', {
          'Dropdown__button--error': !!error && !isOpen,
          'Dropdown__button--isSelectedOption': !isNull(selectedOptionId),
        })}
        type="button"
        ref={$buttonRef}
        id={`${dropdownId}__button`}
        onClick={toggle}
        onKeyDown={e => toggle(e)}
        onBlur={onBlur}
        aria-labelledby={`${dropdownId}__label ${dropdownId}__button`}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        disabled={disabled}
      >
        {iconType && <Icon className="Dropdown__icon" name={iconType} />}
        <span>{getButtonText()}</span>
        <Icon name="Arrow" className={classNames('Dropdown__arrowIcon', { 'Dropdown__arrowIcon--up': isOpen })} />
      </button>
      {error && errorMessage && (
        <p className={classNames('Dropdown__errorMessage', { hidden: isOpen })}>{errorMessage}</p>
      )}
      <ul
        className={classNames('Dropdown__listBox', {
          withoutLabel: hideLabel,
          hidden: !isOpen,
        })}
        ref={$listboxRef}
        id={`${dropdownId}__dropdownList`}
        role="listbox"
        tabIndex={-1}
        onKeyDown={handleKeyDown}
        aria-labelledby={`${dropdownId}__label`}
        aria-activedescendant={`${dropdownId}__${getOptionProperty(options, focusedOptionId, 'label')}`}
      >
        {options.map(({ label }, idx) => {
          return (
            <li
              key={label}
              className={classNames('Dropdown__listItem', {
                'Dropdown__listItem--active': selectedOptionId === idx,
                'Dropdown__listItem--focused': focusedOptionId === idx,
              })}
              id={`${dropdownId}__${label}`}
              role="option"
              onClick={() => {
                handleSelect(idx)
                close()
              }}
              onMouseEnter={() => setFocusedOptionId(idx)}
              onMouseLeave={() => setFocusedOptionId(-1)}
              aria-selected={selectedOptionId === idx}
            >
              {label}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default memo(Dropdown)
