import React from 'react'
import classNames from 'classnames'
import './InfoItem.scss'

interface Props {
  className?: string
  label?: string
  value?: string | null | number
}

const InfoItem = ({ label, value, className }: Props) => (
  <p className={classNames('InfoItem', className)} key={`${label}${value}`}>
    <span className="InfoItem__label">{label}</span>
    {value}
  </p>
)

export default InfoItem
