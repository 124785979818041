import './Home.scss'

import React, { useState, useEffect } from 'react'
import { isNil } from 'lodash'

import { Center } from '../types/model'
import { requestApi } from '../utils/api'
import { logout } from '../utils/auth'
import useRouter from '../hooks/useRouter'

import BasicLayout from '../templates/BasicLayout'
import CenterInfo from '../components/CenterInfo'
import LinkButton from '../components/LinkButton'
import Alert from '../components/Alert'
import MemberList from '../components/MemberList'
import MenuButton from '../components/MenuButton'
import Icon from '../components/Icon'
import Button from '../components/Button'

export default function Home() {
  const { history } = useRouter()
  const [centerInfo, setCenterInfo] = useState<Center | any>()
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const centerId = localStorage.getItem('centerId')
    try {
      requestApi({ url: `/centers/${centerId}`, isAuthenticated: true }).then(res => {
        res?.status === 200
          ? setCenterInfo(res?.data)
          : res?.status === 403
          ? logout(() => history.push('/login'))
          : setError('센터 정보를 불러오지 못했습니다. 다시 로그인해주세요.')
      })
    } catch (e) {
      setError('센터 정보를 불러오지 못했습니다. 다시 로그인해주세요.')
      console.log('request error', e)
    }
  }, [])

  return (
    <BasicLayout
      className="Home"
      headerRightEl={
        <>
        <span style={{margin:10}}>
             <LinkButton  to="/all-attendances"  theme="ghostBlack" >
          <Icon name="Search" title="월 수업 횟수 조회" />
           수업 조회
        </LinkButton>
        </span>
        <span>
          <LinkButton to="/search" theme="ghostBlack">
            <Icon name="Search" title="회원 검색" />
            회원 검색
          </LinkButton>
          </span>
          <MenuButton
            list={[<Button title="로그아웃" onClick={() => logout(() => history.push('login'))} theme="ghostBlack" />]}
          />
        </>
      }
      headerColor="white"
    >
      <CenterInfo {...centerInfo} />
      <div className="Home__content">
        <LinkButton className="Home__addMemberButton" to="/add-member" fullWidth>
          <Icon name="AddMember" />
          회원 신규 등록
        </LinkButton>
        {!isNil(error) && <Alert className="Home__alert" text={error} theme="error" />}
        <MemberList trainerList={centerInfo?.trainerList} />
      </div>
    </BasicLayout>
  )
}
