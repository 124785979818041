import * as React from 'react'

function SvgSnsYoutube(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="currentColor" viewBox="0 0 22 22" {...props}>
      <g clipPath="url(#snsYoutube_svg__clip0)">
        <path d="M22 10.933c0-.054 0-.116-.003-.187-.002-.199-.007-.422-.012-.66a37.045 37.045 0 00-.108-2.011c-.073-.887-.181-1.626-.329-2.18a3.426 3.426 0 00-2.413-2.42c-.695-.186-2.056-.301-3.97-.373-.912-.034-1.886-.056-2.861-.068-.341-.005-.658-.008-.943-.01h-.722c-.285.002-.601.005-.943.01-.975.012-1.95.034-2.86.069-1.915.073-3.278.189-3.97.373A3.422 3.422 0 00.451 5.894c-.15.555-.256 1.294-.33 2.18a36.97 36.97 0 00-.107 2.011c-.008.239-.01.462-.013.661L0 10.933v.137c0 .054 0 .115.002.187l.013.66c.02.685.054 1.368.108 2.011.073.887.181 1.626.329 2.18a3.422 3.422 0 002.413 2.42c.693.186 2.056.301 3.97.372.912.035 1.886.057 2.861.069.341.005.658.007.943.01h.722c.285-.003.601-.005.943-.01.975-.012 1.95-.034 2.86-.069 1.915-.073 3.278-.189 3.97-.373a3.417 3.417 0 002.414-2.419c.15-.554.256-1.293.33-2.18.053-.643.088-1.326.107-2.01.008-.239.01-.462.012-.661 0-.072.003-.133.003-.187v-.137zm-1.768.127c0 .052 0 .108-.002.175l-.013.63a33.268 33.268 0 01-.103 1.913c-.066.791-.16 1.44-.275 1.874a1.652 1.652 0 01-1.164 1.166c-.515.138-1.814.248-3.58.314a111.52 111.52 0 01-3.744.076h-.702l-.928-.007c-.96-.012-1.92-.034-2.817-.069-1.765-.068-3.066-.176-3.58-.314a1.656 1.656 0 01-1.163-1.166c-.116-.435-.209-1.083-.275-1.874a35.567 35.567 0 01-.103-1.912c-.008-.229-.01-.442-.013-.631l-.002-.175v-.117c0-.052 0-.109.002-.175l.013-.63c.017-.654.051-1.307.103-1.914.066-.79.16-1.438.275-1.873a1.65 1.65 0 011.164-1.166c.515-.138 1.814-.248 3.58-.315a111.585 111.585 0 013.744-.076h.702l.928.008c.96.012 1.92.034 2.816.068 1.766.07 3.067.177 3.58.315a1.655 1.655 0 011.164 1.166c.116.435.209 1.083.275 1.873.052.607.084 1.26.103 1.913.008.228.01.442.013.631l.002.175v.117zM8.815 14.292l5.696-3.315L8.815 7.71v6.58z" />
      </g>
      <defs>
        <clipPath id="snsYoutube_svg__clip0">
          <path d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgSnsYoutube
