import './Modal.scss'

import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { isEmpty } from 'lodash'

import Icon from './Icon'
import Button from './Button'

export type ModalContent = {
  title: string
  description: string
}

interface Props {
  className?: string
  content: ModalContent | null
  isVisible?: boolean
  confirmMessage?: string
  onClose?: () => void
  onConfirm?: () => void
}

export default function Loading({ className, content, isVisible = false, confirmMessage = '확인', onClose, onConfirm }: Props) {
  const [show, setShow] = useState(isVisible)

  useEffect(() => {
    setShow(isVisible)
  }, [isVisible])

  if (!show) {
    return null
  }

  return (
    <div className="ModalWrapper">
      <div className={cx('Modal', className)}>
        <Icon name="Info" />
        <Button className="Modal__close" theme="iconOnly" onClick={() => {
          setShow(false)
          onClose?.()
        }}>
          <Icon name="Close" />
        </Button>
        <div className="inner">
          <h3>{content?.title}</h3>
          <p>{isEmpty(content?.description) ? '내용이 없습니다.' : content?.description}</p>
          {onConfirm && <Button onClick={onConfirm} fullWidth>{confirmMessage}</Button>}
        </div>
      </div>
    </div>
  )
}
