import './UserInfoTag.scss'

import React from 'react'
import { isEmpty } from 'lodash'
import {
  DATE_LIST,
  PERSONAL_SOLUTION_SEND_HOUR_LIST,
  PLAN_LIST,
  PAIN_PART_LIST,
  LEVEL_LIST,
  getLabel,
  PERSONAL_SOLUTION_SEND_MINUTE_LIST,
  //PERSONAL_MAX_DURATION_MINUTE_LIST,
} from '../constants/label'

import type { UserInfo } from '../types/model'

export default function UserInfoTag({ userInfo }: { userInfo: UserInfo }) {
  return (
    <section className="UserInfoTag">
      <h2>설정한 요일/시간에 회원님에게 꼭 필요한 맞춤형 운동 콘텐츠가 전달될 예정입니다.</h2>
      {!isEmpty(userInfo.personalSolutionSendDays) && (
        <div className="date">
          매주 {userInfo.personalSolutionSendDays.map((d: string) => getLabel(DATE_LIST, d)).join(', ')}요일
        </div>
      )}
      {!isEmpty(userInfo.personalSolutionSendHour) && (
        <div className="hour">{getLabel(PERSONAL_SOLUTION_SEND_HOUR_LIST, userInfo.personalSolutionSendHour)}
          {getLabel(PERSONAL_SOLUTION_SEND_MINUTE_LIST, userInfo.personalSolutionSendMinute)
        }</div>
      )}
      {/* {!isEmpty(userInfo.personalMaxDurationMinute) && (
        <div className="duration">{getLabel(PERSONAL_MAX_DURATION_MINUTE_LIST, userInfo.personalMaxDurationMinute.toString())}
        </div>
      )} */}
      {!isEmpty(userInfo.level) && <div className="level">난이도: {getLabel(LEVEL_LIST, userInfo.level)}</div>}
      {!isEmpty(userInfo.membershipType) && (
        <div className="plan">플랜: {getLabel(PLAN_LIST, userInfo.membershipType)}</div>
      )}
      {!isEmpty(userInfo.painPartList) &&
        userInfo.painPartList.map((part: string) => (
          <div key={part} className="painPart">
            {getLabel(PAIN_PART_LIST, part)} 통증
          </div>
        ))}
    </section>
  )
}
