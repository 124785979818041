import React from 'react'
import cx from 'classnames'
import { Radar } from 'react-chartjs-2'
import 'chartjs-plugin-datalabels'
import { graphColor } from '../constants/color'

import './RadarGraph.scss'

interface Props {
  className?: string
  labels: string[]
  data: number[]
}

const RadarGraph = ({ className, labels, data }: Props) => {
  const graphData = {
    labels,
    datasets: [
      {
        backgroundColor: 'rgba(135,113,177,0.7)',
        borderColor: graphColor.PURPLE,
        borderWidth: 0,
        data,
      },
    ],
  }

  return (
    <div className={cx('RadarGraph', className)}>
      <Radar
        data={graphData}
        options={{
          maintainAspectRatio: true,
          animation: { duration: 0 },
          plugins: {
            datalabels: {
              rotation: -30,
              color: '#fff',
              textStrokeColor: graphColor.PURPLE,
              textStrokeWidth: 4,
              align: 'start',
              offset: 5,
              font: {
                weight: 'bold',
                size: 30,
              },
            },
          },
          scale: {
            display: false,
            ticks: {
              suggestedMin: 0,
              suggestedMax: 50,
              stepSize: 10,
            },
          },
          legend: {
            display: false,
          },
        }}
        width={100}
        height={100}
      />
    </div>
  )
}

export default RadarGraph
