import './StackedBarGraph.scss'

import React from 'react'
import cx from 'classnames'
import { Bar } from 'react-chartjs-2'
import { graphColor } from '../constants/color'

interface Props {
  className?: string
  labels: string[]
  data: number[]
}

const StackedBarGraph = ({ className, labels, data }: Props) => {
  const calculatedData = [
    [data[0] - 15 > 0 ? 15 : data[0], data[1] - 15 > 0 ? 15 : data[1]],
    [data[0] - 20 > 0 ? 5 : Math.max(0, data[0] - 15), data[1] - 20 > 0 ? 5 : Math.max(0, data[1] - 15)],
    [data[0] - 20 > 0 ? data[0] - 20 : 0, data[1] - 20 > 0 ? data[1] - 20 : 0],
  ]

  const graphData = {
    labels: ['', ''],
    datasets: [
      {
        label: 'BAD',
        backgroundColor: graphColor.LIGHT_GRAY,
        barThickness: 50,
        data: calculatedData[0],
        datalabels: { display: false },
      },
      {
        label: 'NORMAL',
        backgroundColor: graphColor.LIGHT_PURPLE,
        barThickness: 50,
        data: calculatedData[1],
        datalabels: { display: false },
      },
      {
        label: 'GOOD',
        backgroundColor: graphColor.DARK_GRAY,
        barThickness: 50,
        data: calculatedData[2],
      },
    ],
  }

  return (
    <div className={cx('StackedBarGraph', className)}>
      <div className="StackedBarGraph__yAxis">
        <div>이상</div>
        <div>평균</div>
        <div>이하</div>
      </div>
      <div className="StackedBarGraph__xAxis">
        <div>좌</div>
        <div>우</div>
      </div>
      <div className="StackedBarGraph__graph">
        <Bar
          data={graphData}
          options={{
            plugins: {
              datalabels: {
                formatter: (value, context) => data[context.dataIndex],
                color: graphColor.MEDIUM_PURPLE,
                anchor: 'end',
                align: 'end',
                font: {
                  weight: 'bold',
                  size: 20,
                  family: 'Noto Sans KR',
                },
              },
            },
            layout: { padding: { top: 40 } },
            maintainAspectRatio: true,
            animation: { duration: 0 },
            title: {
              display: false,
              text: 'Total score',
            },
            legend: { display: false },
            scales: {
              xAxes: [
                {
                  stacked: true,
                  ticks: {
                    display: true,
                    fontStyle: 'bold',
                    fontSize: 35,
                    fontColor: graphColor.BLACK,
                    fontFamily: 'Noto Sans KR',
                  },
                  gridLines: { display: false },
                },
              ],
              yAxes: [
                {
                  // type: 'linear',
                  position: 'left',
                  stacked: true,
                  ticks: {
                    fontSize: 15,
                    fontColor: graphColor.BLACK,
                    beginAtZero: true,
                    min: 0,
                    max: 25,
                    stepSize: 5,
                    // callback: value => 120 - Number(value),
                    fontFamily: 'Noto Sans KR',
                  },
                  gridLines: {
                    lineWidth: 2,
                    color: '#C4C4C4',
                  },
                },
              ],
            },
          }}
          width={73}
          height={100}
        />
      </div>
    </div>
  )
}

export default StackedBarGraph
