import * as React from 'react'

function WarningCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="61" height="60" viewBox="0 0 61 60" fill="currentColor" {...props}>
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.5 60.001C47.0685 60.001 60.5 46.5695 60.5 30.001C60.5 13.4325 47.0685 0.00100708 30.5 0.00100708C13.9315 0.00100708 0.5 13.4325 0.5 30.001C0.5 46.5695 13.9315 60.001 30.5 60.001ZM30.25 44.001C31.2165 44.001 32 43.2175 32 42.251C32 41.2845 31.2165 40.501 30.25 40.501C29.2835 40.501 28.5 41.2845 28.5 42.251C28.5 43.2175 29.2835 44.001 30.25 44.001ZM30.25 12.501C30.9404 12.501 31.5 13.0607 31.5 13.751V36.251C31.5 36.9414 30.9404 37.501 30.25 37.501C29.5596 37.501 29 36.9414 29 36.251V13.751C29 13.0607 29.5596 12.501 30.25 12.501Z"
          fill="#FFC600"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="60" height="60" fill="white" transform="translate(0.5 0.00100708)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default WarningCircle
