import React, { ReactNode } from 'react'
import classNames from 'classnames'

import Header from '../components/Header'
import Footer from '../components/Footer'
import { HeaderColor } from '../components/Header'

import './BasicLayout.scss'

interface Props {
  className?: string
  children: ReactNode
  headerTitle?: string
  headerLeftEl?: React.ReactNode
  headerRightEl?: React.ReactNode | 'Home'
  headerColor?: HeaderColor
  isHeader?: boolean
}

export default function BasicLayout({
  className,
  children,
  headerTitle,
  headerLeftEl,
  headerRightEl,
  headerColor,
  isHeader = true
}: Props) {
  return (
    <div className={classNames('BasicLayout', className)}>
      {isHeader && <Header title={headerTitle} leftEl={headerLeftEl} rightEl={headerRightEl} color={headerColor} />}
      <div className={classNames('BasicLayout__main', { 'BasicLayout__main--noHeader': !isHeader })} role="main">
        {children}
      </div>
      <Footer />
    </div>
  )
}
