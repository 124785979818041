import './Login.scss'

import React, { useState } from 'react'
import { isEmpty } from 'lodash'

import { requestApi } from '../utils/api'
import { logout } from '../utils/auth'
import useRouter from '../hooks/useRouter'
import { HOMEPAGE } from '../constants/url'

import Input from '../components/Input'
import Button from '../components/Button'
import Alert from '../components/Alert'
import Footer from '../components/Footer'

import IntactLogo from '../images/intactLogo.svg'
import IntactBg01 from '../images/loginBg01.png'
import IntactBg02 from '../images/loginBg02.png'
import Icon from '../components/Icon'

const Login = () => {
  const [id, setId] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [loginError, setLoginError] = useState<string | null>(null)
  const { history } = useRouter()

  const handleLogin = () => {
    const loginInfo = { loginId: id, password }
    try {
      requestApi({ url: '/auth/login', method: 'post', body: loginInfo }).then(res => {
        if (res?.status === 200) {
          const { token, user } = res?.data
          localStorage.setItem('token', token)
          localStorage.setItem('centerId', user.granted)
          localStorage.setItem('loginId', user.loginId)
          history.push('/')
        } else {
          setLoginError('로그인에 실패했습니다. 아이디와 패스워드를 확인해주세요.')
          logout()
        }
      })
    } catch (e) {
      setLoginError('로그인에 실패했습니다. 아이디와 패스워드를 확인해주세요.')
      logout()
    }
  }

  return (
    <div className="Login">
      <header className="Login__header">
        <img alt="intact" src={IntactLogo} />
      </header>
      <div className="Login__main">
        <div className="Login__display">
          <img className="Login__displayBg" alt="" src={IntactBg01} />
          <div className="Login__displayContent">
            <h2>
              INTACT TEST <span>for 가맹점</span>
            </h2>
            <p>머스트무브짐 회원들의 건강평가를 관리하실 수 있습니다.</p>
          </div>
        </div>
        <form className="Login__form">
          {loginError && <Alert className="Login__alert" text={loginError} theme="error" />}
          <Input label="ID" value={id} placeholder="아이디를 입력하세요." onChange={e => setId(e.target.value)} />
          <Input
            type="password"
            className="Login__passwordInput"
            label="비밀번호"
            value={password}
            placeholder="비밀번호를 입력하세요."
            onChange={e => setPassword(e.target.value)}
          />
          <Button onClick={handleLogin} title="로그인" disabled={isEmpty(id) || isEmpty(password)} fullWidth />
        </form>
        <div className="Login__link">
          <a href={HOMEPAGE} target="_blank" rel="noreferrer">
            Intact 홈페이지 바로가기 <Icon name="Arrow" />
          </a>
        </div>
        <img className="Login__bg" alt="" src={IntactBg02} />
      </div>
      <Footer />
    </div>
  )
}

export default Login
