import * as React from 'react'

function SvgMeasureWalking(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" viewBox="0 0 48 48" {...props}>
      <g opacity={0.85} stroke="currentColor" strokeWidth={3}>
        <path d="M23.832 17.414l7.22 5.632 5.017-1.91" />
        <path d="M18.017 18.273l-4.011 1.908L12 24.954m14.039 5.728l4.01 3.818v8.592" strokeLinecap="square" />
        <ellipse cx={26.04} cy={7.773} rx={5.014} ry={4.773} strokeLinecap="square" />
        <path d="M17.014 45l8.045-32.546" />
      </g>
    </svg>
  )
}

export default SvgMeasureWalking
