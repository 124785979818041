import * as React from 'react'

function VCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="61" height="60" viewBox="0 0 61 60" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5 60.001C47.0687 60.001 60.5 46.5698 60.5 30.001C60.5 13.4323 47.0687 0.00100708 30.5 0.00100708C13.9312 0.00100708 0.5 13.4323 0.5 30.001C0.5 46.5698 13.9312 60.001 30.5 60.001ZM16.875 29.001L14.125 31.626L26.625 44.001L48.125 18.751L45.375 16.251L26.375 38.501L16.875 29.001Z"
        fill="#03ACCE"
      />
    </svg>
  )
}

export default VCircle
