import './ReportSummary.scss'

import React from 'react'
import { isNil } from 'lodash'

import { getScoreDate, getCardioScore, getWalkingScore, getJointScore, getStrengthScore, getEvaluateCardiodAge, getEvaluateJointdAge, getEvaluateWalkingdAge, getEvaluateStrengthdAge } from '../utils/misc'
import { maxScore } from '../constants/score'

import Loading from './Loading'
import SummaryGraph from './SummaryGraph'

import type { Report } from '../types/model'
interface Props {
  data: any[] | null
  error: boolean
}

const ReportSummary = ({ data, error }: Props) => {
  if (error) {
    return (
      <div className="ReportSummary">리포트 데이터를 조회하는 데 문제가 발생했습니다. 페이지를 새로고침 해주세요.</div>
    )
  }

  if (isNil(data)) {
    return (
      <div className="ReportSummary">
        <Loading className="ReportSummary__loading" />
      </div>
    )
  }

  // const {
  //   cardio: { scoreStatics: cardioStatics = [] } = {},
  //   strength: { scoreStatics: strengthStatics = [] } = {},
  //   walking: { scoreStatics: walkingStatics = [] } = {},
  //   joint: { scoreStatics: jointStatics = [] } = {},
  // } = data

//   data.map((data
//     // {cardio:{score:cardioScore}},{walking:{score:walkingScore}} ,{joint:{score:joinScore}}
//     )=>{
// console.log('cardioScore',data.cardio.score)
// console.log('walkingScore',data.walking.score)

  //})

  // console.log('data',data);
  // console.log('getEvaluateCardiodAge',getEvaluateCardiodAge(data));
  return (
    <div className="ReportSummary">
      <h3>최신 건강 추이</h3>
      <SummaryGraph
        label="cardio"
        labels={getScoreDate(data)}
        data={getEvaluateCardiodAge(data)}
        min={100}
        max={20}
        stepSize={5}
      />
      <SummaryGraph
        label="joint"
        labels={getScoreDate(data)}
        data={getEvaluateJointdAge(data)}
        min={100}
        max={20}
      />
      <SummaryGraph
        label="walking"
        labels={getScoreDate(data)}
        data={getEvaluateWalkingdAge(data)}
        min={100}
        max={20}
      />
      <SummaryGraph
        label="strength"
        labels={getScoreDate(data)}
        data={getEvaluateStrengthdAge(data)}
        min={100}
        max={20}
      />
    </div>
  )
}

export default ReportSummary
