import * as React from 'react'

function SvgSnsFacebook(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="currentColor" viewBox="0 0 22 22" {...props}>
      <path d="M20.036 1.176H1.965a.785.785 0 00-.786.786v18.071c0 .435.351.786.786.786h18.071a.785.785 0 00.786-.786V1.962a.785.785 0 00-.786-.786zm-.786 18.072h-4.52v-6.036h2.554l.383-2.963H14.73V8.356c0-.86.238-1.444 1.468-1.444h1.57V4.26a20.723 20.723 0 00-2.29-.118c-2.263 0-3.812 1.383-3.812 3.92v2.184H9.107v2.964h2.561v6.038H2.75v-16.5h16.5v16.5z" />
    </svg>
  )
}

export default SvgSnsFacebook
