import * as React from 'react'

function SvgMeasureFlexibility(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" viewBox="0 0 48 48" {...props}>
      <circle
        cx={38.333}
        cy={15.111}
        r={4.444}
        stroke="currentColor"
        strokeWidth={3.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.111 14l-13.727 6.863a4.444 4.444 0 00-2.324 2.898L5 44m25.556-21.11l-11.111 5.555L25 44"
        stroke="currentColor"
        strokeWidth={3.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.222 36.222L26.111 14 11.666 4"
        stroke="currentColor"
        strokeWidth={3.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgMeasureFlexibility
