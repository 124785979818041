import React, { ReactNode } from 'react'
import cx from 'classnames'
import './InputGroup.scss'

interface Props {
  label: string
  error?: boolean
  children: ReactNode
}

export default function InputGroup({ label, error, children }: Props) {
  return (
    <fieldset className={cx('InputGroup', { 'InputGroup--error': error })}>
      <legend className="InputGroup__title">{label}</legend>
      <div className="InputGroup__container">{children}</div>
    </fieldset>
  )
}
