import React from 'react'
import cx from 'classnames'
import { Line } from 'react-chartjs-2'
import './LineGraph.scss'

import {cutDecimal} from '../utils/misc'
import { isEmpty, sum , floor} from 'lodash'

interface Props {
  className?: string
  labels: string[]
  data: number[]
  min?: number
  max?: number
  stepSize? : number
}

const PURPLE = '#562C8D'

const LineGraph = ({ className, labels, data, min, max, stepSize= 1 }: Props) => {
  let sample  = data.map(age => 100 - Number(cutDecimal(age, 1)))
  var lineData = {
    labels,
    datasets: [
      {
        label: '심폐능력점수',
        data: sample,
        lineTension: 0,
        fill: true,
        borderColor: PURPLE,
        backgroundColor: 'rgba(0,0,0,0.2)',
        pointBorderColor: PURPLE,
        pointBackgroundColor: 'white',
        pointRadius: 5,
        pointBorderWidth: 2,
        datalabels: { display: true },
      },
    ],
  }
  return (
    <div className={cx('LineGraph', className)}>
      <Line
        data={lineData}
        options={{
          plugins: {
            datalabels: {
              formatter: value => `${cutDecimal(100 - Number(value), 1)}세`,
              anchor: 'end',
              align: 'end',
              font: {
                weight: 'bold',
                size: 13,
                family: 'Noto Sans KR',
              },
            },
          },
          aspectRatio: 5 / 3,
          layout: {
            padding: {
              top: 32,
              right: 16,
              bottom: 16,
              // left: 8
            }
          },

          legend: { display: false },
          maintainAspectRatio: true,
          animation: { duration: 0 },
          scales: {
            xAxes: [
              {
                ticks: { fontSize: 20 },
              },
            ],
            yAxes: [
              {
                stacked: false,
                ticks: {
                  fontSize: 16,
                  suggestedMin: min,
                  suggestedMax: max,
                  stepSize,
                  callback: value => Math.abs(Number(value) - 100),
                },
              },
            ],
          },
        }}
      />
    </div>
  )
}

export default LineGraph
