import './Selection.scss'

import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { isString } from 'lodash'

type Item = { label: string, value: string }

interface Props {
  className?: string
  name: string
  label: string
  options?: string[] | Item[] | null
  placeholder?: string
  error?: boolean
}

const Selection = forwardRef(
  ({ className, name, label, options, placeholder, error = true }: Props, ref: React.Ref<HTMLSelectElement>) => {
    return (
      <div className={classNames('Selection', className, { 'Selection--error': !!error })}>
        <label className="Selection__label" htmlFor={name}>
          {label}
        </label>
        <select className="Selection__select" id={name} name={name} ref={ref}>
          <option value="">{placeholder}</option>
          {(options as Array<string | Item>)?.map((option, idx) => {
            const label = isString(option) ? option : option.label
            const value = isString(option) ? option : option.value
            return (
              <option key={`${value}-${idx}`} value={value}>
                {label}
              </option>
            )
          })}
        </select>
      </div>
    )
  }
)

export default Selection
