import './ModifyTime.scss'

import React, { useState, useEffect, useRef } from 'react'
import { includes, isNil, pull } from 'lodash'
import { Controller, useForm, SubmitHandler } from 'react-hook-form/dist/index.ie11'

import { User } from '../types/model'
import useRouter from '../hooks/useRouter'
import { requestApi } from '../utils/api'
import { formatDate, today } from '../utils/misc'
import { DATE_LIST, PERSONAL_SOLUTION_SEND_HOUR_LIST, PLAN_LIST, PAIN_PART_LIST, LEVEL_LIST, PERSONAL_SOLUTION_SEND_MINUTE_LIST } from '../constants/label'

import Input from './Input'
import Radio from './Radio'
import Button from './Button'
import Alert from './Alert'
import InfoItem from './InfoItem'
import Selection from './Selection'
import Icon from './Icon'
import Checkbox, { CheckboxGroup } from './Checkbox'

interface Props {
  data?: User | null
  isEdit?: boolean
  trainerList?: string[] | null
}

type FormValues = {
  _id: string
  name: string
  contact: string
  job: string
  gender: string
  height: number | undefined
  birthday: string
  level?: string
  painPartList?: string
  personalSolutionSendDays?: string
  personalSolutionSendHour?: string
  personalSolutionSendMinute?: string
  anotherPersonalSolutionSendHour?: string
  anotherPersonalSolutionSendMinute?: string
  membershipType?: string
  membershipStart?: string
  membershipEnd?: string
  trainer?: string
  memo?: string
}

const defaultValues: FormValues = {
  _id: '',
  name: '',
  contact: '',
  job: '',
  gender: 'male',
  height: undefined,
  birthday: '',
  level: '',
  painPartList: '',
  personalSolutionSendDays: '',
  personalSolutionSendHour: '',
  personalSolutionSendMinute: '',
  anotherPersonalSolutionSendHour: '',
  anotherPersonalSolutionSendMinute: '',
  membershipType: '',
  membershipStart: '',
  membershipEnd: '',
  trainer: '',
  memo: '',
}

const JOB_LIST = [
  '사업 및 관리직',
  '회사원',
  '전문직',
  '서비스직',
  '판매직',
  '농림어업직',
  '기능직',
  '기계조작 및 운전',
  '군인',
  '주부 및 학생',
]

export default function ModifyTime({ data, isEdit }: Props) {
  const { history } = useRouter()
  const memberId = data?._id ?? null

  const [isLoading, setIsLoading] = useState(false)
  const [serverError, setServerError] = useState<string | null>(null)
  const [painPartList, setPainPartList] = useState<string[]>([])
  const [personalSolutionSendDays, setPersonalSolutionSendDays] = useState<string[]>([])
  const [isPSSendDaysError, setIsPSSendDaysError] = useState(false)
  const [isPSSendDaysDisabled, setIsPSSendDaysDisabled] = useState(false)
  const [isSaveTime, setIsSaveTime] = useState(false);

  const { register, handleSubmit, control, getValues, setValue, errors, formState } = useForm<FormValues>({
    mode: 'all',
    defaultValues,
    shouldFocusError: false,
  })

  useEffect(() => {
    if (!isNil(data) ) {
      const config = {
        shouldValidate: true,
        shouldDirty: true,
      }

      setValue('personalSolutionSendHour', data.personalSolutionSendHour, config)
      setValue('personalSolutionSendMinute', data.personalSolutionSendMinute, config)
      setValue('anotherPersonalSolutionSendHour', data.anotherPersonalSolutionSendHour, config)
      setValue('anotherPersonalSolutionSendMinute', data.anotherPersonalSolutionSendMinute, config)
    //   data.membershipStart && setValue('membershipStart', formatDate(data.membershipStart), config)
    //   data.membershipEnd && setValue('membershipEnd', formatDate(data.membershipEnd), config)

    //   setPainPartList(data.painPartList || [])
    //   setPersonalSolutionSendDays(data.personalSolutionSendDays || [])
    }
  }, [data, setValue])

  const formRef = useRef<HTMLFormElement>(null)
  const { touched, isValid } = formState

  const isErrorField = (key: keyof FormValues) => touched[key] && errors[key]

  const onSubmit: SubmitHandler<FormValues> = async data => {
    // const token = localStorage.getItem('token')
    try {
      setIsLoading(true)
      const res = await requestApi({
        url: `/users${isEdit ? `/${memberId}/modifytime` : ''}`,
        method: isEdit ? 'put' : 'post',
        // header: { authorization: token },
        body: data,
      })

      if (res.status === 200) {
        // TODO: POST res의 user 키로 래핑되어있는거 제거 요청함
        // const createdMemberId = res.data?._id
        const createdMemberId = res.data?.user?._id || res.data?._id
        // history.push(`/member/${createdMemberId}`)
        setIsSaveTime(true);
        return
      } else {
        setServerError('회원 정보 저장을 실패했습니다. 다시 시도해주세요.')
      }
    } catch (e) {
      setServerError('회원 정보 저장을 실패했습니다. 다시 시도해주세요.')
      console.error('request error', e)
    } finally {
      setIsLoading(false)
    }
  }


  const handlePersonalSolutionSendDays = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target
    const newPersonalSolutionSendDays = checked ? [...personalSolutionSendDays, value] : pull(personalSolutionSendDays, e.target.value)
    setPersonalSolutionSendDays(newPersonalSolutionSendDays)
    setIsPSSendDaysError(newPersonalSolutionSendDays.length !== 0 && newPersonalSolutionSendDays.length < 2)
    setIsPSSendDaysDisabled(newPersonalSolutionSendDays.length >= 4)
  }


  return (
      
    <div className="EditMemberInfo">
        {!isSaveTime ? (
      <form className="EditMemberInfo__form" onSubmit={handleSubmit(onSubmit)} ref={formRef} autoComplete="off">
        {serverError ? <Alert className="EditMemberInfo__alert" text={serverError} theme="error" /> : null}
     
        <fieldset className="EditMemberInfo__solution">
          <legend className="EditMemberInfo__title">오늘 다시 한번 알림 받기 </legend>
         
        <Selection
            ref={register}
            name="anotherPersonalSolutionSendHour"
            label="시간대 선택"
            options={PERSONAL_SOLUTION_SEND_HOUR_LIST}
            placeholder="시간대를 선택하세요."
          />
        <Selection
            ref={register}
            name="anotherPersonalSolutionSendMinute"
            label="분 선택"
            options={PERSONAL_SOLUTION_SEND_MINUTE_LIST}
            placeholder="분을 선택하세요."
          />

        <legend className="EditMemberInfo__title2">고정 시간 변경하기 </legend>

         
        <Selection
            ref={register}
            name="personalSolutionSendHour"
            label="시간대 선택"
            options={PERSONAL_SOLUTION_SEND_HOUR_LIST}
            placeholder="시간대를 선택하세요."
          />
          <Selection
            ref={register}
            name="personalSolutionSendMinute"
            label="분 선택"
            options={PERSONAL_SOLUTION_SEND_MINUTE_LIST}
            placeholder="분을 선택하세요."
          />
          
        </fieldset>
        
        <Button type="submit" title="저장하기" disabled={!isValid || isLoading || isPSSendDaysError} isLoading={isLoading} fullWidth />
      </form>
      ):
      <div>
            <h2>수정 완료</h2>
     </div>}
      {/* {isEdit && (
        <Button className="EditMemberInfo__delete" type="button" theme="secondary" onClick={deleteMember} fullWidth>
          <Icon name="Delete" /> 회원 삭제하기
        </Button>
      )} */}
    </div>
    
    
  )
}
