import * as React from 'react'

function SvgMeasureMuscle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" viewBox="0 0 48 48" {...props}>
      <g opacity={0.85} stroke="currentColor" strokeWidth={3}>
        <path d="M34.335 12.545l-5.467 12.41h-9.372l-5.467-12.41" strokeLinecap="square" />
        <path d="M19.496 25.092l.78 10.362-4.125.721a1.828 1.828 0 00-1.336 1.89V45m18.739 0v-6.935a1.828 1.828 0 00-1.337-1.89l-4.13-.72.78-10.363" />
        <ellipse cx={24.182} cy={16.364} rx={3.124} ry={3.818} strokeLinecap="square" />
        <path d="M7 7.773h34.364M8.91 3v9.546M38.5 3v9.546" strokeLinecap="square" />
      </g>
    </svg>
  )
}

export default SvgMeasureMuscle
