import * as React from 'react'

function Report(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} width="36" height="36" viewBox="0 0 36 36" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M30.8584 1.92857H26.5191V0.321429C26.5191 0.144643 26.3745 0 26.1977 0H23.9477C23.7709 0 23.6263 0.144643 23.6263 0.321429V1.92857H17.5191V0.321429C17.5191 0.144643 17.3745 0 17.1977 0H14.9477C14.7709 0 14.6263 0.144643 14.6263 0.321429V1.92857H10.287C9.57581 1.92857 9.00126 2.50312 9.00126 3.21429V8.03571H5.14411C4.43295 8.03571 3.8584 8.61027 3.8584 9.32143V34.7143C3.8584 35.4254 4.43295 36 5.14411 36H25.7155C26.4267 36 27.0013 35.4254 27.0013 34.7143V30.8571H30.8584C31.5696 30.8571 32.1441 30.2826 32.1441 29.5714V3.21429C32.1441 2.50312 31.5696 1.92857 30.8584 1.92857ZM24.1084 33.1071H6.75126V10.9286H15.5102V17.9196C15.5102 18.8076 16.2294 19.5268 17.1173 19.5268H24.1084V33.1071ZM24.1084 16.9554H18.0816V10.9286H18.0897L24.1084 16.9473V16.9554ZM29.2513 27.9643H27.0013V15.75L19.287 8.03571H11.8941V4.82143H14.6263V6.10714C14.6263 6.28393 14.7709 6.42857 14.9477 6.42857H17.1977C17.3745 6.42857 17.5191 6.28393 17.5191 6.10714V4.82143H23.6263V6.10714C23.6263 6.28393 23.7709 6.42857 23.9477 6.42857H26.1977C26.3745 6.42857 26.5191 6.28393 26.5191 6.10714V4.82143H29.2513V27.9643Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Report
