import './UserInfo.scss'

import React from 'react'
import cx from 'classnames'
import { isNil } from 'lodash'

import { User } from '../types/model'
import { getAge, formatDate, getGender, getMembershipEndDateDiff } from '../utils/misc'

import LinkButton from './LinkButton'
import Icon from './Icon'
import Loading from './Loading'

interface Props {
  className?: string
  data?: User | null
  errorMessage?: string | null
}

export default function UserInfo({ className, data, errorMessage }: Props) {
  if (errorMessage) {
    return (
      <div className={cx('UserInfo', className)}>
        <p className="UserInfo__desc">{errorMessage || '회원 기본정보가 없습니다.'}</p>
      </div>
    )
  }

  if (isNil(data)) {
    return (
      <div className={cx('UserInfo', className)}>
        <Loading />
      </div>
    )
  }

  const { _id: userId, name, contact, birthday, job, gender, membershipStart, membershipEnd, created } = data

  return (
    <div className={cx('UserInfo', className)}>
      <h2>
        {name}{' '}
        <LinkButton to={`tel:${contact}`} theme="iconOnly" external>
          <Icon name="Phone" />
        </LinkButton>
      </h2>
      <p>
        {job}, {getGender(gender)}, {`${getAge(birthday)}세`}, {formatDate(created, 'YYYY.MM.DD')} 등록
      </p>
      <p>
        {membershipStart ? `시작일: ${formatDate(membershipStart, 'YYYY.MM.DD')} |${' '}` : ''}
        {membershipEnd ? getMembershipEndDateDiff(membershipEnd) : '미등록'}
      </p>
      <LinkButton
        className="UserInfo__editButton"
        to={`/edit-member/${userId}`}
        title="수정"
        theme="secondary"
        size="small"
      />
    </div>
  )
}
