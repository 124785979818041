import './Tab.scss'

import React, { ReactNode } from 'react'
import cx from 'classnames'
import Button from './Button'

interface Props {
  item: { label: string; value: string; disabled?: boolean }[]
  selectedTab: string
  onSelect: (tab: string) => void
  children?: ReactNode
}

const Tab = ({ item, selectedTab, onSelect, children }: Props) => (
  <div className="TabWrapper">
    <div className="Tab">
      {item.map(({ label, value, disabled = false }) => (
        <Button
          key={value}
          className={cx('Tab__item', { 'Tab__item--active': selectedTab === value })}
          theme={selectedTab === value ? 'ghostPurple' : 'ghostGray'}
          onClick={() => onSelect(value)}
          disabled={disabled}
        >
          {label}
        </Button>
      ))}
    </div>
    <div className="Tab__contents">{children}</div>
  </div>
)

export default Tab
