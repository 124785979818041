import * as React from 'react'

function SvgMenu(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="currentColor" viewBox="0 0 22 22" {...props}>
      <path d="M10.944 13.023a2.022 2.022 0 100-4.045 2.022 2.022 0 000 4.045zm-7.988 0a2.022 2.022 0 100-4.045 2.022 2.022 0 000 4.045zm15.976 0a2.022 2.022 0 100-4.045 2.022 2.022 0 000 4.045z" />
    </svg>
  )
}

export default SvgMenu
