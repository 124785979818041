import * as React from 'react'

function SvgPlus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="currentColor" viewBox="0 0 44 44" {...props}>
      <path d="M20.527 4.324h2.946c.262 0 .393.13.393.393v34.571c0 .262-.131.393-.393.393h-2.946c-.262 0-.393-.131-.393-.393V4.717c0-.262.13-.393.393-.393z" />
      <path d="M5.5 20.136h33c.263 0 .394.131.394.393v2.947c0 .261-.131.392-.393.392h-33c-.262 0-.393-.13-.393-.392v-2.947c0-.262.13-.393.393-.393z" />
    </svg>
  )
}

export default SvgPlus
