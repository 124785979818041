import './Footer.scss'

import React from 'react'
import { KAKAO_CHANEL, FACEBOOK, INSTAGRAM, YOUTUBE } from '../constants/url'

import LinkButton from './LinkButton'
import Icon from './Icon'
import MustmoveLogo from '../images/mustmoveLogo.png'
import SnsKakao from '../images/SnsKakao.png'

export default function Footer() {
  return (
    <footer className="Footer">
      <img className="Footer__logo" src={MustmoveLogo} alt="Must move gym logo" />
      <div className="Footer__info">
        <p className="Footer__contact">
          <span>
            <b>상호명</b>주식회사 머스트무브
          </span>
          <span>
            <b>대표</b>주상화
          </span>
        </p>
        <p className="Footer__contact">
          <b>사업자 등록번호</b>119-88-01131
        </p>
        <p className="Footer__contact">
          <b>주소</b>서울 강남구 테헤란로 302 위워크타워 15F 105호
        </p>
        <p className="Footer__contact">
          <b>고객문의</b>02-555-7576 (운영시간 9:00~18:00, 주말/공휴일 제외)
        </p>
      </div>
      <ul className="Footer__links">
        <li>
          <LinkButton to={KAKAO_CHANEL} theme="iconOnly" external newTab>
            <img src={SnsKakao} alt="MustMove 카카오 채널" />
          </LinkButton>
        </li>
        <li>
          <LinkButton to={FACEBOOK} theme="iconOnly" external newTab>
            <Icon name="SnsFacebook" title="MustMove 페이스북" />
          </LinkButton>
        </li>
        <li>
          <LinkButton to={INSTAGRAM} theme="iconOnly" external newTab>
            <Icon name="SnsInstagram" title="MustMove 인스타그램" />
          </LinkButton>
        </li>
        <li>
          <LinkButton to={YOUTUBE} theme="iconOnly" external newTab>
            <Icon name="SnsYoutube" title="MustMove 유튜브" />
          </LinkButton>
        </li>
      </ul>
    </footer>
  )
}
