import './DiseasePredictionPage.scss'

import React from 'react'
import useRouter from '../hooks/useRouter'

import BasicLayout from '../templates/BasicLayout'
import DiseasePrediction from '../components/DiseasePrediction'

export default function DiseasePredictionPage() {
  const { match: { params: { id: reportId } } } = useRouter<{ id: string }>()

  return (
    <BasicLayout className="DiseasePredictionPage" isHeader={false}>
      <h1>인택트 질환 예측</h1 >
      <DiseasePrediction reportId={reportId} />
    </BasicLayout >
  )
}
