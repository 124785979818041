import './Paginator.scss'

import React from 'react'
import ReactPaginate from 'react-paginate'

import Icon from './Icon'

interface Props {
  currentPage: number
  pageCount: number
  setCurrentPage?: any
}

export default function Paginator({ currentPage = 0, pageCount = 17, setCurrentPage }: Props) {
  return (
    <ReactPaginate
      forcePage={currentPage}
      previousLabel={<Icon className="Paginator__prev" name="Arrow" />}
      nextLabel={<Icon className="Paginator__next" name="Arrow" />}
      breakLabel={'...'}
      pageCount={pageCount}
      marginPagesDisplayed={1}
      pageRangeDisplayed={4}
      onPageChange={val => setCurrentPage(val?.selected + 1)}
      containerClassName={'Paginator'}
      activeClassName={'active'}
    />
  )
}
