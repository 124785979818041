import './Checkbox.scss'

import React, { ReactNode, forwardRef, useState, useEffect } from 'react'
import classNames from 'classnames'
import useId from '../hooks/useId'
import Icon from './Icon'


interface CheckboxGroupProps {
  title: string
  subTitle: string
  error?: boolean
  children: ReactNode
}

export const CheckboxGroup = ({ title, subTitle, error, children }: CheckboxGroupProps) => {
  return (
    <fieldset className="CheckboxGroup">
      <legend>{title}<span className={classNames({ error })}>{subTitle}</span></legend>
      {children}
    </fieldset>
  )
}


interface Props {
  name: string
  label: string
  value: string
  checked?: boolean
  disabled?: boolean
  error?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Checkbox = forwardRef(({ name, label, value, checked, disabled, error, onChange }: Props, ref: React.Ref<HTMLInputElement>) => {
  const id = useId('Checkbox')
  const [isChecked, setIsChecked] = useState(checked)

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  return (
    <label htmlFor={id} className={classNames('Checkbox', { disabled: !isChecked && disabled })}>
      <input
        className="Checkbox__input"
        type="checkbox"
        id={id}
        ref={ref}
        name={name}
        value={value}
        checked={isChecked}
        disabled={!isChecked && disabled}
        aria-checked={isChecked}
        aria-invalid={error}
        onChange={(e) => {
          setIsChecked(!isChecked)
          onChange?.(e)
        }}
      />{' '}
      <div className={classNames('Checkbox__iconContainer', { error, checked: isChecked, disabled: !isChecked && disabled })}>
        {isChecked && <Icon name="FunBoldCheck" size={16} />}
      </div>
      <span className="Checkbox__label">{label}</span>
    </label>
  )
})

export default Checkbox
