import './ReportList.scss'

import React from 'react'
import cx from 'classnames'

import { Report } from '../types/model'
import { isNil } from 'lodash'
import Loading from './Loading'
import { Link } from 'react-router-dom'
import Icon from './Icon'
import { cutDecimal, formatDate } from '../utils/misc'

interface Props {
  data: Report[] | null
  userId: string
  error: boolean
}

const ReportList = ({ data, userId, error }: Props) => {
  if (error) {
    return (
      <div className="ReportList">리포트 데이터를 조회하는 데 문제가 발생했습니다. 페이지를 새로고침 해주세요.</div>
    )
  }

  if (isNil(data)) {
    return (
      <div className="ReportList">
        <Loading className="ReportList__loading" />
      </div>
    )
  }

  return (
    <div className="ReportList">
      <h3>{data?.length}개의 건강 평가</h3>
      <ul className="ReportList__list">
        <Link className="ReportList__create" to={`/health-check/${userId}`}>
          <Icon name="Plus" />
          <span>새로 평가하기</span>
        </Link>
        {data.map(({ _id: reportId, created, common: { evaluatedAge, weight, score } }, idx) => {
          const date = formatDate(created, `YYYY년 M월 D일`)
          const diff = idx === data.length - 1 ? null : cutDecimal(score - data[idx + 1]?.common.score, 1)
          return (
            <li key={created} className={cx('ReportList__listItem', { 'ReportList__listItem--bad': diff && diff < 0 })}>
              <Link to={`/reports/${reportId}`}>
                <h3>
                  건강 나이 <span>{evaluatedAge}</span>세
                  {!isNil(diff) && (
                    <p className="diff">
                      {diff > 0 && '+'}
                      {diff}p
                    </p>
                  )}
                </h3>
                <div>
                  <p>{weight}kg</p>
                  <p>종합 건강 점수 {score}</p>
                  <p>{date}</p>
                </div>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default ReportList
