import * as React from 'react'

function SvgXCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="currentColor" version="1.1" x="0px" y="0px" width="18px" height="18px" viewBox="0 0 18 18" {...props}>
      <path
        d="M9,0C4.03,0,0,4.03,0,9c0,4.97,4.03,9,9,9c4.97,0,9-4.03,9-9C18,4.03,13.97,0,9,0z M12.66,11.87
	c0.3,0.3,0.25,0.62,0.03,0.84c-0.23,0.22-0.53,0.24-0.83-0.05L9,9.8l-2.85,2.84c-0.28,0.32-0.68,0.26-0.87,0.07
	c-0.19-0.2-0.25-0.54,0.02-0.82L8.2,9L5.26,6.06C5.1,5.89,5.03,5.51,5.31,5.28c0.27-0.23,0.55-0.22,0.85,0.08L9,8.21v0l2.88-2.88
	c0.16-0.16,0.5-0.36,0.85-0.03c0.23,0.22,0.19,0.62-0.11,0.88L9.79,9L12.66,11.87z"
      />
    </svg>
  )
}

export default SvgXCircle
