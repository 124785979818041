import './CenterInfo.scss'

import React from 'react'
import { Center } from '../types/model'

import LinkButton from './LinkButton'
import CenterLogo from '../images/CenterLogo.png'

export default function CenterInfo(props: Center) {
  const {
    centerName,
    logoImage,
    pointName,
    usersCount,
    activeUsersCount,
    reportsCount,
    usersAverageEvaluatedAge,
    usersAverageAge,
  } = props

  return (
    <div className="CenterInfo">
      <div className="CenterInfo__profile">
        <div className="CenterInfo__logo">{<img src={CenterLogo} alt={centerName} />}</div>
        <div className="CenterInfo__info">
          <h2>{centerName}</h2>
          <h3>{pointName}</h3>
          <LinkButton to="/edit-center" title="수정" theme="secondary" size="small" />
        </div>
      </div>
      <ul className="CenterInfo__list">
        <li className="CenterInfo__listItem">
          <span>{usersCount}</span>
          <span>전체 회원수</span>
        </li>
        <li className="CenterInfo__listItem">
          <span>{activeUsersCount}</span>
          <span>
            현재 등록중인
            <br />
            회원 수
          </span>
        </li>
        <li className="CenterInfo__listItem">
          <span>{reportsCount}</span>
          <span>전체 리포트수</span>
        </li>
        <li className="CenterInfo__listItem">
          <span>
            {usersAverageEvaluatedAge}
            <span>/{usersAverageAge}</span>
          </span>
          <span className="small">
            회원 전체 평균
            <br />
            건강나이/실제나이
          </span>
        </li>
      </ul>
    </div>
  )
}
