import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import './Button.scss'

interface Props {
  to: string
  external?: boolean
  newTab?: boolean
  children?: ReactNode

  className?: string
  theme?: 'primary' | 'secondary' | 'error' | 'aqua' | 'iconOnly' | 'ghostPurple' | 'ghostGray' | 'ghostBlack' | 'ghostRed'
  size?: 'small' | 'medium' | 'large'
  fullWidth?: boolean
  title?: string

  isLoading?: boolean
  disabled?: boolean
  testId?: string
}

export default function LinkButton({
  to,
  external,
  newTab,
  children,
  className,
  theme = 'primary',
  size = 'medium',
  fullWidth,
  title,
  isLoading = false,
  disabled,
  testId,
}: Props) {
  const tabProps = newTab ? { target: '_blank', rel: 'noopener noreferrer' } : {}
  const buttonClassNames = classNames('Button', className, `Button--${size}`, `Button--${theme}`, {
    disabled,
    fullWidth,
    isLoading,
  })

  if (isLoading) {
    return (
      <a className={buttonClassNames} tabIndex={-1} data-testid={testId}>
        Loading...
        {/* <Loading isVisible={isLoading} size={theme === 'text' ? iconSize['small'] : iconSize[size]} /> */}
      </a>
    )
  }

  if (external || disabled) {
    return (
      <a
        className={buttonClassNames}
        href={disabled ? undefined : to}
        tabIndex={disabled ? -1 : 0}
        data-testid={testId}
        {...tabProps}
      >
        {theme === 'iconOnly' ? (
          children
        ) : (
          <span role="button" className="Button__text">
            {children || title}
          </span>
        )}
      </a>
    )
  }

  return (
    <Link to={to} className={buttonClassNames} data-testid={testId} {...tabProps} tabIndex={0}>
      {theme === 'iconOnly' ? (
        children
      ) : (
        <span role="button" className="Button__text">
          {children || title}
        </span>
      )}
    </Link>
  )
}
