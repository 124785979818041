import React, { ReactText } from 'react'
import classNames from 'classnames'

export type RadioTheme = 'radio' | 'chip'

interface Props {
  id: string
  name: string
  value: ReactText
  label: ReactText
  checked?: boolean
  error?: boolean
  disabled?: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default function RadioItem({ id, name, value, label, checked, error, disabled, onChange }: Props) {
  return (
    <div className="RadioItem">
      <input
        className="RadioItem__input"
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        aria-invalid={error}
        aria-checked={checked}
      />
      <label className={classNames('RadioItem__label', { error, checked, disabled })} htmlFor={id}>
        {label}
      </label>
    </div>
  )
}
