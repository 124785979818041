import './AttendancePage.scss'

import React, { useEffect, useState } from 'react'
import { isNil, isEmpty } from 'lodash'
import cx from 'classnames'
import { Attendance, User } from '../types/model'
import useRouter from '../hooks/useRouter'
import { requestApi } from '../utils/api'
import { formatDate } from '../utils/misc'

import BasicLayout from '../templates/BasicLayout'
import Button from '../components/Button'
import Info from '../components/Info'
import Icon from '../components/Icon'

export default function AttendancePage() {
  const {
    history,
    match: {
      params: { id: userId },
    },
  } = useRouter<{ id: string }>()
  const [memberInfo, setMemberInfo] = useState<User | null>(null)
  const [attendance, setAttendance] = useState<Attendance[]>([])
  const [memberInfoError, setMemberInfoError] = useState<string | null>(null)
  const [attendanceError, setAttendanceError] = useState<string | null>(null)
  const [isDeleteMode, setIsDeleteMode] = useState(false)
  const [isCheckingAttendance, setIsCheckingAttendance] = useState(false)

  const goBack = () => history.goBack()

  useEffect(() => {
    if (userId) {
      requestApi({ url: `/users/${userId}`, isAuthenticated: true }).then(res => {
        if (res?.status === 200) {
          setMemberInfo(res?.data)
        } else {
          setMemberInfoError('멤버 정보를 조회하는 데 문제가 발생했습니다. 페이지를 새로고침 해주세요.')
        }
      })

      requestApi({ url: `/attendances?userId=${userId}`, body: { userId }, isAuthenticated: true }).then(res => {
        if (res?.status === 200) {
          setAttendance(res?.data?.filter((att: Attendance) => att.status !== 'CANCEL'))
        } else {
          setAttendanceError('출석 정보를 조회하는 데 문제가 발생했습니다. 페이지를 새로고침 해주세요.')
        }
      })
    }
  }, [userId])

  const checkAttendance = () => {
    setIsCheckingAttendance(true)

    requestApi({
      method: 'post',
      url: `/attendances`,
      body: { userId, name: memberInfo?.name, contact: memberInfo?.contact },
      isAuthenticated: true,
    }).then(({ status, data }) => {
      if (status === 200) {
        const newAttendance = [data, ...attendance].filter(att => att.status !== 'CANCEL')
        setAttendance(newAttendance)
        setIsCheckingAttendance(false)
      }
    })
  }

  const deleteAttendance = (attendanceId: string) => {
    if (window.confirm('출석 기록을 삭제하시겠습니까?')) {
      requestApi({
        method: 'delete',
        url: `/attendances/${attendanceId}`,
        body: { status: 'CANCEL' },
        isAuthenticated: true,
      }).then(({ status, data }) => {
        if (status === 200 && data?.status === 'CANCEL') {
          const newAttendance = attendance.filter(att => att._id !== attendanceId)
          setAttendance(newAttendance)
        }
      })
    }
  }

  if (isNil(memberInfo)) {
    return (
      <BasicLayout className="AttendancePage" headerTitle={`회원님의 출석 현황`}>
        회원 정보 요청중...
      </BasicLayout>
    )
  }

  const totalAttendance = attendance.length
  const { name, trainer, membershipStart, membershipEnd } = memberInfo
  const memberData = [
    { label: '시작일', value: membershipStart && formatDate(membershipStart, 'YYYY년 M월 D일') },
    { label: '종료일', value: membershipEnd && formatDate(membershipEnd, 'YYYY년 M월 D일') },
    { label: '트레이너', value: trainer },
    { label: '총 출석일', value: `${totalAttendance} 회` },
  ]

  return (
    <BasicLayout
      className="AttendancePage"
      headerTitle={`${name}님의 출석 현황`}
      headerColor="black"
      headerLeftEl={<button onClick={goBack}>이전</button>}
    >
      <Info
        className="AttendancePage__memberSection"
        title="회원 등록 정보"
        data={memberData}
        error={memberInfoError}
      />
      <section className="AttendancePage__attendanceSection">
        <header className="AttendancePage__attendanceHeader">
          <h2>출석 현황</h2>
          <Button
            theme="secondary"
            size="small"
            title={isDeleteMode ? '완료' : '편집'}
            onClick={() => (isDeleteMode ? setIsDeleteMode(false) : setIsDeleteMode(true))}
          />
        </header>
        {attendanceError && <p className="AttendancePage__errorMessage">{attendanceError}</p>}
        {!attendanceError && (
          <>
            {isEmpty(attendance) && <p className="AttendancePage__attendanceDesc">출석 기록이 없습니다.</p>}
            {!isEmpty(attendance) && (
              <ul
                className={cx('AttendancePage__attendanceList', {
                  'AttendancePage__attendanceList--normal': !isDeleteMode,
                })}
              >
                {attendance.map(({ _id: attendanceId, created, status = 'PENDING' }, idx) => (
                  <li key={attendanceId} className="AttendancePage__attendanceItem">
                    <span className="AttendancePage__attendanceLabel">{totalAttendance - idx}회차</span>
                    <span className="AttendancePage__attendanceDate">
                      {formatDate(created, `YYYY년 M월 D일 h:mm A`)}
                    </span>
                    {isDeleteMode ? (
                      <Button
                        className="AttendancePage__attendanceDeleteButton"
                        theme="iconOnly"
                        onClick={() => deleteAttendance(attendanceId)}
                      >
                        <Icon name="Delete" />
                      </Button>
                    ) : (
                      <p className={`AttendancePage__attendanceStatus ${status}`}>
                        {status === 'DONE' ? '체크됨' : '대기중'}
                      </p>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
        {!isDeleteMode && (
          <Button
            className="AttendancePage__attendanceCheckButton"
            onClick={checkAttendance}
            isLoading={isCheckingAttendance}
            fullWidth
          >
            <Icon name="Attendance" />
            출석 확인 요청하기
          </Button>
        )}
      </section>
    </BasicLayout>
  )
}
