import * as React from 'react'

function SvgAddMember(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 36 36" fill="currentColor" {...props}>
      <path d="M24.682 23.23a6.889 6.889 0 013.27-.82h.005c.12 0 .177-.145.088-.225-1.233-1.106-2.64-2-4.167-2.644-.015-.008-.032-.012-.048-.02a9.967 9.967 0 004.119-8.08c0-5.504-4.452-9.964-9.945-9.964-5.492 0-9.94 4.46-9.94 9.964a9.96 9.96 0 004.123 8.08c-.016.008-.033.012-.049.02a14.972 14.972 0 00-4.793 3.238 15.003 15.003 0 00-3.23 4.802 15.011 15.011 0 00-1.182 5.549.322.322 0 00.322.329h2.407c.172 0 .317-.14.321-.313a11.988 11.988 0 013.52-8.209 11.928 11.928 0 018.506-3.532c2.278 0 4.463.631 6.348 1.812a.325.325 0 00.325.013zm-6.673-4.878c-1.84 0-3.572-.72-4.878-2.025a6.879 6.879 0 01-2.021-4.886c0-1.844.719-3.58 2.02-4.886a6.84 6.84 0 014.88-2.025 6.85 6.85 0 014.877 2.025 6.88 6.88 0 012.021 4.886c0 1.844-.72 3.58-2.02 4.886a6.854 6.854 0 01-4.878 2.025zm14.777 9.562h-3.375V24.54a.322.322 0 00-.321-.321h-2.25a.322.322 0 00-.322.321v3.375h-3.375a.322.322 0 00-.321.322v2.25c0 .177.145.321.321.321h3.375v3.375c0 .177.145.322.322.322h2.25a.322.322 0 00.321-.322v-3.375h3.375a.322.322 0 00.322-.321v-2.25a.322.322 0 00-.322-.322z" />
    </svg>
  )
}

export default SvgAddMember
