import React, { ReactText } from 'react'
import classNames from 'classnames'
import { includes } from 'lodash'
import RadioItem from './RadioItem'
import './Radio.scss'

interface Info {
  label: ReactText
  value: ReactText
}

interface Props {
  name: string
  label: ReactText
  options: Info[]
  selectedValue?: ReactText
  className?: string
  hideLabel?: boolean
  error?: boolean
  disabled?: boolean
  disabledValues?: ReactText[]
  onChange: (val: string) => void
}

export default function Radio({
  name,
  label,
  options,
  selectedValue,
  className,
  hideLabel,
  error,
  disabled,
  disabledValues,
  onChange,
}: Props) {
  return (
    <fieldset aria-labelledby={name} className={classNames('Radio', className)}>
      <legend id={name} className={classNames('Radio__label', { 'screen-reader-text': hideLabel })}>
        {label}
      </legend>
      {options.map(({ value, label }, idx) => (
        <RadioItem
          key={value}
          id={`${value}${idx}`}
          name={name}
          value={value}
          label={label}
          checked={selectedValue === value}
          error={error}
          disabled={disabled || includes(disabledValues, value)}
          onChange={e => onChange(e.target.value)}
        />
      ))}
    </fieldset>
  )
}
