export const logout = (cb?: () => void) => {
  localStorage.removeItem('token')
  localStorage.removeItem('centerId')
  localStorage.removeItem('loginId')
  cb?.()
}

export const isLoggedIn = () => (
  !!localStorage.getItem('token') &&
  !!localStorage.getItem('centerId') &&
  !!localStorage.getItem('loginId')
)
