import React from 'react'
import { Redirect } from 'react-router-dom'
import { logout, isLoggedIn } from '../utils/auth'

interface Props {
  children: React.ReactNode
}

export default function AuthPage({ children }: Props) {
  const isAuthenticated = isLoggedIn()

  if (!isAuthenticated) {
    logout()
    return <Redirect to="/login" />
  }

  return <>{children}</>
}
