import * as React from 'react'

function SvgMeasureHeart(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" viewBox="0 0 48 48" {...props}>
      <path
        d="M3 22.181h13.363l3.819-5.727 7.637 11.453 3.818-5.727H45m-34.363 6.111A112.497 112.497 0 0024 43.181c4.888-4.556 9.36-9.54 13.365-14.89m5.725-12.926C43.062 9.634 38.409 5.003 32.678 5A10.54 10.54 0 0024 9.664 10.54 10.54 0 0015.324 5C9.592 5.003 4.939 9.634 4.909 15.365"
        opacity={0.85}
        stroke="currentColor"
        strokeWidth={3}
        strokeLinecap="square"
      />
    </svg>
  )
}

export default SvgMeasureHeart
