import * as React from 'react'

function SvgArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg className="Icon" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="15" cy="14.9993" rx="15" ry="14.9995" fill="white"/>
    <path d="M21.187 19.4906C20.8501 18.6927 20.3613 17.968 19.7477 17.3567C19.136 16.7437 18.4113 16.2549 17.6138 15.9174C17.6066 15.9138 17.5995 15.9121 17.5923 15.9085C18.7048 15.1049 19.4281 13.796 19.4281 12.3192C19.4281 9.87277 17.4459 7.89062 14.9995 7.89062C12.5531 7.89062 10.5709 9.87277 10.5709 12.3192C10.5709 13.796 11.2941 15.1049 12.4066 15.9103C12.3995 15.9138 12.3923 15.9156 12.3852 15.9192C11.5852 16.2567 10.8673 16.7406 10.2513 17.3585C9.63825 17.9702 9.14949 18.6949 8.81199 19.4924C8.48042 20.2732 8.3016 21.1103 8.2852 21.9585C8.28472 21.9775 8.28807 21.9965 8.29503 22.0142C8.302 22.032 8.31245 22.0482 8.32576 22.0618C8.33907 22.0755 8.35498 22.0863 8.37255 22.0937C8.39012 22.1011 8.40899 22.1049 8.42806 22.1049H9.49949C9.57806 22.1049 9.64056 22.0424 9.64234 21.9656C9.67806 20.5871 10.2316 19.296 11.2102 18.3174C12.2227 17.3049 13.5673 16.7478 14.9995 16.7478C16.4316 16.7478 17.7763 17.3049 18.7888 18.3174C19.7673 19.296 20.3209 20.5871 20.3566 21.9656C20.3584 22.0442 20.4209 22.1049 20.4995 22.1049H21.5709C21.59 22.1049 21.6089 22.1011 21.6264 22.0937C21.644 22.0863 21.6599 22.0755 21.6732 22.0618C21.6865 22.0482 21.697 22.032 21.7039 22.0142C21.7109 21.9965 21.7142 21.9775 21.7138 21.9585C21.6959 21.1049 21.5191 20.2746 21.187 19.4906ZM14.9995 15.3906C14.1798 15.3906 13.4084 15.071 12.8281 14.4906C12.2477 13.9103 11.9281 13.1388 11.9281 12.3192C11.9281 11.4996 12.2477 10.7281 12.8281 10.1478C13.4084 9.56741 14.1798 9.24777 14.9995 9.24777C15.8191 9.24777 16.5906 9.56741 17.1709 10.1478C17.7513 10.7281 18.0709 11.4996 18.0709 12.3192C18.0709 13.1388 17.7513 13.9103 17.1709 14.4906C16.5906 15.071 15.8191 15.3906 14.9995 15.3906Z" fill="#03ACCE"/>
    </svg>
  )
}

export default SvgArrow
