import './CropImg.scss'

import React from 'react'
import cx from 'classnames'


interface Props {
  className?: string
  size?: string
  alt: string
  src: string
}

export default function CropImg({ className, size = '50px', alt, src }: Props) {
  return (
    <div className={cx('CropImg', className)} style={{ width: size, height: size }}>
      <img alt={alt} src={src} />
      </div>
  )
}
