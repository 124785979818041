import React from 'react'
import cx from 'classnames'
import useRouter from '../hooks/useRouter'

import './Header.scss'

export type HeaderColor = 'white' | 'purple' | 'black'

interface Props {
  title?: string
  leftEl?: React.ReactNode
  rightEl?: React.ReactNode | 'Home'
  color?: HeaderColor
}

export default function Header({ title, leftEl, rightEl, color = 'purple' }: Props) {
  const { history } = useRouter()

  const HomeButton = () => (
    <button className="Header__rightEl" onClick={() => history.push('/')}>
      Home
    </button>
  )

  return (
    <header className={cx('Header', `Header--${color}`)}>
      {leftEl && <div className="Header__leftEl">{leftEl}</div>}
      {title && (
        <h1 className="Header__title" onClick={() => history.push('/')}>
          {title}
        </h1>
      )}
      {rightEl && rightEl === 'Home' ? <HomeButton /> : <div className="Header__rightEl">{rightEl}</div>}
    </header>
  )
}
