import React, { useState, useEffect } from 'react'
import useRouter from '../hooks/useRouter'

import BasicLayout from '../templates/BasicLayout'
import EditMemberInfo from '../components/EditMemberInfo'

import { requestApi } from '../utils/api'
import { logout } from '../utils/auth'
import { User } from '../types/model'

import './AddMember.scss'

export default function AddMember() {
  const {
    history,
    match: {
      params: { id: userId },
      path,
    },
  } = useRouter<{ id: string }>()
  const [memberInfo, setMemberInfo] = useState<User | null>(null)
  const [trainerList, setTrainerList] = useState<string[] | null>(null)

  const isEditPage = path.includes('edit-member') && !!userId
  const headerTitle = isEditPage ? '회원 정보 수정' : '회원 신규 등록'

  const goBack = () => history.goBack()

  const confirmCancle = () => {
    if (window.confirm('회원 신규 등록을 취소하시겠습니까?')) {
      history.push('/')
    }
  }

  useEffect(() => {
    if (userId) {
      requestApi({ url: `/users/${userId}`, isAuthenticated: true }).then(res => {
        if (res?.status === 200) {
          setMemberInfo(res?.data)
        } else {
          alert('회원 정보를 조회하는데 실패했습니다. url을 확인해주세요.')
          history.push('/')
        }
      })
    }

    const centerId = localStorage.getItem('centerId')

    try {
      requestApi({ url: `/centers/${centerId}`, isAuthenticated: true }).then(res => {
        res?.status === 200
          ? setTrainerList(res?.data?.trainerList ?? [])
          : res?.status === 403
          ? logout(() => history.push('/login'))
          : alert('센터 정보를 불러오지 못했습니다. 다시 로그인해주세요.')
      })
    } catch (e) {
      alert('센터 정보를 불러오지 못했습니다. 다시 로그인해주세요.')
      console.log('request error', e)
    }
  }, [])

  return (
    <BasicLayout
      className="AddMember"
      headerColor="black"
      headerTitle={headerTitle}
      headerLeftEl={<button onClick={isEditPage ? goBack : confirmCancle}>{isEditPage ? '이전' : '취소'}</button>}
    >
      <EditMemberInfo data={memberInfo} isEdit={isEditPage} trainerList={trainerList} />
    </BasicLayout>
  )
}
