import './TotalScore.scss'

import React from 'react'
import cx from 'classnames'
import { Bar } from 'react-chartjs-2'
import { graphColor } from '../constants/color'
import { cutDecimal } from '../utils/misc'

interface Props {
  className?: string
  labels: string[]
  data: number[]
}

const TotalScore = ({ className, labels, data }: Props) => {
  const graphData = {
    labels: ['', '', '', ''],
    datasets: [
      {
        type: 'bar',
        backgroundColor: 'rgba(135,113,177,0.6)',
        borderWidth: 0,
        barThickness: 70,
        data: data.map(age => 120 - Number(cutDecimal(age, 1))),
      },
    ],
  }

  return (
    <div className={cx('TotalScore', className)}>
      <div className="TotalScore__xAxis">
        <div>심폐</div>
        <div>보행</div>
        <div>관절</div>
        <div>근력</div>
      </div>
      <Bar
        data={graphData}
        options={{
          plugins: {
            datalabels: {
              formatter: value => `${cutDecimal(120 - Number(value), 1)}`,
              color: graphColor.DARK_PURPLE,
              anchor: 'end',
              align: 'end',
              font: {
                weight: 'bold',
                size: 33,
              },
            },
          },
          layout: { padding: { top: 50 } },
          maintainAspectRatio: true,
          animation: { duration: 0 },
          title: {
            display: false,
            text: 'Total score',
          },
          legend: { display: false },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontStyle: 'bold',
                  fontSize: 30,
                  fontColor: graphColor.BLACK,
                },
              },
            ],
            yAxes: [
              {
                type: 'linear',
                position: 'left',
                ticks: {
                  fontSize: 20,
                  fontColor: '#6D6D6D',
                  beginAtZero: false,
                  min: 20,
                  max: 100,
                  stepSize: 10,
                  callback: value => Math.abs(Number(value) - 120),
                },
                gridLines: {
                  lineWidth: 2,
                  color: '#C4C4C4',
                },
              },
            ],
          },
        }}
        width={100}
        height={100}
      />
    </div>
  )
}

export default TotalScore
